import React, {useContext, useEffect, useRef, useState} from 'react';
import "./Chat.css";
import {Button, Input, message, Popconfirm, Select, Spin, Tag} from "antd";
import {apiPost} from "../utility/api";
import {ChatContext} from "../contexts/ChatContext";
import {useLocation, useNavigate} from "react-router-dom";
import Markdown from "react-markdown";
import {AppIcon} from "../assets/AppIcon";
import {CopyOutlined, DeleteOutlined, LoadingOutlined, QuestionCircleOutlined, RedoOutlined} from "@ant-design/icons";

const Chat = () => {

  const [input, setInput] = useState("");
  const [chatId, setChatId] = useState("");
  const [model, setModel] = useState("llama3");
  const [loading, setLoading] = useState(false);
  const {chatMessages, addChat, updateChat, getChatMessages, clearChatMessages, addChatMessage, updateChatMessage, deleteChatMessage, models, error} = useContext(ChatContext);
  const location = useLocation();
  const navigate = useNavigate();
  const chatWindowRef = useRef(null);
  const bottomRef = useRef(null);
  const [alert, contextHolder] = message.useMessage();
  const [loadingMessages, setLoadingMessages] = useState({});

  useEffect(() => {
    // Get chat id from path
    const currentChatId = location.pathname.replace("/", "");

    // Skip if navigated to same chat
    if (currentChatId === chatId) return

    // Update chat id state
    setChatId(currentChatId);

    // Skip if new chat
    if (currentChatId === "") {
      clearChatMessages()
      return
    }

    // Get chat messages from api
    getChatMessages(currentChatId)
    scrollToBottom(true)
  }, [location]);

  useEffect(() => {
    scrollToBottom()
  }, [chatMessages])

  useEffect(() => {
    if (error) {
      alert.error(error).then(() => navigate("/"));
    }
  }, [error])

  const scrollToBottom = (forceScroll = false) => {
    const chatWindow = chatWindowRef.current
    const bottom = bottomRef.current
    if (forceScroll) {
      setTimeout(() => bottom.scrollIntoView({behavior: "smooth"}), 200)
      return
    }
    // If generating and user if far from bottom, don't scroll
    if (chatWindow.scrollTop + chatWindow.clientHeight + 100 < chatWindow.scrollHeight)
      return
    bottom.scrollIntoView({behavior: "smooth"})
  }

  const clickSend = () => {

    // Update UI
    setLoading(true);
    const userInput = input.trim()
    setInput("")

    // Add input to message list
    addChatMessage({id: "user-new", content: userInput, type: "user"})
    addChatMessage({id: "ai-new", content: "", type: "ai", response_to: "user-new", model: model})
    scrollToBottom(true)

    // Send to API
    apiPost("/chat", {
      chat_id: chatId,
      content: userInput,
      model: model
    }).then(response => {
      const reader = response.body.getReader()
      let responseContent = ""
      let generatedChatId = ""
      let userMessageId = ""
      const readChunk = () => {
        reader.read().then(({value, done}) => {
          if (done) return
          const decoded = new TextDecoder().decode(value)
          const parts = decoded.split("}{")
          parts.forEach((part, _) => {
            if (parts.length > 1) {
              if (_ === 0)
                part = part + "}"
              else if (_ === parts.length - 1)
                part = "{" + part
              else
                part = "{" + part + "}"
            }
            console.log(part)
            const data = JSON.parse(part)
            switch (data.event_type) {
              case "chat_id":
                generatedChatId = data.data
                setChatId(generatedChatId)
                addChat({id: generatedChatId, title: userInput.substring(0, 32)})
                navigate(generatedChatId, {replace: true})
                break
              case "response":
                responseContent += data.data
                updateChatMessage("ai-new", {content: responseContent})
                break
              case "title":
                updateChat(generatedChatId, {"title": data.data})
                break
              case "user_message_id":
                userMessageId = data.data
                updateChatMessage("user-new", {id: userMessageId})
                break
              case "ai_message_id":
                updateChatMessage("ai-new", {id: data.data, response_to: userMessageId})
                setLoading(false)
                break
              default:
            }
          })
          readChunk()
        })
      }
      readChunk()
    });
  }
  
  const clickRegenerate = (userMessageId) => {

    // Update UI
    setLoading(true)

    // Create new message
    addChatMessage({id: "ai-new", content: "", type: "ai-regen", response_to: userMessageId, model: model})

    // Send to API
    apiPost(`/chat_message/${userMessageId}/regenerate`, {
      model: model
    }).then(response => {
      const reader = response.body.getReader()
      let responseContent = ""
      const readChunk = () => {
        reader.read().then(({value, done}) => {
          if (done) return
          const decoded = new TextDecoder().decode(value)
          const parts = decoded.split("}{")
          parts.forEach((part, _) => {
            if (parts.length > 1) {
              if (_ === 0)
                part = part + "}"
              else if (_ === parts.length - 1)
                part = "{" + part
              else
                part = "{" + part + "}"
            }
            console.log(part)
            const data = JSON.parse(part)
            switch (data.event_type) {
              case "response":
                responseContent += data.data
                updateChatMessage("ai-new", {content: responseContent})
                break
              case "ai_message_id":
                updateChatMessage("ai-new", {id: data.data, response_to: userMessageId})
                setLoading(false)
                break
              default:
            }
          })
          readChunk()
        })
      }
      readChunk()
    })

  }

  return (
    <div className="Chat">
      {contextHolder}
      <div className="chat-header">
        <AppIcon/>
        <h2>DSA Chatbot</h2>
        <Select
          defaultValue={model}
          options={models.map(m => ({label: m, value: m}))}
          style={{ width: 120, marginLeft: "auto" }}
          onChange={value => setModel(value)}
        />
      </div>
      <div className="chat-window" ref={chatWindowRef}>
        {chatMessages.filter(message => message.type === "user").map(message =>
          <React.Fragment key={message.id}>
            <div className="chat-message">
              <Popconfirm
                title="Delete message"
                description="Are you sure you want to delete this message?"
                icon={<QuestionCircleOutlined style={{color: "red"}}/>}
                okButtonProps={{danger: true}}
                onConfirm={() => deleteChatMessage(message.id)}
              >
                <Button size="small" type="text" icon={<DeleteOutlined/>} shape="circle"/>
              </Popconfirm>
              <p>{message.content}</p>
            </div>
            {chatMessages.filter(response => response.response_to === message.id).map(response =>
              <div className="chat-response" key={response.id}>
                <Markdown>
                  {response.content}
                </Markdown>
                {(response.id === "ai-new" && loading) ? <Spin indicator={<LoadingOutlined/>} style={{marginTop: 16}} />: ""}
                <div className="message-actions">
                  <Popconfirm
                    title="Delete message"
                    description="Are you sure you want to delete this message?"
                    icon={<QuestionCircleOutlined style={{color: "red"}}/>}
                    okButtonProps={{danger: true}}
                    onConfirm={() => deleteChatMessage(response.id)}
                  >
                    <Button size="small" type="text" icon={<DeleteOutlined/>} disabled={response.id === "ai-new"} />
                  </Popconfirm>
                  <Button
                    size="small"
                    type="text"
                    onClick={() => clickRegenerate(message.id)}
                    disabled={response.id === "ai-new"} // Disable if it's regenerating
                  >
                    {loadingMessages[response.id] ? <Spin indicator={<LoadingOutlined/>} /> : <RedoOutlined />}
                  </Button>
                  <Button
                    size="small"
                    type="text"
                    icon={<CopyOutlined />}
                    disabled={response.id === "ai-new"}
                    onClick={() => {
                      navigator.clipboard.writeText(response.content).then(() => alert.success("Message copied to clipboard"));
                    }}
                  />
                  <Tag color="purple" className="model-tag">{response.model}</Tag>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
        <div ref={bottomRef}></div>
      </div>


      <div className="chat-input">
        <Input.TextArea autoSize value={input} onChange={e => setInput(e.target.value)}/>
        <Button type="primary" onClick={clickSend} disabled={input.trim() === "" || loading}>Send</Button>
      </div>
    </div>
  );
};

export default Chat;
