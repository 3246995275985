import React, {useContext, useEffect, useState} from 'react';
import {Button, Menu, Tooltip} from "antd";
import "./Sidebar.css";
import {useLocation, useNavigate} from "react-router-dom";
import {apiPost} from "../utility/api";
import {ChatContext} from "../contexts/ChatContext";
import {AppIcon} from "../assets/AppIcon";
import Icon, {
  DeleteOutlined,
  ExportOutlined,
  ImportOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined
} from '@ant-design/icons';

const Sidebar = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const {chats, deleteChat, chatMessages, addChat} = useContext(ChatContext);
  const [menuItems, setMenuItems] = useState([])
  const [menuOpen, setMenuOpen] = useState(true)
  const [model, setModel] = useState("llama3");

  useEffect(() => {
    setMenuItems([
      {
        label: "New Chat",
        icon: <Icon component={AppIcon}/>,
        key: ""
      },
      {
        type: "group",
        label: "Chat History",
        children: []
      },
      ...chats.map(({id, title}) => ({key: id, label:
          <div className="menu-item">
            <p>{title}</p>
          </div>
      }))
    ]);
  }, [chats])

  const selectChat = (chat_id) => {
    navigate(chat_id, {replace: true})
  }

  const deleteCurrentChat = () => {
    const chatId = location.pathname.replace("/", "")
    const chatIndex = chats.findIndex(chat => chat.id === chatId)
    const newChatId = chats[chats.length > chatIndex + 1 ? chatIndex + 1 : chatIndex - 1]?.id || ""
    deleteChat(chatId)
    navigate(newChatId, {replace: true})
  }

  const exportChat = () => {

    // Get the chat title
    const chatId = location.pathname.replace("/", "")
    const currentChat = chats.find(chat => chat.id === chatId);
    const chatTitle = currentChat.title;

    // Combine the chat title and messages into an object
    const chatData = {
      title: chatTitle,
      messages: chatMessages,
    };

    // Create a Blob from the chat messages, format as JSON
    const jsonData = JSON.stringify(chatData, null, 2); // Pretty format the JSON

    // Create a Blob with the chat data and create a link to download it
    const blob = new Blob([jsonData], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    // Create a temporary link to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = `chat_${chatId}.json`;  // Filename for the downloaded chat
    document.body.appendChild(a);
    a.click();

    // Clean up after download
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

  }

  const importChat = () => {

    // Create a hidden input element to select the file
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'application/json';
  
    input.onchange = (event) => {
      const file = event.target.files[0];
  
      if (file) {
        const reader = new FileReader();
  
        // When file is read, parse the content and update chat messages
        reader.onload = (e) => {
          try {
            const importedChat = JSON.parse(e.target.result);  // Parse the JSON content
  
            // Validate that the file contains a valid chat structure
            if (importedChat && importedChat.title && Array.isArray(importedChat.messages)) {

              apiPost("/import", {
                title: importedChat.title,
                model: model,
                messages: importedChat.messages,
              }).then(response => {
                if (response.ok) {
                    return response.json();  // Extract the JSON from the response
                } else {
                    console.error("Error in the response", response.status);
                }
              }).then(data => {
                  console.log("Response Data:", data);  // This should contain the chat_id
                  const chatId = data[0].chatId;
                  const chatTitle = data[0].chatTitle;
                  addChat({id: chatId, title: chatTitle})
                  navigate(chatId, { replace: true });
              }).catch(error => {
                  console.error("Error:", error);
              });

              console.log("Chat imported successfully.");
            } else {
              console.error("Invalid chat format.");
            }
          } catch (error) {
            console.error("Error parsing the file:", error);
          }
        };
  
        reader.readAsText(file);  // Read the file as text
      }
    };
  
    input.click();  // Trigger the file input click to open the file dialog
  };

  return (
    <div
      className={"Sidebar" + (menuOpen ? "" : " close")}
    >
      <Button
        type="default"
        size="small"
        className="menu-button"
        onClick={() => setMenuOpen(!menuOpen)}
      >
        {menuOpen ? <MenuUnfoldOutlined/>: <MenuFoldOutlined/>}
      </Button>
      <Menu
        className="menu-list"
        items={menuItems}
        onClick={({key}) => selectChat(key)}
        // defaultSelectedKeys={[location.pathname.replace("/", "")]}
        selectedKeys={[location.pathname.replace("/", "")]}
      />
      <div className="menu-actions">
        <Tooltip title="Import Chat">
          <Button icon={<ImportOutlined />} onClick={importChat}/>
        </Tooltip>
        <Tooltip title="Export Chat">
          <Button icon={<ExportOutlined />} disabled={location.pathname === "/"} onClick={exportChat}/>
        </Tooltip>
        <Tooltip title="Delete Chat">
          <Button icon={<DeleteOutlined />} disabled={location.pathname === "/"} onClick={deleteCurrentChat}/>
        </Tooltip>
      </div>
    </div>
  );
};

export default Sidebar;