
export const AppIcon = () => {
  return (
    <svg
         viewBox="0 0 384 383.999986" preserveAspectRatio="xMidYMid meet" version="1.0">
      <defs>
        <clipPath id="492733c605">
          <path
            d="M 108.144531 19.199219 L 201.453125 19.199219 L 201.453125 112.507812 L 108.144531 112.507812 Z M 108.144531 19.199219 "
            clipRule="nonzero"/>
        </clipPath>
        <clipPath id="b77456e5c6">
          <path
            d="M 154.800781 19.199219 C 129.035156 19.199219 108.144531 40.085938 108.144531 65.855469 C 108.144531 91.621094 129.035156 112.507812 154.800781 112.507812 C 180.566406 112.507812 201.453125 91.621094 201.453125 65.855469 C 201.453125 40.085938 180.566406 19.199219 154.800781 19.199219 Z M 154.800781 19.199219 "
            clipRule="nonzero"/>
        </clipPath>
        <linearGradient x1="-0.0000112653" gradientTransform="matrix(0.364487, 0, 0, 0.364487, 108.145373, 19.200009)"
                        y1="128" x2="255.999985" gradientUnits="userSpaceOnUse" y2="128" id="c99b5c78f6">
          <stop stopOpacity="1" stopColor="rgb(0%, 28.999329%, 67.799377%)" offset="0"/>
          <stop stopOpacity="1" stopColor="rgb(0.309753%, 29.051208%, 67.886353%)" offset="0.00390625"/>
          <stop stopOpacity="1" stopColor="rgb(0.621033%, 29.104614%, 67.974854%)" offset="0.0078125"/>
          <stop stopOpacity="1" stopColor="rgb(0.932312%, 29.156494%, 68.061829%)" offset="0.0117188"/>
          <stop stopOpacity="1" stopColor="rgb(1.243591%, 29.208374%, 68.148804%)" offset="0.015625"/>
          <stop stopOpacity="1" stopColor="rgb(1.553345%, 29.260254%, 68.235779%)" offset="0.0195312"/>
          <stop stopOpacity="1" stopColor="rgb(1.864624%, 29.31366%, 68.32428%)" offset="0.0234375"/>
          <stop stopOpacity="1" stopColor="rgb(2.175903%, 29.36554%, 68.411255%)" offset="0.0273438"/>
          <stop stopOpacity="1" stopColor="rgb(2.487183%, 29.417419%, 68.499756%)" offset="0.03125"/>
          <stop stopOpacity="1" stopColor="rgb(2.796936%, 29.469299%, 68.586731%)" offset="0.0351562"/>
          <stop stopOpacity="1" stopColor="rgb(3.108215%, 29.522705%, 68.673706%)" offset="0.0390625"/>
          <stop stopOpacity="1" stopColor="rgb(3.419495%, 29.574585%, 68.760681%)" offset="0.0429688"/>
          <stop stopOpacity="1" stopColor="rgb(3.730774%, 29.627991%, 68.849182%)" offset="0.046875"/>
          <stop stopOpacity="1" stopColor="rgb(4.040527%, 29.679871%, 68.936157%)" offset="0.0507812"/>
          <stop stopOpacity="1" stopColor="rgb(4.351807%, 29.73175%, 69.024658%)" offset="0.0546875"/>
          <stop stopOpacity="1" stopColor="rgb(4.663086%, 29.78363%, 69.111633%)" offset="0.0585938"/>
          <stop stopOpacity="1" stopColor="rgb(4.974365%, 29.837036%, 69.198608%)" offset="0.0625"/>
          <stop stopOpacity="1" stopColor="rgb(5.284119%, 29.888916%, 69.285583%)" offset="0.0664062"/>
          <stop stopOpacity="1" stopColor="rgb(5.595398%, 29.940796%, 69.374084%)" offset="0.0703125"/>
          <stop stopOpacity="1" stopColor="rgb(5.906677%, 29.992676%, 69.46106%)" offset="0.0742188"/>
          <stop stopOpacity="1" stopColor="rgb(6.217957%, 30.046082%, 69.549561%)" offset="0.078125"/>
          <stop stopOpacity="1" stopColor="rgb(6.529236%, 30.097961%, 69.636536%)" offset="0.0820312"/>
          <stop stopOpacity="1" stopColor="rgb(6.840515%, 30.151367%, 69.723511%)" offset="0.0859375"/>
          <stop stopOpacity="1" stopColor="rgb(7.150269%, 30.203247%, 69.810486%)" offset="0.0898438"/>
          <stop stopOpacity="1" stopColor="rgb(7.461548%, 30.255127%, 69.898987%)" offset="0.09375"/>
          <stop stopOpacity="1" stopColor="rgb(7.772827%, 30.307007%, 69.985962%)" offset="0.0976562"/>
          <stop stopOpacity="1" stopColor="rgb(8.084106%, 30.360413%, 70.074463%)" offset="0.101562"/>
          <stop stopOpacity="1" stopColor="rgb(8.39386%, 30.412292%, 70.161438%)" offset="0.105469"/>
          <stop stopOpacity="1" stopColor="rgb(8.705139%, 30.464172%, 70.249939%)" offset="0.109375"/>
          <stop stopOpacity="1" stopColor="rgb(9.016418%, 30.516052%, 70.336914%)" offset="0.113281"/>
          <stop stopOpacity="1" stopColor="rgb(9.327698%, 30.569458%, 70.423889%)" offset="0.117188"/>
          <stop stopOpacity="1" stopColor="rgb(9.637451%, 30.621338%, 70.510864%)" offset="0.121094"/>
          <stop stopOpacity="1" stopColor="rgb(9.94873%, 30.674744%, 70.599365%)" offset="0.125"/>
          <stop stopOpacity="1" stopColor="rgb(10.26001%, 30.726624%, 70.68634%)" offset="0.128906"/>
          <stop stopOpacity="1" stopColor="rgb(10.571289%, 30.778503%, 70.774841%)" offset="0.132812"/>
          <stop stopOpacity="1" stopColor="rgb(10.881042%, 30.830383%, 70.861816%)" offset="0.136719"/>
          <stop stopOpacity="1" stopColor="rgb(11.192322%, 30.883789%, 70.948792%)" offset="0.140625"/>
          <stop stopOpacity="1" stopColor="rgb(11.503601%, 30.935669%, 71.035767%)" offset="0.144531"/>
          <stop stopOpacity="1" stopColor="rgb(11.81488%, 30.987549%, 71.124268%)" offset="0.148438"/>
          <stop stopOpacity="1" stopColor="rgb(12.12616%, 31.039429%, 71.211243%)" offset="0.152344"/>
          <stop stopOpacity="1" stopColor="rgb(12.437439%, 31.092834%, 71.299744%)" offset="0.15625"/>
          <stop stopOpacity="1" stopColor="rgb(12.747192%, 31.144714%, 71.386719%)" offset="0.160156"/>
          <stop stopOpacity="1" stopColor="rgb(13.058472%, 31.19812%, 71.473694%)" offset="0.164062"/>
          <stop stopOpacity="1" stopColor="rgb(13.369751%, 31.25%, 71.560669%)" offset="0.167969"/>
          <stop stopOpacity="1" stopColor="rgb(13.68103%, 31.30188%, 71.64917%)" offset="0.171875"/>
          <stop stopOpacity="1" stopColor="rgb(13.990784%, 31.35376%, 71.736145%)" offset="0.175781"/>
          <stop stopOpacity="1" stopColor="rgb(14.302063%, 31.407166%, 71.824646%)" offset="0.179688"/>
          <stop stopOpacity="1" stopColor="rgb(14.613342%, 31.459045%, 71.911621%)" offset="0.183594"/>
          <stop stopOpacity="1" stopColor="rgb(14.924622%, 31.512451%, 71.998596%)" offset="0.1875"/>
          <stop stopOpacity="1" stopColor="rgb(15.234375%, 31.564331%, 72.085571%)" offset="0.191406"/>
          <stop stopOpacity="1" stopColor="rgb(15.545654%, 31.616211%, 72.174072%)" offset="0.195312"/>
          <stop stopOpacity="1" stopColor="rgb(15.856934%, 31.668091%, 72.261047%)" offset="0.199219"/>
          <stop stopOpacity="1" stopColor="rgb(16.168213%, 31.721497%, 72.349548%)" offset="0.203125"/>
          <stop stopOpacity="1" stopColor="rgb(16.477966%, 31.773376%, 72.436523%)" offset="0.207031"/>
          <stop stopOpacity="1" stopColor="rgb(16.789246%, 31.825256%, 72.523499%)" offset="0.210938"/>
          <stop stopOpacity="1" stopColor="rgb(17.100525%, 31.877136%, 72.610474%)" offset="0.214844"/>
          <stop stopOpacity="1" stopColor="rgb(17.411804%, 31.930542%, 72.698975%)" offset="0.21875"/>
          <stop stopOpacity="1" stopColor="rgb(17.723083%, 31.982422%, 72.78595%)" offset="0.222656"/>
          <stop stopOpacity="1" stopColor="rgb(18.034363%, 32.035828%, 72.874451%)" offset="0.226562"/>
          <stop stopOpacity="1" stopColor="rgb(18.344116%, 32.087708%, 72.961426%)" offset="0.230469"/>
          <stop stopOpacity="1" stopColor="rgb(18.655396%, 32.139587%, 73.049927%)" offset="0.234375"/>
          <stop stopOpacity="1" stopColor="rgb(18.966675%, 32.191467%, 73.136902%)" offset="0.238281"/>
          <stop stopOpacity="1" stopColor="rgb(19.277954%, 32.244873%, 73.223877%)" offset="0.242188"/>
          <stop stopOpacity="1" stopColor="rgb(19.587708%, 32.296753%, 73.310852%)" offset="0.246094"/>
          <stop stopOpacity="1" stopColor="rgb(19.898987%, 32.348633%, 73.399353%)" offset="0.25"/>
          <stop stopOpacity="1" stopColor="rgb(20.210266%, 32.400513%, 73.486328%)" offset="0.253906"/>
          <stop stopOpacity="1" stopColor="rgb(20.521545%, 32.453918%, 73.574829%)" offset="0.257812"/>
          <stop stopOpacity="1" stopColor="rgb(20.831299%, 32.505798%, 73.661804%)" offset="0.261719"/>
          <stop stopOpacity="1" stopColor="rgb(21.142578%, 32.559204%, 73.748779%)" offset="0.265625"/>
          <stop stopOpacity="1" stopColor="rgb(21.453857%, 32.611084%, 73.835754%)" offset="0.269531"/>
          <stop stopOpacity="1" stopColor="rgb(21.765137%, 32.662964%, 73.924255%)" offset="0.273438"/>
          <stop stopOpacity="1" stopColor="rgb(22.07489%, 32.714844%, 74.01123%)" offset="0.277344"/>
          <stop stopOpacity="1" stopColor="rgb(22.386169%, 32.76825%, 74.099731%)" offset="0.28125"/>
          <stop stopOpacity="1" stopColor="rgb(22.697449%, 32.820129%, 74.186707%)" offset="0.285156"/>
          <stop stopOpacity="1" stopColor="rgb(23.008728%, 32.872009%, 74.273682%)" offset="0.289062"/>
          <stop stopOpacity="1" stopColor="rgb(23.318481%, 32.923889%, 74.360657%)" offset="0.292969"/>
          <stop stopOpacity="1" stopColor="rgb(23.629761%, 32.977295%, 74.449158%)" offset="0.296875"/>
          <stop stopOpacity="1" stopColor="rgb(23.94104%, 33.029175%, 74.536133%)" offset="0.300781"/>
          <stop stopOpacity="1" stopColor="rgb(24.252319%, 33.082581%, 74.624634%)" offset="0.304688"/>
          <stop stopOpacity="1" stopColor="rgb(24.563599%, 33.13446%, 74.711609%)" offset="0.308594"/>
          <stop stopOpacity="1" stopColor="rgb(24.874878%, 33.18634%, 74.798584%)" offset="0.3125"/>
          <stop stopOpacity="1" stopColor="rgb(25.184631%, 33.23822%, 74.885559%)" offset="0.316406"/>
          <stop stopOpacity="1" stopColor="rgb(25.495911%, 33.291626%, 74.97406%)" offset="0.320312"/>
          <stop stopOpacity="1" stopColor="rgb(25.80719%, 33.343506%, 75.061035%)" offset="0.324219"/>
          <stop stopOpacity="1" stopColor="rgb(26.118469%, 33.395386%, 75.149536%)" offset="0.328125"/>
          <stop stopOpacity="1" stopColor="rgb(26.428223%, 33.447266%, 75.236511%)" offset="0.332031"/>
          <stop stopOpacity="1" stopColor="rgb(26.739502%, 33.500671%, 75.323486%)" offset="0.335938"/>
          <stop stopOpacity="1" stopColor="rgb(27.050781%, 33.552551%, 75.410461%)" offset="0.339844"/>
          <stop stopOpacity="1" stopColor="rgb(27.362061%, 33.605957%, 75.498962%)" offset="0.34375"/>
          <stop stopOpacity="1" stopColor="rgb(27.671814%, 33.657837%, 75.585938%)" offset="0.347656"/>
          <stop stopOpacity="1" stopColor="rgb(27.983093%, 33.709717%, 75.674438%)" offset="0.351562"/>
          <stop stopOpacity="1" stopColor="rgb(28.294373%, 33.761597%, 75.761414%)" offset="0.355469"/>
          <stop stopOpacity="1" stopColor="rgb(28.605652%, 33.815002%, 75.849915%)" offset="0.359375"/>
          <stop stopOpacity="1" stopColor="rgb(28.915405%, 33.866882%, 75.93689%)" offset="0.363281"/>
          <stop stopOpacity="1" stopColor="rgb(29.226685%, 33.920288%, 76.023865%)" offset="0.367188"/>
          <stop stopOpacity="1" stopColor="rgb(29.537964%, 33.972168%, 76.11084%)" offset="0.371094"/>
          <stop stopOpacity="1" stopColor="rgb(29.849243%, 34.024048%, 76.199341%)" offset="0.375"/>
          <stop stopOpacity="1" stopColor="rgb(30.160522%, 34.075928%, 76.286316%)" offset="0.378906"/>
          <stop stopOpacity="1" stopColor="rgb(30.471802%, 34.129333%, 76.374817%)" offset="0.382812"/>
          <stop stopOpacity="1" stopColor="rgb(30.781555%, 34.181213%, 76.461792%)" offset="0.386719"/>
          <stop stopOpacity="1" stopColor="rgb(31.092834%, 34.233093%, 76.548767%)" offset="0.390625"/>
          <stop stopOpacity="1" stopColor="rgb(31.404114%, 34.284973%, 76.635742%)" offset="0.394531"/>
          <stop stopOpacity="1" stopColor="rgb(31.715393%, 34.338379%, 76.724243%)" offset="0.398438"/>
          <stop stopOpacity="1" stopColor="rgb(32.025146%, 34.390259%, 76.811218%)" offset="0.402344"/>
          <stop stopOpacity="1" stopColor="rgb(32.336426%, 34.443665%, 76.899719%)" offset="0.40625"/>
          <stop stopOpacity="1" stopColor="rgb(32.647705%, 34.495544%, 76.986694%)" offset="0.410156"/>
          <stop stopOpacity="1" stopColor="rgb(32.958984%, 34.547424%, 77.073669%)" offset="0.414062"/>
          <stop stopOpacity="1" stopColor="rgb(33.268738%, 34.599304%, 77.160645%)" offset="0.417969"/>
          <stop stopOpacity="1" stopColor="rgb(33.580017%, 34.65271%, 77.249146%)" offset="0.421875"/>
          <stop stopOpacity="1" stopColor="rgb(33.891296%, 34.70459%, 77.336121%)" offset="0.425781"/>
          <stop stopOpacity="1" stopColor="rgb(34.202576%, 34.75647%, 77.424622%)" offset="0.429688"/>
          <stop stopOpacity="1" stopColor="rgb(34.512329%, 34.80835%, 77.511597%)" offset="0.433594"/>
          <stop stopOpacity="1" stopColor="rgb(34.823608%, 34.861755%, 77.598572%)" offset="0.4375"/>
          <stop stopOpacity="1" stopColor="rgb(35.134888%, 34.913635%, 77.685547%)" offset="0.441406"/>
          <stop stopOpacity="1" stopColor="rgb(35.446167%, 34.967041%, 77.774048%)" offset="0.445312"/>
          <stop stopOpacity="1" stopColor="rgb(35.757446%, 35.018921%, 77.861023%)" offset="0.449219"/>
          <stop stopOpacity="1" stopColor="rgb(36.068726%, 35.070801%, 77.949524%)" offset="0.453125"/>
          <stop stopOpacity="1" stopColor="rgb(36.378479%, 35.122681%, 78.036499%)" offset="0.457031"/>
          <stop stopOpacity="1" stopColor="rgb(36.689758%, 35.176086%, 78.123474%)" offset="0.460938"/>
          <stop stopOpacity="1" stopColor="rgb(37.001038%, 35.227966%, 78.210449%)" offset="0.464844"/>
          <stop stopOpacity="1" stopColor="rgb(37.312317%, 35.279846%, 78.29895%)" offset="0.46875"/>
          <stop stopOpacity="1" stopColor="rgb(37.62207%, 35.331726%, 78.385925%)" offset="0.472656"/>
          <stop stopOpacity="1" stopColor="rgb(37.93335%, 35.385132%, 78.474426%)" offset="0.476562"/>
          <stop stopOpacity="1" stopColor="rgb(38.244629%, 35.437012%, 78.561401%)" offset="0.480469"/>
          <stop stopOpacity="1" stopColor="rgb(38.555908%, 35.490417%, 78.649902%)" offset="0.484375"/>
          <stop stopOpacity="1" stopColor="rgb(38.865662%, 35.542297%, 78.736877%)" offset="0.488281"/>
          <stop stopOpacity="1" stopColor="rgb(39.176941%, 35.594177%, 78.823853%)" offset="0.492188"/>
          <stop stopOpacity="1" stopColor="rgb(39.48822%, 35.646057%, 78.910828%)" offset="0.496094"/>
          <stop stopOpacity="1" stopColor="rgb(39.7995%, 35.699463%, 78.999329%)" offset="0.5"/>
          <stop stopOpacity="1" stopColor="rgb(40.109253%, 35.751343%, 79.086304%)" offset="0.503906"/>
          <stop stopOpacity="1" stopColor="rgb(40.420532%, 35.803223%, 79.174805%)" offset="0.507812"/>
          <stop stopOpacity="1" stopColor="rgb(40.731812%, 35.855103%, 79.26178%)" offset="0.511719"/>
          <stop stopOpacity="1" stopColor="rgb(41.043091%, 35.908508%, 79.348755%)" offset="0.515625"/>
          <stop stopOpacity="1" stopColor="rgb(41.352844%, 35.960388%, 79.43573%)" offset="0.519531"/>
          <stop stopOpacity="1" stopColor="rgb(41.664124%, 36.013794%, 79.524231%)" offset="0.523437"/>
          <stop stopOpacity="1" stopColor="rgb(41.975403%, 36.065674%, 79.611206%)" offset="0.527344"/>
          <stop stopOpacity="1" stopColor="rgb(42.286682%, 36.117554%, 79.699707%)" offset="0.53125"/>
          <stop stopOpacity="1" stopColor="rgb(42.597961%, 36.169434%, 79.786682%)" offset="0.535156"/>
          <stop stopOpacity="1" stopColor="rgb(42.909241%, 36.222839%, 79.873657%)" offset="0.539062"/>
          <stop stopOpacity="1" stopColor="rgb(43.218994%, 36.274719%, 79.960632%)" offset="0.542969"/>
          <stop stopOpacity="1" stopColor="rgb(43.530273%, 36.326599%, 80.049133%)" offset="0.546875"/>
          <stop stopOpacity="1" stopColor="rgb(43.841553%, 36.378479%, 80.136108%)" offset="0.550781"/>
          <stop stopOpacity="1" stopColor="rgb(44.152832%, 36.431885%, 80.224609%)" offset="0.554688"/>
          <stop stopOpacity="1" stopColor="rgb(44.462585%, 36.483765%, 80.311584%)" offset="0.558594"/>
          <stop stopOpacity="1" stopColor="rgb(44.773865%, 36.53717%, 80.39856%)" offset="0.5625"/>
          <stop stopOpacity="1" stopColor="rgb(45.085144%, 36.58905%, 80.485535%)" offset="0.566406"/>
          <stop stopOpacity="1" stopColor="rgb(45.396423%, 36.64093%, 80.574036%)" offset="0.570312"/>
          <stop stopOpacity="1" stopColor="rgb(45.706177%, 36.69281%, 80.661011%)" offset="0.574219"/>
          <stop stopOpacity="1" stopColor="rgb(46.017456%, 36.746216%, 80.749512%)" offset="0.578125"/>
          <stop stopOpacity="1" stopColor="rgb(46.328735%, 36.798096%, 80.836487%)" offset="0.582031"/>
          <stop stopOpacity="1" stopColor="rgb(46.640015%, 36.851501%, 80.924988%)" offset="0.585938"/>
          <stop stopOpacity="1" stopColor="rgb(46.949768%, 36.903381%, 81.011963%)" offset="0.589844"/>
          <stop stopOpacity="1" stopColor="rgb(47.261047%, 36.955261%, 81.098938%)" offset="0.59375"/>
          <stop stopOpacity="1" stopColor="rgb(47.572327%, 37.007141%, 81.185913%)" offset="0.597656"/>
          <stop stopOpacity="1" stopColor="rgb(47.883606%, 37.060547%, 81.274414%)" offset="0.601562"/>
          <stop stopOpacity="1" stopColor="rgb(48.194885%, 37.112427%, 81.361389%)" offset="0.605469"/>
          <stop stopOpacity="1" stopColor="rgb(48.506165%, 37.164307%, 81.44989%)" offset="0.609375"/>
          <stop stopOpacity="1" stopColor="rgb(48.815918%, 37.216187%, 81.536865%)" offset="0.613281"/>
          <stop stopOpacity="1" stopColor="rgb(49.127197%, 37.269592%, 81.62384%)" offset="0.617187"/>
          <stop stopOpacity="1" stopColor="rgb(49.438477%, 37.321472%, 81.710815%)" offset="0.621094"/>
          <stop stopOpacity="1" stopColor="rgb(49.749756%, 37.374878%, 81.799316%)" offset="0.625"/>
          <stop stopOpacity="1" stopColor="rgb(50.059509%, 37.426758%, 81.886292%)" offset="0.628906"/>
          <stop stopOpacity="1" stopColor="rgb(50.370789%, 37.478638%, 81.974792%)" offset="0.632813"/>
          <stop stopOpacity="1" stopColor="rgb(50.682068%, 37.530518%, 82.061768%)" offset="0.636719"/>
          <stop stopOpacity="1" stopColor="rgb(50.993347%, 37.583923%, 82.148743%)" offset="0.640625"/>
          <stop stopOpacity="1" stopColor="rgb(51.303101%, 37.635803%, 82.235718%)" offset="0.644531"/>
          <stop stopOpacity="1" stopColor="rgb(51.61438%, 37.687683%, 82.324219%)" offset="0.648438"/>
          <stop stopOpacity="1" stopColor="rgb(51.925659%, 37.739563%, 82.411194%)" offset="0.652344"/>
          <stop stopOpacity="1" stopColor="rgb(52.236938%, 37.792969%, 82.499695%)" offset="0.65625"/>
          <stop stopOpacity="1" stopColor="rgb(52.546692%, 37.844849%, 82.58667%)" offset="0.660156"/>
          <stop stopOpacity="1" stopColor="rgb(52.857971%, 37.898254%, 82.673645%)" offset="0.664062"/>
          <stop stopOpacity="1" stopColor="rgb(53.16925%, 37.950134%, 82.76062%)" offset="0.667969"/>
          <stop stopOpacity="1" stopColor="rgb(53.48053%, 38.002014%, 82.849121%)" offset="0.671875"/>
          <stop stopOpacity="1" stopColor="rgb(53.791809%, 38.053894%, 82.936096%)" offset="0.675781"/>
          <stop stopOpacity="1" stopColor="rgb(54.103088%, 38.1073%, 83.024597%)" offset="0.679688"/>
          <stop stopOpacity="1" stopColor="rgb(54.412842%, 38.15918%, 83.111572%)" offset="0.683594"/>
          <stop stopOpacity="1" stopColor="rgb(54.724121%, 38.21106%, 83.198547%)" offset="0.6875"/>
          <stop stopOpacity="1" stopColor="rgb(55.0354%, 38.262939%, 83.285522%)" offset="0.691406"/>
          <stop stopOpacity="1" stopColor="rgb(55.34668%, 38.316345%, 83.374023%)" offset="0.695312"/>
          <stop stopOpacity="1" stopColor="rgb(55.656433%, 38.368225%, 83.460999%)" offset="0.699219"/>
          <stop stopOpacity="1" stopColor="rgb(55.967712%, 38.421631%, 83.5495%)" offset="0.703125"/>
          <stop stopOpacity="1" stopColor="rgb(56.278992%, 38.473511%, 83.636475%)" offset="0.707031"/>
          <stop stopOpacity="1" stopColor="rgb(56.590271%, 38.525391%, 83.724976%)" offset="0.710938"/>
          <stop stopOpacity="1" stopColor="rgb(56.900024%, 38.577271%, 83.811951%)" offset="0.714844"/>
          <stop stopOpacity="1" stopColor="rgb(57.211304%, 38.630676%, 83.898926%)" offset="0.71875"/>
          <stop stopOpacity="1" stopColor="rgb(57.522583%, 38.682556%, 83.985901%)" offset="0.722656"/>
          <stop stopOpacity="1" stopColor="rgb(57.833862%, 38.734436%, 84.074402%)" offset="0.726563"/>
          <stop stopOpacity="1" stopColor="rgb(58.143616%, 38.786316%, 84.161377%)" offset="0.730469"/>
          <stop stopOpacity="1" stopColor="rgb(58.454895%, 38.839722%, 84.249878%)" offset="0.734375"/>
          <stop stopOpacity="1" stopColor="rgb(58.766174%, 38.891602%, 84.336853%)" offset="0.738281"/>
          <stop stopOpacity="1" stopColor="rgb(59.077454%, 38.945007%, 84.423828%)" offset="0.742188"/>
          <stop stopOpacity="1" stopColor="rgb(59.387207%, 38.996887%, 84.510803%)" offset="0.746094"/>
          <stop stopOpacity="1" stopColor="rgb(59.698486%, 39.048767%, 84.599304%)" offset="0.75"/>
          <stop stopOpacity="1" stopColor="rgb(60.009766%, 39.100647%, 84.686279%)" offset="0.753906"/>
          <stop stopOpacity="1" stopColor="rgb(60.321045%, 39.154053%, 84.77478%)" offset="0.757812"/>
          <stop stopOpacity="1" stopColor="rgb(60.632324%, 39.205933%, 84.861755%)" offset="0.761719"/>
          <stop stopOpacity="1" stopColor="rgb(60.943604%, 39.259338%, 84.94873%)" offset="0.765625"/>
          <stop stopOpacity="1" stopColor="rgb(61.253357%, 39.311218%, 85.035706%)" offset="0.769531"/>
          <stop stopOpacity="1" stopColor="rgb(61.564636%, 39.363098%, 85.124207%)" offset="0.773437"/>
          <stop stopOpacity="1" stopColor="rgb(61.875916%, 39.414978%, 85.211182%)" offset="0.777344"/>
          <stop stopOpacity="1" stopColor="rgb(62.187195%, 39.468384%, 85.299683%)" offset="0.78125"/>
          <stop stopOpacity="1" stopColor="rgb(62.496948%, 39.520264%, 85.386658%)" offset="0.785156"/>
          <stop stopOpacity="1" stopColor="rgb(62.808228%, 39.572144%, 85.473633%)" offset="0.789062"/>
          <stop stopOpacity="1" stopColor="rgb(63.119507%, 39.624023%, 85.560608%)" offset="0.792969"/>
          <stop stopOpacity="1" stopColor="rgb(63.430786%, 39.677429%, 85.649109%)" offset="0.796875"/>
          <stop stopOpacity="1" stopColor="rgb(63.74054%, 39.729309%, 85.736084%)" offset="0.800781"/>
          <stop stopOpacity="1" stopColor="rgb(64.051819%, 39.782715%, 85.824585%)" offset="0.804688"/>
          <stop stopOpacity="1" stopColor="rgb(64.363098%, 39.834595%, 85.91156%)" offset="0.808594"/>
          <stop stopOpacity="1" stopColor="rgb(64.674377%, 39.886475%, 85.998535%)" offset="0.8125"/>
          <stop stopOpacity="1" stopColor="rgb(64.984131%, 39.938354%, 86.08551%)" offset="0.816406"/>
          <stop stopOpacity="1" stopColor="rgb(65.29541%, 39.99176%, 86.174011%)" offset="0.820312"/>
          <stop stopOpacity="1" stopColor="rgb(65.606689%, 40.04364%, 86.260986%)" offset="0.824219"/>
          <stop stopOpacity="1" stopColor="rgb(65.917969%, 40.09552%, 86.349487%)" offset="0.828125"/>
          <stop stopOpacity="1" stopColor="rgb(66.229248%, 40.1474%, 86.436462%)" offset="0.832031"/>
          <stop stopOpacity="1" stopColor="rgb(66.540527%, 40.200806%, 86.524963%)" offset="0.835938"/>
          <stop stopOpacity="1" stopColor="rgb(66.850281%, 40.252686%, 86.611938%)" offset="0.839844"/>
          <stop stopOpacity="1" stopColor="rgb(67.16156%, 40.306091%, 86.698914%)" offset="0.84375"/>
          <stop stopOpacity="1" stopColor="rgb(67.472839%, 40.357971%, 86.785889%)" offset="0.847656"/>
          <stop stopOpacity="1" stopColor="rgb(67.784119%, 40.409851%, 86.87439%)" offset="0.851562"/>
          <stop stopOpacity="1" stopColor="rgb(68.093872%, 40.461731%, 86.961365%)" offset="0.855469"/>
          <stop stopOpacity="1" stopColor="rgb(68.405151%, 40.515137%, 87.049866%)" offset="0.859375"/>
          <stop stopOpacity="1" stopColor="rgb(68.716431%, 40.567017%, 87.136841%)" offset="0.863281"/>
          <stop stopOpacity="1" stopColor="rgb(69.02771%, 40.618896%, 87.223816%)" offset="0.867187"/>
          <stop stopOpacity="1" stopColor="rgb(69.337463%, 40.670776%, 87.310791%)" offset="0.871094"/>
          <stop stopOpacity="1" stopColor="rgb(69.648743%, 40.724182%, 87.399292%)" offset="0.875"/>
          <stop stopOpacity="1" stopColor="rgb(69.960022%, 40.776062%, 87.486267%)" offset="0.878906"/>
          <stop stopOpacity="1" stopColor="rgb(70.271301%, 40.829468%, 87.574768%)" offset="0.882813"/>
          <stop stopOpacity="1" stopColor="rgb(70.581055%, 40.881348%, 87.661743%)" offset="0.886719"/>
          <stop stopOpacity="1" stopColor="rgb(70.892334%, 40.933228%, 87.748718%)" offset="0.890625"/>
          <stop stopOpacity="1" stopColor="rgb(71.203613%, 40.985107%, 87.835693%)" offset="0.894531"/>
          <stop stopOpacity="1" stopColor="rgb(71.514893%, 41.038513%, 87.924194%)" offset="0.898438"/>
          <stop stopOpacity="1" stopColor="rgb(71.826172%, 41.090393%, 88.011169%)" offset="0.902344"/>
          <stop stopOpacity="1" stopColor="rgb(72.137451%, 41.142273%, 88.09967%)" offset="0.90625"/>
          <stop stopOpacity="1" stopColor="rgb(72.447205%, 41.194153%, 88.186646%)" offset="0.910156"/>
          <stop stopOpacity="1" stopColor="rgb(72.758484%, 41.247559%, 88.273621%)" offset="0.914062"/>
          <stop stopOpacity="1" stopColor="rgb(73.069763%, 41.299438%, 88.360596%)" offset="0.917969"/>
          <stop stopOpacity="1" stopColor="rgb(73.381042%, 41.352844%, 88.449097%)" offset="0.921875"/>
          <stop stopOpacity="1" stopColor="rgb(73.690796%, 41.404724%, 88.536072%)" offset="0.925781"/>
          <stop stopOpacity="1" stopColor="rgb(74.002075%, 41.456604%, 88.624573%)" offset="0.929688"/>
          <stop stopOpacity="1" stopColor="rgb(74.313354%, 41.508484%, 88.711548%)" offset="0.933594"/>
          <stop stopOpacity="1" stopColor="rgb(74.624634%, 41.56189%, 88.798523%)" offset="0.9375"/>
          <stop stopOpacity="1" stopColor="rgb(74.934387%, 41.61377%, 88.885498%)" offset="0.941406"/>
          <stop stopOpacity="1" stopColor="rgb(75.245667%, 41.667175%, 88.973999%)" offset="0.945312"/>
          <stop stopOpacity="1" stopColor="rgb(75.556946%, 41.719055%, 89.060974%)" offset="0.949219"/>
          <stop stopOpacity="1" stopColor="rgb(75.868225%, 41.770935%, 89.149475%)" offset="0.953125"/>
          <stop stopOpacity="1" stopColor="rgb(76.177979%, 41.822815%, 89.23645%)" offset="0.957031"/>
          <stop stopOpacity="1" stopColor="rgb(76.489258%, 41.876221%, 89.324951%)" offset="0.960938"/>
          <stop stopOpacity="1" stopColor="rgb(76.800537%, 41.928101%, 89.411926%)" offset="0.964844"/>
          <stop stopOpacity="1" stopColor="rgb(77.111816%, 41.97998%, 89.498901%)" offset="0.96875"/>
          <stop stopOpacity="1" stopColor="rgb(77.42157%, 42.03186%, 89.585876%)" offset="0.972656"/>
          <stop stopOpacity="1" stopColor="rgb(77.732849%, 42.085266%, 89.674377%)" offset="0.976563"/>
          <stop stopOpacity="1" stopColor="rgb(78.044128%, 42.137146%, 89.761353%)" offset="0.980469"/>
          <stop stopOpacity="1" stopColor="rgb(78.355408%, 42.190552%, 89.849854%)" offset="0.984375"/>
          <stop stopOpacity="1" stopColor="rgb(78.666687%, 42.242432%, 89.936829%)" offset="0.988281"/>
          <stop stopOpacity="1" stopColor="rgb(78.977966%, 42.294312%, 90.023804%)" offset="0.992188"/>
          <stop stopOpacity="1" stopColor="rgb(79.28772%, 42.346191%, 90.110779%)" offset="0.996094"/>
          <stop stopOpacity="1" stopColor="rgb(79.598999%, 42.399597%, 90.19928%)" offset="1"/>
        </linearGradient>
        <clipPath id="7570c1c446">
          <path
            d="M 33.746094 145.34375 L 127.054688 145.34375 L 127.054688 238.65625 L 33.746094 238.65625 Z M 33.746094 145.34375 "
            clipRule="nonzero"/>
        </clipPath>
        <clipPath id="4a928b27db">
          <path
            d="M 80.398438 145.34375 C 54.632812 145.34375 33.746094 166.234375 33.746094 192 C 33.746094 217.765625 54.632812 238.65625 80.398438 238.65625 C 106.164062 238.65625 127.054688 217.765625 127.054688 192 C 127.054688 166.234375 106.164062 145.34375 80.398438 145.34375 Z M 80.398438 145.34375 "
            clipRule="nonzero"/>
        </clipPath>
        <linearGradient x1="-0.0000041868" gradientTransform="matrix(0.364487, 0, 0, 0.364487, 33.744795, 145.345645)"
                        y1="128" x2="255.999992" gradientUnits="userSpaceOnUse" y2="128" id="bbc8435cdd">
          <stop stopOpacity="1" stopColor="rgb(0%, 28.999329%, 67.799377%)" offset="0"/>
          <stop stopOpacity="1" stopColor="rgb(0.309753%, 29.051208%, 67.886353%)" offset="0.00390625"/>
          <stop stopOpacity="1" stopColor="rgb(0.621033%, 29.104614%, 67.974854%)" offset="0.0078125"/>
          <stop stopOpacity="1" stopColor="rgb(0.932312%, 29.156494%, 68.061829%)" offset="0.0117188"/>
          <stop stopOpacity="1" stopColor="rgb(1.243591%, 29.208374%, 68.148804%)" offset="0.015625"/>
          <stop stopOpacity="1" stopColor="rgb(1.553345%, 29.260254%, 68.235779%)" offset="0.0195312"/>
          <stop stopOpacity="1" stopColor="rgb(1.864624%, 29.31366%, 68.32428%)" offset="0.0234375"/>
          <stop stopOpacity="1" stopColor="rgb(2.175903%, 29.36554%, 68.411255%)" offset="0.0273438"/>
          <stop stopOpacity="1" stopColor="rgb(2.487183%, 29.417419%, 68.499756%)" offset="0.03125"/>
          <stop stopOpacity="1" stopColor="rgb(2.796936%, 29.469299%, 68.586731%)" offset="0.0351562"/>
          <stop stopOpacity="1" stopColor="rgb(3.108215%, 29.522705%, 68.673706%)" offset="0.0390625"/>
          <stop stopOpacity="1" stopColor="rgb(3.419495%, 29.574585%, 68.760681%)" offset="0.0429688"/>
          <stop stopOpacity="1" stopColor="rgb(3.730774%, 29.627991%, 68.849182%)" offset="0.046875"/>
          <stop stopOpacity="1" stopColor="rgb(4.040527%, 29.679871%, 68.936157%)" offset="0.0507812"/>
          <stop stopOpacity="1" stopColor="rgb(4.351807%, 29.73175%, 69.024658%)" offset="0.0546875"/>
          <stop stopOpacity="1" stopColor="rgb(4.663086%, 29.78363%, 69.111633%)" offset="0.0585938"/>
          <stop stopOpacity="1" stopColor="rgb(4.974365%, 29.837036%, 69.198608%)" offset="0.0625"/>
          <stop stopOpacity="1" stopColor="rgb(5.284119%, 29.888916%, 69.285583%)" offset="0.0664062"/>
          <stop stopOpacity="1" stopColor="rgb(5.595398%, 29.940796%, 69.374084%)" offset="0.0703125"/>
          <stop stopOpacity="1" stopColor="rgb(5.906677%, 29.992676%, 69.46106%)" offset="0.0742188"/>
          <stop stopOpacity="1" stopColor="rgb(6.217957%, 30.046082%, 69.549561%)" offset="0.078125"/>
          <stop stopOpacity="1" stopColor="rgb(6.529236%, 30.097961%, 69.636536%)" offset="0.0820312"/>
          <stop stopOpacity="1" stopColor="rgb(6.840515%, 30.151367%, 69.723511%)" offset="0.0859375"/>
          <stop stopOpacity="1" stopColor="rgb(7.150269%, 30.203247%, 69.810486%)" offset="0.0898438"/>
          <stop stopOpacity="1" stopColor="rgb(7.461548%, 30.255127%, 69.898987%)" offset="0.09375"/>
          <stop stopOpacity="1" stopColor="rgb(7.772827%, 30.307007%, 69.985962%)" offset="0.0976562"/>
          <stop stopOpacity="1" stopColor="rgb(8.084106%, 30.360413%, 70.074463%)" offset="0.101562"/>
          <stop stopOpacity="1" stopColor="rgb(8.39386%, 30.412292%, 70.161438%)" offset="0.105469"/>
          <stop stopOpacity="1" stopColor="rgb(8.705139%, 30.464172%, 70.249939%)" offset="0.109375"/>
          <stop stopOpacity="1" stopColor="rgb(9.016418%, 30.516052%, 70.336914%)" offset="0.113281"/>
          <stop stopOpacity="1" stopColor="rgb(9.327698%, 30.569458%, 70.423889%)" offset="0.117188"/>
          <stop stopOpacity="1" stopColor="rgb(9.637451%, 30.621338%, 70.510864%)" offset="0.121094"/>
          <stop stopOpacity="1" stopColor="rgb(9.94873%, 30.674744%, 70.599365%)" offset="0.125"/>
          <stop stopOpacity="1" stopColor="rgb(10.26001%, 30.726624%, 70.68634%)" offset="0.128906"/>
          <stop stopOpacity="1" stopColor="rgb(10.571289%, 30.778503%, 70.774841%)" offset="0.132812"/>
          <stop stopOpacity="1" stopColor="rgb(10.881042%, 30.830383%, 70.861816%)" offset="0.136719"/>
          <stop stopOpacity="1" stopColor="rgb(11.192322%, 30.883789%, 70.948792%)" offset="0.140625"/>
          <stop stopOpacity="1" stopColor="rgb(11.503601%, 30.935669%, 71.035767%)" offset="0.144531"/>
          <stop stopOpacity="1" stopColor="rgb(11.81488%, 30.987549%, 71.124268%)" offset="0.148438"/>
          <stop stopOpacity="1" stopColor="rgb(12.12616%, 31.039429%, 71.211243%)" offset="0.152344"/>
          <stop stopOpacity="1" stopColor="rgb(12.437439%, 31.092834%, 71.299744%)" offset="0.15625"/>
          <stop stopOpacity="1" stopColor="rgb(12.747192%, 31.144714%, 71.386719%)" offset="0.160156"/>
          <stop stopOpacity="1" stopColor="rgb(13.058472%, 31.19812%, 71.473694%)" offset="0.164062"/>
          <stop stopOpacity="1" stopColor="rgb(13.369751%, 31.25%, 71.560669%)" offset="0.167969"/>
          <stop stopOpacity="1" stopColor="rgb(13.68103%, 31.30188%, 71.64917%)" offset="0.171875"/>
          <stop stopOpacity="1" stopColor="rgb(13.990784%, 31.35376%, 71.736145%)" offset="0.175781"/>
          <stop stopOpacity="1" stopColor="rgb(14.302063%, 31.407166%, 71.824646%)" offset="0.179688"/>
          <stop stopOpacity="1" stopColor="rgb(14.613342%, 31.459045%, 71.911621%)" offset="0.183594"/>
          <stop stopOpacity="1" stopColor="rgb(14.924622%, 31.512451%, 71.998596%)" offset="0.1875"/>
          <stop stopOpacity="1" stopColor="rgb(15.234375%, 31.564331%, 72.085571%)" offset="0.191406"/>
          <stop stopOpacity="1" stopColor="rgb(15.545654%, 31.616211%, 72.174072%)" offset="0.195312"/>
          <stop stopOpacity="1" stopColor="rgb(15.856934%, 31.668091%, 72.261047%)" offset="0.199219"/>
          <stop stopOpacity="1" stopColor="rgb(16.168213%, 31.721497%, 72.349548%)" offset="0.203125"/>
          <stop stopOpacity="1" stopColor="rgb(16.477966%, 31.773376%, 72.436523%)" offset="0.207031"/>
          <stop stopOpacity="1" stopColor="rgb(16.789246%, 31.825256%, 72.523499%)" offset="0.210938"/>
          <stop stopOpacity="1" stopColor="rgb(17.100525%, 31.877136%, 72.610474%)" offset="0.214844"/>
          <stop stopOpacity="1" stopColor="rgb(17.411804%, 31.930542%, 72.698975%)" offset="0.21875"/>
          <stop stopOpacity="1" stopColor="rgb(17.723083%, 31.982422%, 72.78595%)" offset="0.222656"/>
          <stop stopOpacity="1" stopColor="rgb(18.034363%, 32.035828%, 72.874451%)" offset="0.226562"/>
          <stop stopOpacity="1" stopColor="rgb(18.344116%, 32.087708%, 72.961426%)" offset="0.230469"/>
          <stop stopOpacity="1" stopColor="rgb(18.655396%, 32.139587%, 73.049927%)" offset="0.234375"/>
          <stop stopOpacity="1" stopColor="rgb(18.966675%, 32.191467%, 73.136902%)" offset="0.238281"/>
          <stop stopOpacity="1" stopColor="rgb(19.277954%, 32.244873%, 73.223877%)" offset="0.242188"/>
          <stop stopOpacity="1" stopColor="rgb(19.587708%, 32.296753%, 73.310852%)" offset="0.246094"/>
          <stop stopOpacity="1" stopColor="rgb(19.898987%, 32.348633%, 73.399353%)" offset="0.25"/>
          <stop stopOpacity="1" stopColor="rgb(20.210266%, 32.400513%, 73.486328%)" offset="0.253906"/>
          <stop stopOpacity="1" stopColor="rgb(20.521545%, 32.453918%, 73.574829%)" offset="0.257812"/>
          <stop stopOpacity="1" stopColor="rgb(20.831299%, 32.505798%, 73.661804%)" offset="0.261719"/>
          <stop stopOpacity="1" stopColor="rgb(21.142578%, 32.559204%, 73.748779%)" offset="0.265625"/>
          <stop stopOpacity="1" stopColor="rgb(21.453857%, 32.611084%, 73.835754%)" offset="0.269531"/>
          <stop stopOpacity="1" stopColor="rgb(21.765137%, 32.662964%, 73.924255%)" offset="0.273438"/>
          <stop stopOpacity="1" stopColor="rgb(22.07489%, 32.714844%, 74.01123%)" offset="0.277344"/>
          <stop stopOpacity="1" stopColor="rgb(22.386169%, 32.76825%, 74.099731%)" offset="0.28125"/>
          <stop stopOpacity="1" stopColor="rgb(22.697449%, 32.820129%, 74.186707%)" offset="0.285156"/>
          <stop stopOpacity="1" stopColor="rgb(23.008728%, 32.872009%, 74.273682%)" offset="0.289062"/>
          <stop stopOpacity="1" stopColor="rgb(23.318481%, 32.923889%, 74.360657%)" offset="0.292969"/>
          <stop stopOpacity="1" stopColor="rgb(23.629761%, 32.977295%, 74.449158%)" offset="0.296875"/>
          <stop stopOpacity="1" stopColor="rgb(23.94104%, 33.029175%, 74.536133%)" offset="0.300781"/>
          <stop stopOpacity="1" stopColor="rgb(24.252319%, 33.082581%, 74.624634%)" offset="0.304688"/>
          <stop stopOpacity="1" stopColor="rgb(24.563599%, 33.13446%, 74.711609%)" offset="0.308594"/>
          <stop stopOpacity="1" stopColor="rgb(24.874878%, 33.18634%, 74.798584%)" offset="0.3125"/>
          <stop stopOpacity="1" stopColor="rgb(25.184631%, 33.23822%, 74.885559%)" offset="0.316406"/>
          <stop stopOpacity="1" stopColor="rgb(25.495911%, 33.291626%, 74.97406%)" offset="0.320312"/>
          <stop stopOpacity="1" stopColor="rgb(25.80719%, 33.343506%, 75.061035%)" offset="0.324219"/>
          <stop stopOpacity="1" stopColor="rgb(26.118469%, 33.395386%, 75.149536%)" offset="0.328125"/>
          <stop stopOpacity="1" stopColor="rgb(26.428223%, 33.447266%, 75.236511%)" offset="0.332031"/>
          <stop stopOpacity="1" stopColor="rgb(26.739502%, 33.500671%, 75.323486%)" offset="0.335938"/>
          <stop stopOpacity="1" stopColor="rgb(27.050781%, 33.552551%, 75.410461%)" offset="0.339844"/>
          <stop stopOpacity="1" stopColor="rgb(27.362061%, 33.605957%, 75.498962%)" offset="0.34375"/>
          <stop stopOpacity="1" stopColor="rgb(27.671814%, 33.657837%, 75.585938%)" offset="0.347656"/>
          <stop stopOpacity="1" stopColor="rgb(27.983093%, 33.709717%, 75.674438%)" offset="0.351562"/>
          <stop stopOpacity="1" stopColor="rgb(28.294373%, 33.761597%, 75.761414%)" offset="0.355469"/>
          <stop stopOpacity="1" stopColor="rgb(28.605652%, 33.815002%, 75.849915%)" offset="0.359375"/>
          <stop stopOpacity="1" stopColor="rgb(28.915405%, 33.866882%, 75.93689%)" offset="0.363281"/>
          <stop stopOpacity="1" stopColor="rgb(29.226685%, 33.920288%, 76.023865%)" offset="0.367188"/>
          <stop stopOpacity="1" stopColor="rgb(29.537964%, 33.972168%, 76.11084%)" offset="0.371094"/>
          <stop stopOpacity="1" stopColor="rgb(29.849243%, 34.024048%, 76.199341%)" offset="0.375"/>
          <stop stopOpacity="1" stopColor="rgb(30.160522%, 34.075928%, 76.286316%)" offset="0.378906"/>
          <stop stopOpacity="1" stopColor="rgb(30.471802%, 34.129333%, 76.374817%)" offset="0.382812"/>
          <stop stopOpacity="1" stopColor="rgb(30.781555%, 34.181213%, 76.461792%)" offset="0.386719"/>
          <stop stopOpacity="1" stopColor="rgb(31.092834%, 34.233093%, 76.548767%)" offset="0.390625"/>
          <stop stopOpacity="1" stopColor="rgb(31.404114%, 34.284973%, 76.635742%)" offset="0.394531"/>
          <stop stopOpacity="1" stopColor="rgb(31.715393%, 34.338379%, 76.724243%)" offset="0.398438"/>
          <stop stopOpacity="1" stopColor="rgb(32.025146%, 34.390259%, 76.811218%)" offset="0.402344"/>
          <stop stopOpacity="1" stopColor="rgb(32.336426%, 34.443665%, 76.899719%)" offset="0.40625"/>
          <stop stopOpacity="1" stopColor="rgb(32.647705%, 34.495544%, 76.986694%)" offset="0.410156"/>
          <stop stopOpacity="1" stopColor="rgb(32.958984%, 34.547424%, 77.073669%)" offset="0.414062"/>
          <stop stopOpacity="1" stopColor="rgb(33.268738%, 34.599304%, 77.160645%)" offset="0.417969"/>
          <stop stopOpacity="1" stopColor="rgb(33.580017%, 34.65271%, 77.249146%)" offset="0.421875"/>
          <stop stopOpacity="1" stopColor="rgb(33.891296%, 34.70459%, 77.336121%)" offset="0.425781"/>
          <stop stopOpacity="1" stopColor="rgb(34.202576%, 34.75647%, 77.424622%)" offset="0.429688"/>
          <stop stopOpacity="1" stopColor="rgb(34.512329%, 34.80835%, 77.511597%)" offset="0.433594"/>
          <stop stopOpacity="1" stopColor="rgb(34.823608%, 34.861755%, 77.598572%)" offset="0.4375"/>
          <stop stopOpacity="1" stopColor="rgb(35.134888%, 34.913635%, 77.685547%)" offset="0.441406"/>
          <stop stopOpacity="1" stopColor="rgb(35.446167%, 34.967041%, 77.774048%)" offset="0.445312"/>
          <stop stopOpacity="1" stopColor="rgb(35.757446%, 35.018921%, 77.861023%)" offset="0.449219"/>
          <stop stopOpacity="1" stopColor="rgb(36.068726%, 35.070801%, 77.949524%)" offset="0.453125"/>
          <stop stopOpacity="1" stopColor="rgb(36.378479%, 35.122681%, 78.036499%)" offset="0.457031"/>
          <stop stopOpacity="1" stopColor="rgb(36.689758%, 35.176086%, 78.123474%)" offset="0.460938"/>
          <stop stopOpacity="1" stopColor="rgb(37.001038%, 35.227966%, 78.210449%)" offset="0.464844"/>
          <stop stopOpacity="1" stopColor="rgb(37.312317%, 35.279846%, 78.29895%)" offset="0.46875"/>
          <stop stopOpacity="1" stopColor="rgb(37.62207%, 35.331726%, 78.385925%)" offset="0.472656"/>
          <stop stopOpacity="1" stopColor="rgb(37.93335%, 35.385132%, 78.474426%)" offset="0.476562"/>
          <stop stopOpacity="1" stopColor="rgb(38.244629%, 35.437012%, 78.561401%)" offset="0.480469"/>
          <stop stopOpacity="1" stopColor="rgb(38.555908%, 35.490417%, 78.649902%)" offset="0.484375"/>
          <stop stopOpacity="1" stopColor="rgb(38.865662%, 35.542297%, 78.736877%)" offset="0.488281"/>
          <stop stopOpacity="1" stopColor="rgb(39.176941%, 35.594177%, 78.823853%)" offset="0.492188"/>
          <stop stopOpacity="1" stopColor="rgb(39.48822%, 35.646057%, 78.910828%)" offset="0.496094"/>
          <stop stopOpacity="1" stopColor="rgb(39.7995%, 35.699463%, 78.999329%)" offset="0.5"/>
          <stop stopOpacity="1" stopColor="rgb(40.109253%, 35.751343%, 79.086304%)" offset="0.503906"/>
          <stop stopOpacity="1" stopColor="rgb(40.420532%, 35.803223%, 79.174805%)" offset="0.507813"/>
          <stop stopOpacity="1" stopColor="rgb(40.731812%, 35.855103%, 79.26178%)" offset="0.511719"/>
          <stop stopOpacity="1" stopColor="rgb(41.043091%, 35.908508%, 79.348755%)" offset="0.515625"/>
          <stop stopOpacity="1" stopColor="rgb(41.352844%, 35.960388%, 79.43573%)" offset="0.519531"/>
          <stop stopOpacity="1" stopColor="rgb(41.664124%, 36.013794%, 79.524231%)" offset="0.523438"/>
          <stop stopOpacity="1" stopColor="rgb(41.975403%, 36.065674%, 79.611206%)" offset="0.527344"/>
          <stop stopOpacity="1" stopColor="rgb(42.286682%, 36.117554%, 79.699707%)" offset="0.53125"/>
          <stop stopOpacity="1" stopColor="rgb(42.597961%, 36.169434%, 79.786682%)" offset="0.535156"/>
          <stop stopOpacity="1" stopColor="rgb(42.909241%, 36.222839%, 79.873657%)" offset="0.539062"/>
          <stop stopOpacity="1" stopColor="rgb(43.218994%, 36.274719%, 79.960632%)" offset="0.542969"/>
          <stop stopOpacity="1" stopColor="rgb(43.530273%, 36.326599%, 80.049133%)" offset="0.546875"/>
          <stop stopOpacity="1" stopColor="rgb(43.841553%, 36.378479%, 80.136108%)" offset="0.550781"/>
          <stop stopOpacity="1" stopColor="rgb(44.152832%, 36.431885%, 80.224609%)" offset="0.554688"/>
          <stop stopOpacity="1" stopColor="rgb(44.462585%, 36.483765%, 80.311584%)" offset="0.558594"/>
          <stop stopOpacity="1" stopColor="rgb(44.773865%, 36.53717%, 80.39856%)" offset="0.5625"/>
          <stop stopOpacity="1" stopColor="rgb(45.085144%, 36.58905%, 80.485535%)" offset="0.566406"/>
          <stop stopOpacity="1" stopColor="rgb(45.396423%, 36.64093%, 80.574036%)" offset="0.570312"/>
          <stop stopOpacity="1" stopColor="rgb(45.706177%, 36.69281%, 80.661011%)" offset="0.574219"/>
          <stop stopOpacity="1" stopColor="rgb(46.017456%, 36.746216%, 80.749512%)" offset="0.578125"/>
          <stop stopOpacity="1" stopColor="rgb(46.328735%, 36.798096%, 80.836487%)" offset="0.582031"/>
          <stop stopOpacity="1" stopColor="rgb(46.640015%, 36.851501%, 80.924988%)" offset="0.585938"/>
          <stop stopOpacity="1" stopColor="rgb(46.949768%, 36.903381%, 81.011963%)" offset="0.589844"/>
          <stop stopOpacity="1" stopColor="rgb(47.261047%, 36.955261%, 81.098938%)" offset="0.59375"/>
          <stop stopOpacity="1" stopColor="rgb(47.572327%, 37.007141%, 81.185913%)" offset="0.597656"/>
          <stop stopOpacity="1" stopColor="rgb(47.883606%, 37.060547%, 81.274414%)" offset="0.601563"/>
          <stop stopOpacity="1" stopColor="rgb(48.194885%, 37.112427%, 81.361389%)" offset="0.605469"/>
          <stop stopOpacity="1" stopColor="rgb(48.506165%, 37.164307%, 81.44989%)" offset="0.609375"/>
          <stop stopOpacity="1" stopColor="rgb(48.815918%, 37.216187%, 81.536865%)" offset="0.613281"/>
          <stop stopOpacity="1" stopColor="rgb(49.127197%, 37.269592%, 81.62384%)" offset="0.617188"/>
          <stop stopOpacity="1" stopColor="rgb(49.438477%, 37.321472%, 81.710815%)" offset="0.621094"/>
          <stop stopOpacity="1" stopColor="rgb(49.749756%, 37.374878%, 81.799316%)" offset="0.625"/>
          <stop stopOpacity="1" stopColor="rgb(50.059509%, 37.426758%, 81.886292%)" offset="0.628906"/>
          <stop stopOpacity="1" stopColor="rgb(50.370789%, 37.478638%, 81.974792%)" offset="0.632812"/>
          <stop stopOpacity="1" stopColor="rgb(50.682068%, 37.530518%, 82.061768%)" offset="0.636719"/>
          <stop stopOpacity="1" stopColor="rgb(50.993347%, 37.583923%, 82.148743%)" offset="0.640625"/>
          <stop stopOpacity="1" stopColor="rgb(51.303101%, 37.635803%, 82.235718%)" offset="0.644531"/>
          <stop stopOpacity="1" stopColor="rgb(51.61438%, 37.687683%, 82.324219%)" offset="0.648437"/>
          <stop stopOpacity="1" stopColor="rgb(51.925659%, 37.739563%, 82.411194%)" offset="0.652344"/>
          <stop stopOpacity="1" stopColor="rgb(52.236938%, 37.792969%, 82.499695%)" offset="0.65625"/>
          <stop stopOpacity="1" stopColor="rgb(52.546692%, 37.844849%, 82.58667%)" offset="0.660156"/>
          <stop stopOpacity="1" stopColor="rgb(52.857971%, 37.898254%, 82.673645%)" offset="0.664062"/>
          <stop stopOpacity="1" stopColor="rgb(53.16925%, 37.950134%, 82.76062%)" offset="0.667969"/>
          <stop stopOpacity="1" stopColor="rgb(53.48053%, 38.002014%, 82.849121%)" offset="0.671875"/>
          <stop stopOpacity="1" stopColor="rgb(53.791809%, 38.053894%, 82.936096%)" offset="0.675781"/>
          <stop stopOpacity="1" stopColor="rgb(54.103088%, 38.1073%, 83.024597%)" offset="0.679688"/>
          <stop stopOpacity="1" stopColor="rgb(54.412842%, 38.15918%, 83.111572%)" offset="0.683594"/>
          <stop stopOpacity="1" stopColor="rgb(54.724121%, 38.21106%, 83.198547%)" offset="0.6875"/>
          <stop stopOpacity="1" stopColor="rgb(55.0354%, 38.262939%, 83.285522%)" offset="0.691406"/>
          <stop stopOpacity="1" stopColor="rgb(55.34668%, 38.316345%, 83.374023%)" offset="0.695312"/>
          <stop stopOpacity="1" stopColor="rgb(55.656433%, 38.368225%, 83.460999%)" offset="0.699219"/>
          <stop stopOpacity="1" stopColor="rgb(55.967712%, 38.421631%, 83.5495%)" offset="0.703125"/>
          <stop stopOpacity="1" stopColor="rgb(56.278992%, 38.473511%, 83.636475%)" offset="0.707031"/>
          <stop stopOpacity="1" stopColor="rgb(56.590271%, 38.525391%, 83.724976%)" offset="0.710938"/>
          <stop stopOpacity="1" stopColor="rgb(56.900024%, 38.577271%, 83.811951%)" offset="0.714844"/>
          <stop stopOpacity="1" stopColor="rgb(57.211304%, 38.630676%, 83.898926%)" offset="0.71875"/>
          <stop stopOpacity="1" stopColor="rgb(57.522583%, 38.682556%, 83.985901%)" offset="0.722656"/>
          <stop stopOpacity="1" stopColor="rgb(57.833862%, 38.734436%, 84.074402%)" offset="0.726562"/>
          <stop stopOpacity="1" stopColor="rgb(58.143616%, 38.786316%, 84.161377%)" offset="0.730469"/>
          <stop stopOpacity="1" stopColor="rgb(58.454895%, 38.839722%, 84.249878%)" offset="0.734375"/>
          <stop stopOpacity="1" stopColor="rgb(58.766174%, 38.891602%, 84.336853%)" offset="0.738281"/>
          <stop stopOpacity="1" stopColor="rgb(59.077454%, 38.945007%, 84.423828%)" offset="0.742187"/>
          <stop stopOpacity="1" stopColor="rgb(59.387207%, 38.996887%, 84.510803%)" offset="0.746094"/>
          <stop stopOpacity="1" stopColor="rgb(59.698486%, 39.048767%, 84.599304%)" offset="0.75"/>
          <stop stopOpacity="1" stopColor="rgb(60.009766%, 39.100647%, 84.686279%)" offset="0.753906"/>
          <stop stopOpacity="1" stopColor="rgb(60.321045%, 39.154053%, 84.77478%)" offset="0.757813"/>
          <stop stopOpacity="1" stopColor="rgb(60.632324%, 39.205933%, 84.861755%)" offset="0.761719"/>
          <stop stopOpacity="1" stopColor="rgb(60.943604%, 39.259338%, 84.94873%)" offset="0.765625"/>
          <stop stopOpacity="1" stopColor="rgb(61.253357%, 39.311218%, 85.035706%)" offset="0.769531"/>
          <stop stopOpacity="1" stopColor="rgb(61.564636%, 39.363098%, 85.124207%)" offset="0.773438"/>
          <stop stopOpacity="1" stopColor="rgb(61.875916%, 39.414978%, 85.211182%)" offset="0.777344"/>
          <stop stopOpacity="1" stopColor="rgb(62.187195%, 39.468384%, 85.299683%)" offset="0.78125"/>
          <stop stopOpacity="1" stopColor="rgb(62.496948%, 39.520264%, 85.386658%)" offset="0.785156"/>
          <stop stopOpacity="1" stopColor="rgb(62.808228%, 39.572144%, 85.473633%)" offset="0.789062"/>
          <stop stopOpacity="1" stopColor="rgb(63.119507%, 39.624023%, 85.560608%)" offset="0.792969"/>
          <stop stopOpacity="1" stopColor="rgb(63.430786%, 39.677429%, 85.649109%)" offset="0.796875"/>
          <stop stopOpacity="1" stopColor="rgb(63.74054%, 39.729309%, 85.736084%)" offset="0.800781"/>
          <stop stopOpacity="1" stopColor="rgb(64.051819%, 39.782715%, 85.824585%)" offset="0.804688"/>
          <stop stopOpacity="1" stopColor="rgb(64.363098%, 39.834595%, 85.91156%)" offset="0.808594"/>
          <stop stopOpacity="1" stopColor="rgb(64.674377%, 39.886475%, 85.998535%)" offset="0.8125"/>
          <stop stopOpacity="1" stopColor="rgb(64.984131%, 39.938354%, 86.08551%)" offset="0.816406"/>
          <stop stopOpacity="1" stopColor="rgb(65.29541%, 39.99176%, 86.174011%)" offset="0.820312"/>
          <stop stopOpacity="1" stopColor="rgb(65.606689%, 40.04364%, 86.260986%)" offset="0.824219"/>
          <stop stopOpacity="1" stopColor="rgb(65.917969%, 40.09552%, 86.349487%)" offset="0.828125"/>
          <stop stopOpacity="1" stopColor="rgb(66.229248%, 40.1474%, 86.436462%)" offset="0.832031"/>
          <stop stopOpacity="1" stopColor="rgb(66.540527%, 40.200806%, 86.524963%)" offset="0.835938"/>
          <stop stopOpacity="1" stopColor="rgb(66.850281%, 40.252686%, 86.611938%)" offset="0.839844"/>
          <stop stopOpacity="1" stopColor="rgb(67.16156%, 40.306091%, 86.698914%)" offset="0.84375"/>
          <stop stopOpacity="1" stopColor="rgb(67.472839%, 40.357971%, 86.785889%)" offset="0.847656"/>
          <stop stopOpacity="1" stopColor="rgb(67.784119%, 40.409851%, 86.87439%)" offset="0.851563"/>
          <stop stopOpacity="1" stopColor="rgb(68.093872%, 40.461731%, 86.961365%)" offset="0.855469"/>
          <stop stopOpacity="1" stopColor="rgb(68.405151%, 40.515137%, 87.049866%)" offset="0.859375"/>
          <stop stopOpacity="1" stopColor="rgb(68.716431%, 40.567017%, 87.136841%)" offset="0.863281"/>
          <stop stopOpacity="1" stopColor="rgb(69.02771%, 40.618896%, 87.223816%)" offset="0.867188"/>
          <stop stopOpacity="1" stopColor="rgb(69.337463%, 40.670776%, 87.310791%)" offset="0.871094"/>
          <stop stopOpacity="1" stopColor="rgb(69.648743%, 40.724182%, 87.399292%)" offset="0.875"/>
          <stop stopOpacity="1" stopColor="rgb(69.960022%, 40.776062%, 87.486267%)" offset="0.878906"/>
          <stop stopOpacity="1" stopColor="rgb(70.271301%, 40.829468%, 87.574768%)" offset="0.882812"/>
          <stop stopOpacity="1" stopColor="rgb(70.581055%, 40.881348%, 87.661743%)" offset="0.886719"/>
          <stop stopOpacity="1" stopColor="rgb(70.892334%, 40.933228%, 87.748718%)" offset="0.890625"/>
          <stop stopOpacity="1" stopColor="rgb(71.203613%, 40.985107%, 87.835693%)" offset="0.894531"/>
          <stop stopOpacity="1" stopColor="rgb(71.514893%, 41.038513%, 87.924194%)" offset="0.898437"/>
          <stop stopOpacity="1" stopColor="rgb(71.826172%, 41.090393%, 88.011169%)" offset="0.902344"/>
          <stop stopOpacity="1" stopColor="rgb(72.137451%, 41.142273%, 88.09967%)" offset="0.90625"/>
          <stop stopOpacity="1" stopColor="rgb(72.447205%, 41.194153%, 88.186646%)" offset="0.910156"/>
          <stop stopOpacity="1" stopColor="rgb(72.758484%, 41.247559%, 88.273621%)" offset="0.914062"/>
          <stop stopOpacity="1" stopColor="rgb(73.069763%, 41.299438%, 88.360596%)" offset="0.917969"/>
          <stop stopOpacity="1" stopColor="rgb(73.381042%, 41.352844%, 88.449097%)" offset="0.921875"/>
          <stop stopOpacity="1" stopColor="rgb(73.690796%, 41.404724%, 88.536072%)" offset="0.925781"/>
          <stop stopOpacity="1" stopColor="rgb(74.002075%, 41.456604%, 88.624573%)" offset="0.929688"/>
          <stop stopOpacity="1" stopColor="rgb(74.313354%, 41.508484%, 88.711548%)" offset="0.933594"/>
          <stop stopOpacity="1" stopColor="rgb(74.624634%, 41.56189%, 88.798523%)" offset="0.9375"/>
          <stop stopOpacity="1" stopColor="rgb(74.934387%, 41.61377%, 88.885498%)" offset="0.941406"/>
          <stop stopOpacity="1" stopColor="rgb(75.245667%, 41.667175%, 88.973999%)" offset="0.945312"/>
          <stop stopOpacity="1" stopColor="rgb(75.556946%, 41.719055%, 89.060974%)" offset="0.949219"/>
          <stop stopOpacity="1" stopColor="rgb(75.868225%, 41.770935%, 89.149475%)" offset="0.953125"/>
          <stop stopOpacity="1" stopColor="rgb(76.177979%, 41.822815%, 89.23645%)" offset="0.957031"/>
          <stop stopOpacity="1" stopColor="rgb(76.489258%, 41.876221%, 89.324951%)" offset="0.960938"/>
          <stop stopOpacity="1" stopColor="rgb(76.800537%, 41.928101%, 89.411926%)" offset="0.964844"/>
          <stop stopOpacity="1" stopColor="rgb(77.111816%, 41.97998%, 89.498901%)" offset="0.96875"/>
          <stop stopOpacity="1" stopColor="rgb(77.42157%, 42.03186%, 89.585876%)" offset="0.972656"/>
          <stop stopOpacity="1" stopColor="rgb(77.732849%, 42.085266%, 89.674377%)" offset="0.976562"/>
          <stop stopOpacity="1" stopColor="rgb(78.044128%, 42.137146%, 89.761353%)" offset="0.980469"/>
          <stop stopOpacity="1" stopColor="rgb(78.355408%, 42.190552%, 89.849854%)" offset="0.984375"/>
          <stop stopOpacity="1" stopColor="rgb(78.666687%, 42.242432%, 89.936829%)" offset="0.988281"/>
          <stop stopOpacity="1" stopColor="rgb(78.977966%, 42.294312%, 90.023804%)" offset="0.992187"/>
          <stop stopOpacity="1" stopColor="rgb(79.28772%, 42.346191%, 90.110779%)" offset="0.996094"/>
          <stop stopOpacity="1" stopColor="rgb(79.598999%, 42.399597%, 90.19928%)" offset="1"/>
        </linearGradient>
        <clipPath id="55836b9904">
          <path
            d="M 182.546875 145.34375 L 275.855469 145.34375 L 275.855469 238.65625 L 182.546875 238.65625 Z M 182.546875 145.34375 "
            clipRule="nonzero"/>
        </clipPath>
        <clipPath id="ba509a4e8c">
          <path
            d="M 229.199219 145.34375 C 203.433594 145.34375 182.546875 166.234375 182.546875 192 C 182.546875 217.765625 203.433594 238.65625 229.199219 238.65625 C 254.964844 238.65625 275.855469 217.765625 275.855469 192 C 275.855469 166.234375 254.964844 145.34375 229.199219 145.34375 Z M 229.199219 145.34375 "
            clipRule="nonzero"/>
        </clipPath>
        <linearGradient x1="-0.0000318422" gradientTransform="matrix(0.364487, 0, 0, 0.364487, 182.54596, 145.345645)"
                        y1="128" x2="255.999965" gradientUnits="userSpaceOnUse" y2="128" id="a7df0cc7ef">
          <stop stopOpacity="1" stopColor="rgb(0%, 28.999329%, 67.799377%)" offset="0"/>
          <stop stopOpacity="1" stopColor="rgb(0.309753%, 29.051208%, 67.886353%)" offset="0.00390625"/>
          <stop stopOpacity="1" stopColor="rgb(0.621033%, 29.104614%, 67.974854%)" offset="0.0078125"/>
          <stop stopOpacity="1" stopColor="rgb(0.932312%, 29.156494%, 68.061829%)" offset="0.0117188"/>
          <stop stopOpacity="1" stopColor="rgb(1.243591%, 29.208374%, 68.148804%)" offset="0.015625"/>
          <stop stopOpacity="1" stopColor="rgb(1.553345%, 29.260254%, 68.235779%)" offset="0.0195312"/>
          <stop stopOpacity="1" stopColor="rgb(1.864624%, 29.31366%, 68.32428%)" offset="0.0234375"/>
          <stop stopOpacity="1" stopColor="rgb(2.175903%, 29.36554%, 68.411255%)" offset="0.0273438"/>
          <stop stopOpacity="1" stopColor="rgb(2.487183%, 29.417419%, 68.499756%)" offset="0.03125"/>
          <stop stopOpacity="1" stopColor="rgb(2.796936%, 29.469299%, 68.586731%)" offset="0.0351562"/>
          <stop stopOpacity="1" stopColor="rgb(3.108215%, 29.522705%, 68.673706%)" offset="0.0390625"/>
          <stop stopOpacity="1" stopColor="rgb(3.419495%, 29.574585%, 68.760681%)" offset="0.0429688"/>
          <stop stopOpacity="1" stopColor="rgb(3.730774%, 29.627991%, 68.849182%)" offset="0.046875"/>
          <stop stopOpacity="1" stopColor="rgb(4.040527%, 29.679871%, 68.936157%)" offset="0.0507812"/>
          <stop stopOpacity="1" stopColor="rgb(4.351807%, 29.73175%, 69.024658%)" offset="0.0546875"/>
          <stop stopOpacity="1" stopColor="rgb(4.663086%, 29.78363%, 69.111633%)" offset="0.0585938"/>
          <stop stopOpacity="1" stopColor="rgb(4.974365%, 29.837036%, 69.198608%)" offset="0.0625"/>
          <stop stopOpacity="1" stopColor="rgb(5.284119%, 29.888916%, 69.285583%)" offset="0.0664062"/>
          <stop stopOpacity="1" stopColor="rgb(5.595398%, 29.940796%, 69.374084%)" offset="0.0703125"/>
          <stop stopOpacity="1" stopColor="rgb(5.906677%, 29.992676%, 69.46106%)" offset="0.0742188"/>
          <stop stopOpacity="1" stopColor="rgb(6.217957%, 30.046082%, 69.549561%)" offset="0.078125"/>
          <stop stopOpacity="1" stopColor="rgb(6.529236%, 30.097961%, 69.636536%)" offset="0.0820312"/>
          <stop stopOpacity="1" stopColor="rgb(6.840515%, 30.151367%, 69.723511%)" offset="0.0859375"/>
          <stop stopOpacity="1" stopColor="rgb(7.150269%, 30.203247%, 69.810486%)" offset="0.0898438"/>
          <stop stopOpacity="1" stopColor="rgb(7.461548%, 30.255127%, 69.898987%)" offset="0.09375"/>
          <stop stopOpacity="1" stopColor="rgb(7.772827%, 30.307007%, 69.985962%)" offset="0.0976562"/>
          <stop stopOpacity="1" stopColor="rgb(8.084106%, 30.360413%, 70.074463%)" offset="0.101562"/>
          <stop stopOpacity="1" stopColor="rgb(8.39386%, 30.412292%, 70.161438%)" offset="0.105469"/>
          <stop stopOpacity="1" stopColor="rgb(8.705139%, 30.464172%, 70.249939%)" offset="0.109375"/>
          <stop stopOpacity="1" stopColor="rgb(9.016418%, 30.516052%, 70.336914%)" offset="0.113281"/>
          <stop stopOpacity="1" stopColor="rgb(9.327698%, 30.569458%, 70.423889%)" offset="0.117188"/>
          <stop stopOpacity="1" stopColor="rgb(9.637451%, 30.621338%, 70.510864%)" offset="0.121094"/>
          <stop stopOpacity="1" stopColor="rgb(9.94873%, 30.674744%, 70.599365%)" offset="0.125"/>
          <stop stopOpacity="1" stopColor="rgb(10.26001%, 30.726624%, 70.68634%)" offset="0.128906"/>
          <stop stopOpacity="1" stopColor="rgb(10.571289%, 30.778503%, 70.774841%)" offset="0.132812"/>
          <stop stopOpacity="1" stopColor="rgb(10.881042%, 30.830383%, 70.861816%)" offset="0.136719"/>
          <stop stopOpacity="1" stopColor="rgb(11.192322%, 30.883789%, 70.948792%)" offset="0.140625"/>
          <stop stopOpacity="1" stopColor="rgb(11.503601%, 30.935669%, 71.035767%)" offset="0.144531"/>
          <stop stopOpacity="1" stopColor="rgb(11.81488%, 30.987549%, 71.124268%)" offset="0.148438"/>
          <stop stopOpacity="1" stopColor="rgb(12.12616%, 31.039429%, 71.211243%)" offset="0.152344"/>
          <stop stopOpacity="1" stopColor="rgb(12.437439%, 31.092834%, 71.299744%)" offset="0.15625"/>
          <stop stopOpacity="1" stopColor="rgb(12.747192%, 31.144714%, 71.386719%)" offset="0.160156"/>
          <stop stopOpacity="1" stopColor="rgb(13.058472%, 31.19812%, 71.473694%)" offset="0.164062"/>
          <stop stopOpacity="1" stopColor="rgb(13.369751%, 31.25%, 71.560669%)" offset="0.167969"/>
          <stop stopOpacity="1" stopColor="rgb(13.68103%, 31.30188%, 71.64917%)" offset="0.171875"/>
          <stop stopOpacity="1" stopColor="rgb(13.990784%, 31.35376%, 71.736145%)" offset="0.175781"/>
          <stop stopOpacity="1" stopColor="rgb(14.302063%, 31.407166%, 71.824646%)" offset="0.179688"/>
          <stop stopOpacity="1" stopColor="rgb(14.613342%, 31.459045%, 71.911621%)" offset="0.183594"/>
          <stop stopOpacity="1" stopColor="rgb(14.924622%, 31.512451%, 71.998596%)" offset="0.1875"/>
          <stop stopOpacity="1" stopColor="rgb(15.234375%, 31.564331%, 72.085571%)" offset="0.191406"/>
          <stop stopOpacity="1" stopColor="rgb(15.545654%, 31.616211%, 72.174072%)" offset="0.195312"/>
          <stop stopOpacity="1" stopColor="rgb(15.856934%, 31.668091%, 72.261047%)" offset="0.199219"/>
          <stop stopOpacity="1" stopColor="rgb(16.168213%, 31.721497%, 72.349548%)" offset="0.203125"/>
          <stop stopOpacity="1" stopColor="rgb(16.477966%, 31.773376%, 72.436523%)" offset="0.207031"/>
          <stop stopOpacity="1" stopColor="rgb(16.789246%, 31.825256%, 72.523499%)" offset="0.210938"/>
          <stop stopOpacity="1" stopColor="rgb(17.100525%, 31.877136%, 72.610474%)" offset="0.214844"/>
          <stop stopOpacity="1" stopColor="rgb(17.411804%, 31.930542%, 72.698975%)" offset="0.21875"/>
          <stop stopOpacity="1" stopColor="rgb(17.723083%, 31.982422%, 72.78595%)" offset="0.222656"/>
          <stop stopOpacity="1" stopColor="rgb(18.034363%, 32.035828%, 72.874451%)" offset="0.226562"/>
          <stop stopOpacity="1" stopColor="rgb(18.344116%, 32.087708%, 72.961426%)" offset="0.230469"/>
          <stop stopOpacity="1" stopColor="rgb(18.655396%, 32.139587%, 73.049927%)" offset="0.234375"/>
          <stop stopOpacity="1" stopColor="rgb(18.966675%, 32.191467%, 73.136902%)" offset="0.238281"/>
          <stop stopOpacity="1" stopColor="rgb(19.277954%, 32.244873%, 73.223877%)" offset="0.242188"/>
          <stop stopOpacity="1" stopColor="rgb(19.587708%, 32.296753%, 73.310852%)" offset="0.246094"/>
          <stop stopOpacity="1" stopColor="rgb(19.898987%, 32.348633%, 73.399353%)" offset="0.25"/>
          <stop stopOpacity="1" stopColor="rgb(20.210266%, 32.400513%, 73.486328%)" offset="0.253906"/>
          <stop stopOpacity="1" stopColor="rgb(20.521545%, 32.453918%, 73.574829%)" offset="0.257812"/>
          <stop stopOpacity="1" stopColor="rgb(20.831299%, 32.505798%, 73.661804%)" offset="0.261719"/>
          <stop stopOpacity="1" stopColor="rgb(21.142578%, 32.559204%, 73.748779%)" offset="0.265625"/>
          <stop stopOpacity="1" stopColor="rgb(21.453857%, 32.611084%, 73.835754%)" offset="0.269531"/>
          <stop stopOpacity="1" stopColor="rgb(21.765137%, 32.662964%, 73.924255%)" offset="0.273438"/>
          <stop stopOpacity="1" stopColor="rgb(22.07489%, 32.714844%, 74.01123%)" offset="0.277344"/>
          <stop stopOpacity="1" stopColor="rgb(22.386169%, 32.76825%, 74.099731%)" offset="0.28125"/>
          <stop stopOpacity="1" stopColor="rgb(22.697449%, 32.820129%, 74.186707%)" offset="0.285156"/>
          <stop stopOpacity="1" stopColor="rgb(23.008728%, 32.872009%, 74.273682%)" offset="0.289062"/>
          <stop stopOpacity="1" stopColor="rgb(23.318481%, 32.923889%, 74.360657%)" offset="0.292969"/>
          <stop stopOpacity="1" stopColor="rgb(23.629761%, 32.977295%, 74.449158%)" offset="0.296875"/>
          <stop stopOpacity="1" stopColor="rgb(23.94104%, 33.029175%, 74.536133%)" offset="0.300781"/>
          <stop stopOpacity="1" stopColor="rgb(24.252319%, 33.082581%, 74.624634%)" offset="0.304688"/>
          <stop stopOpacity="1" stopColor="rgb(24.563599%, 33.13446%, 74.711609%)" offset="0.308594"/>
          <stop stopOpacity="1" stopColor="rgb(24.874878%, 33.18634%, 74.798584%)" offset="0.3125"/>
          <stop stopOpacity="1" stopColor="rgb(25.184631%, 33.23822%, 74.885559%)" offset="0.316406"/>
          <stop stopOpacity="1" stopColor="rgb(25.495911%, 33.291626%, 74.97406%)" offset="0.320312"/>
          <stop stopOpacity="1" stopColor="rgb(25.80719%, 33.343506%, 75.061035%)" offset="0.324219"/>
          <stop stopOpacity="1" stopColor="rgb(26.118469%, 33.395386%, 75.149536%)" offset="0.328125"/>
          <stop stopOpacity="1" stopColor="rgb(26.428223%, 33.447266%, 75.236511%)" offset="0.332031"/>
          <stop stopOpacity="1" stopColor="rgb(26.739502%, 33.500671%, 75.323486%)" offset="0.335938"/>
          <stop stopOpacity="1" stopColor="rgb(27.050781%, 33.552551%, 75.410461%)" offset="0.339844"/>
          <stop stopOpacity="1" stopColor="rgb(27.362061%, 33.605957%, 75.498962%)" offset="0.34375"/>
          <stop stopOpacity="1" stopColor="rgb(27.671814%, 33.657837%, 75.585938%)" offset="0.347656"/>
          <stop stopOpacity="1" stopColor="rgb(27.983093%, 33.709717%, 75.674438%)" offset="0.351562"/>
          <stop stopOpacity="1" stopColor="rgb(28.294373%, 33.761597%, 75.761414%)" offset="0.355469"/>
          <stop stopOpacity="1" stopColor="rgb(28.605652%, 33.815002%, 75.849915%)" offset="0.359375"/>
          <stop stopOpacity="1" stopColor="rgb(28.915405%, 33.866882%, 75.93689%)" offset="0.363281"/>
          <stop stopOpacity="1" stopColor="rgb(29.226685%, 33.920288%, 76.023865%)" offset="0.367188"/>
          <stop stopOpacity="1" stopColor="rgb(29.537964%, 33.972168%, 76.11084%)" offset="0.371094"/>
          <stop stopOpacity="1" stopColor="rgb(29.849243%, 34.024048%, 76.199341%)" offset="0.375"/>
          <stop stopOpacity="1" stopColor="rgb(30.160522%, 34.075928%, 76.286316%)" offset="0.378906"/>
          <stop stopOpacity="1" stopColor="rgb(30.471802%, 34.129333%, 76.374817%)" offset="0.382812"/>
          <stop stopOpacity="1" stopColor="rgb(30.781555%, 34.181213%, 76.461792%)" offset="0.386719"/>
          <stop stopOpacity="1" stopColor="rgb(31.092834%, 34.233093%, 76.548767%)" offset="0.390625"/>
          <stop stopOpacity="1" stopColor="rgb(31.404114%, 34.284973%, 76.635742%)" offset="0.394531"/>
          <stop stopOpacity="1" stopColor="rgb(31.715393%, 34.338379%, 76.724243%)" offset="0.398438"/>
          <stop stopOpacity="1" stopColor="rgb(32.025146%, 34.390259%, 76.811218%)" offset="0.402344"/>
          <stop stopOpacity="1" stopColor="rgb(32.336426%, 34.443665%, 76.899719%)" offset="0.40625"/>
          <stop stopOpacity="1" stopColor="rgb(32.647705%, 34.495544%, 76.986694%)" offset="0.410156"/>
          <stop stopOpacity="1" stopColor="rgb(32.958984%, 34.547424%, 77.073669%)" offset="0.414062"/>
          <stop stopOpacity="1" stopColor="rgb(33.268738%, 34.599304%, 77.160645%)" offset="0.417969"/>
          <stop stopOpacity="1" stopColor="rgb(33.580017%, 34.65271%, 77.249146%)" offset="0.421875"/>
          <stop stopOpacity="1" stopColor="rgb(33.891296%, 34.70459%, 77.336121%)" offset="0.425781"/>
          <stop stopOpacity="1" stopColor="rgb(34.202576%, 34.75647%, 77.424622%)" offset="0.429688"/>
          <stop stopOpacity="1" stopColor="rgb(34.512329%, 34.80835%, 77.511597%)" offset="0.433594"/>
          <stop stopOpacity="1" stopColor="rgb(34.823608%, 34.861755%, 77.598572%)" offset="0.4375"/>
          <stop stopOpacity="1" stopColor="rgb(35.134888%, 34.913635%, 77.685547%)" offset="0.441406"/>
          <stop stopOpacity="1" stopColor="rgb(35.446167%, 34.967041%, 77.774048%)" offset="0.445312"/>
          <stop stopOpacity="1" stopColor="rgb(35.757446%, 35.018921%, 77.861023%)" offset="0.449219"/>
          <stop stopOpacity="1" stopColor="rgb(36.068726%, 35.070801%, 77.949524%)" offset="0.453125"/>
          <stop stopOpacity="1" stopColor="rgb(36.378479%, 35.122681%, 78.036499%)" offset="0.457031"/>
          <stop stopOpacity="1" stopColor="rgb(36.689758%, 35.176086%, 78.123474%)" offset="0.460938"/>
          <stop stopOpacity="1" stopColor="rgb(37.001038%, 35.227966%, 78.210449%)" offset="0.464844"/>
          <stop stopOpacity="1" stopColor="rgb(37.312317%, 35.279846%, 78.29895%)" offset="0.46875"/>
          <stop stopOpacity="1" stopColor="rgb(37.62207%, 35.331726%, 78.385925%)" offset="0.472656"/>
          <stop stopOpacity="1" stopColor="rgb(37.93335%, 35.385132%, 78.474426%)" offset="0.476562"/>
          <stop stopOpacity="1" stopColor="rgb(38.244629%, 35.437012%, 78.561401%)" offset="0.480469"/>
          <stop stopOpacity="1" stopColor="rgb(38.555908%, 35.490417%, 78.649902%)" offset="0.484375"/>
          <stop stopOpacity="1" stopColor="rgb(38.865662%, 35.542297%, 78.736877%)" offset="0.488281"/>
          <stop stopOpacity="1" stopColor="rgb(39.176941%, 35.594177%, 78.823853%)" offset="0.492188"/>
          <stop stopOpacity="1" stopColor="rgb(39.48822%, 35.646057%, 78.910828%)" offset="0.496094"/>
          <stop stopOpacity="1" stopColor="rgb(39.7995%, 35.699463%, 78.999329%)" offset="0.5"/>
          <stop stopOpacity="1" stopColor="rgb(40.109253%, 35.751343%, 79.086304%)" offset="0.503906"/>
          <stop stopOpacity="1" stopColor="rgb(40.420532%, 35.803223%, 79.174805%)" offset="0.507812"/>
          <stop stopOpacity="1" stopColor="rgb(40.731812%, 35.855103%, 79.26178%)" offset="0.511719"/>
          <stop stopOpacity="1" stopColor="rgb(41.043091%, 35.908508%, 79.348755%)" offset="0.515625"/>
          <stop stopOpacity="1" stopColor="rgb(41.352844%, 35.960388%, 79.43573%)" offset="0.519531"/>
          <stop stopOpacity="1" stopColor="rgb(41.664124%, 36.013794%, 79.524231%)" offset="0.523437"/>
          <stop stopOpacity="1" stopColor="rgb(41.975403%, 36.065674%, 79.611206%)" offset="0.527344"/>
          <stop stopOpacity="1" stopColor="rgb(42.286682%, 36.117554%, 79.699707%)" offset="0.53125"/>
          <stop stopOpacity="1" stopColor="rgb(42.597961%, 36.169434%, 79.786682%)" offset="0.535156"/>
          <stop stopOpacity="1" stopColor="rgb(42.909241%, 36.222839%, 79.873657%)" offset="0.539062"/>
          <stop stopOpacity="1" stopColor="rgb(43.218994%, 36.274719%, 79.960632%)" offset="0.542969"/>
          <stop stopOpacity="1" stopColor="rgb(43.530273%, 36.326599%, 80.049133%)" offset="0.546875"/>
          <stop stopOpacity="1" stopColor="rgb(43.841553%, 36.378479%, 80.136108%)" offset="0.550781"/>
          <stop stopOpacity="1" stopColor="rgb(44.152832%, 36.431885%, 80.224609%)" offset="0.554688"/>
          <stop stopOpacity="1" stopColor="rgb(44.462585%, 36.483765%, 80.311584%)" offset="0.558594"/>
          <stop stopOpacity="1" stopColor="rgb(44.773865%, 36.53717%, 80.39856%)" offset="0.5625"/>
          <stop stopOpacity="1" stopColor="rgb(45.085144%, 36.58905%, 80.485535%)" offset="0.566406"/>
          <stop stopOpacity="1" stopColor="rgb(45.396423%, 36.64093%, 80.574036%)" offset="0.570312"/>
          <stop stopOpacity="1" stopColor="rgb(45.706177%, 36.69281%, 80.661011%)" offset="0.574219"/>
          <stop stopOpacity="1" stopColor="rgb(46.017456%, 36.746216%, 80.749512%)" offset="0.578125"/>
          <stop stopOpacity="1" stopColor="rgb(46.328735%, 36.798096%, 80.836487%)" offset="0.582031"/>
          <stop stopOpacity="1" stopColor="rgb(46.640015%, 36.851501%, 80.924988%)" offset="0.585938"/>
          <stop stopOpacity="1" stopColor="rgb(46.949768%, 36.903381%, 81.011963%)" offset="0.589844"/>
          <stop stopOpacity="1" stopColor="rgb(47.261047%, 36.955261%, 81.098938%)" offset="0.59375"/>
          <stop stopOpacity="1" stopColor="rgb(47.572327%, 37.007141%, 81.185913%)" offset="0.597656"/>
          <stop stopOpacity="1" stopColor="rgb(47.883606%, 37.060547%, 81.274414%)" offset="0.601562"/>
          <stop stopOpacity="1" stopColor="rgb(48.194885%, 37.112427%, 81.361389%)" offset="0.605469"/>
          <stop stopOpacity="1" stopColor="rgb(48.506165%, 37.164307%, 81.44989%)" offset="0.609375"/>
          <stop stopOpacity="1" stopColor="rgb(48.815918%, 37.216187%, 81.536865%)" offset="0.613281"/>
          <stop stopOpacity="1" stopColor="rgb(49.127197%, 37.269592%, 81.62384%)" offset="0.617187"/>
          <stop stopOpacity="1" stopColor="rgb(49.438477%, 37.321472%, 81.710815%)" offset="0.621094"/>
          <stop stopOpacity="1" stopColor="rgb(49.749756%, 37.374878%, 81.799316%)" offset="0.625"/>
          <stop stopOpacity="1" stopColor="rgb(50.059509%, 37.426758%, 81.886292%)" offset="0.628906"/>
          <stop stopOpacity="1" stopColor="rgb(50.370789%, 37.478638%, 81.974792%)" offset="0.632813"/>
          <stop stopOpacity="1" stopColor="rgb(50.682068%, 37.530518%, 82.061768%)" offset="0.636719"/>
          <stop stopOpacity="1" stopColor="rgb(50.993347%, 37.583923%, 82.148743%)" offset="0.640625"/>
          <stop stopOpacity="1" stopColor="rgb(51.303101%, 37.635803%, 82.235718%)" offset="0.644531"/>
          <stop stopOpacity="1" stopColor="rgb(51.61438%, 37.687683%, 82.324219%)" offset="0.648438"/>
          <stop stopOpacity="1" stopColor="rgb(51.925659%, 37.739563%, 82.411194%)" offset="0.652344"/>
          <stop stopOpacity="1" stopColor="rgb(52.236938%, 37.792969%, 82.499695%)" offset="0.65625"/>
          <stop stopOpacity="1" stopColor="rgb(52.546692%, 37.844849%, 82.58667%)" offset="0.660156"/>
          <stop stopOpacity="1" stopColor="rgb(52.857971%, 37.898254%, 82.673645%)" offset="0.664062"/>
          <stop stopOpacity="1" stopColor="rgb(53.16925%, 37.950134%, 82.76062%)" offset="0.667969"/>
          <stop stopOpacity="1" stopColor="rgb(53.48053%, 38.002014%, 82.849121%)" offset="0.671875"/>
          <stop stopOpacity="1" stopColor="rgb(53.791809%, 38.053894%, 82.936096%)" offset="0.675781"/>
          <stop stopOpacity="1" stopColor="rgb(54.103088%, 38.1073%, 83.024597%)" offset="0.679688"/>
          <stop stopOpacity="1" stopColor="rgb(54.412842%, 38.15918%, 83.111572%)" offset="0.683594"/>
          <stop stopOpacity="1" stopColor="rgb(54.724121%, 38.21106%, 83.198547%)" offset="0.6875"/>
          <stop stopOpacity="1" stopColor="rgb(55.0354%, 38.262939%, 83.285522%)" offset="0.691406"/>
          <stop stopOpacity="1" stopColor="rgb(55.34668%, 38.316345%, 83.374023%)" offset="0.695312"/>
          <stop stopOpacity="1" stopColor="rgb(55.656433%, 38.368225%, 83.460999%)" offset="0.699219"/>
          <stop stopOpacity="1" stopColor="rgb(55.967712%, 38.421631%, 83.5495%)" offset="0.703125"/>
          <stop stopOpacity="1" stopColor="rgb(56.278992%, 38.473511%, 83.636475%)" offset="0.707031"/>
          <stop stopOpacity="1" stopColor="rgb(56.590271%, 38.525391%, 83.724976%)" offset="0.710938"/>
          <stop stopOpacity="1" stopColor="rgb(56.900024%, 38.577271%, 83.811951%)" offset="0.714844"/>
          <stop stopOpacity="1" stopColor="rgb(57.211304%, 38.630676%, 83.898926%)" offset="0.71875"/>
          <stop stopOpacity="1" stopColor="rgb(57.522583%, 38.682556%, 83.985901%)" offset="0.722656"/>
          <stop stopOpacity="1" stopColor="rgb(57.833862%, 38.734436%, 84.074402%)" offset="0.726563"/>
          <stop stopOpacity="1" stopColor="rgb(58.143616%, 38.786316%, 84.161377%)" offset="0.730469"/>
          <stop stopOpacity="1" stopColor="rgb(58.454895%, 38.839722%, 84.249878%)" offset="0.734375"/>
          <stop stopOpacity="1" stopColor="rgb(58.766174%, 38.891602%, 84.336853%)" offset="0.738281"/>
          <stop stopOpacity="1" stopColor="rgb(59.077454%, 38.945007%, 84.423828%)" offset="0.742188"/>
          <stop stopOpacity="1" stopColor="rgb(59.387207%, 38.996887%, 84.510803%)" offset="0.746094"/>
          <stop stopOpacity="1" stopColor="rgb(59.698486%, 39.048767%, 84.599304%)" offset="0.75"/>
          <stop stopOpacity="1" stopColor="rgb(60.009766%, 39.100647%, 84.686279%)" offset="0.753906"/>
          <stop stopOpacity="1" stopColor="rgb(60.321045%, 39.154053%, 84.77478%)" offset="0.757812"/>
          <stop stopOpacity="1" stopColor="rgb(60.632324%, 39.205933%, 84.861755%)" offset="0.761719"/>
          <stop stopOpacity="1" stopColor="rgb(60.943604%, 39.259338%, 84.94873%)" offset="0.765625"/>
          <stop stopOpacity="1" stopColor="rgb(61.253357%, 39.311218%, 85.035706%)" offset="0.769531"/>
          <stop stopOpacity="1" stopColor="rgb(61.564636%, 39.363098%, 85.124207%)" offset="0.773437"/>
          <stop stopOpacity="1" stopColor="rgb(61.875916%, 39.414978%, 85.211182%)" offset="0.777344"/>
          <stop stopOpacity="1" stopColor="rgb(62.187195%, 39.468384%, 85.299683%)" offset="0.78125"/>
          <stop stopOpacity="1" stopColor="rgb(62.496948%, 39.520264%, 85.386658%)" offset="0.785156"/>
          <stop stopOpacity="1" stopColor="rgb(62.808228%, 39.572144%, 85.473633%)" offset="0.789062"/>
          <stop stopOpacity="1" stopColor="rgb(63.119507%, 39.624023%, 85.560608%)" offset="0.792969"/>
          <stop stopOpacity="1" stopColor="rgb(63.430786%, 39.677429%, 85.649109%)" offset="0.796875"/>
          <stop stopOpacity="1" stopColor="rgb(63.74054%, 39.729309%, 85.736084%)" offset="0.800781"/>
          <stop stopOpacity="1" stopColor="rgb(64.051819%, 39.782715%, 85.824585%)" offset="0.804688"/>
          <stop stopOpacity="1" stopColor="rgb(64.363098%, 39.834595%, 85.91156%)" offset="0.808594"/>
          <stop stopOpacity="1" stopColor="rgb(64.674377%, 39.886475%, 85.998535%)" offset="0.8125"/>
          <stop stopOpacity="1" stopColor="rgb(64.984131%, 39.938354%, 86.08551%)" offset="0.816406"/>
          <stop stopOpacity="1" stopColor="rgb(65.29541%, 39.99176%, 86.174011%)" offset="0.820312"/>
          <stop stopOpacity="1" stopColor="rgb(65.606689%, 40.04364%, 86.260986%)" offset="0.824219"/>
          <stop stopOpacity="1" stopColor="rgb(65.917969%, 40.09552%, 86.349487%)" offset="0.828125"/>
          <stop stopOpacity="1" stopColor="rgb(66.229248%, 40.1474%, 86.436462%)" offset="0.832031"/>
          <stop stopOpacity="1" stopColor="rgb(66.540527%, 40.200806%, 86.524963%)" offset="0.835938"/>
          <stop stopOpacity="1" stopColor="rgb(66.850281%, 40.252686%, 86.611938%)" offset="0.839844"/>
          <stop stopOpacity="1" stopColor="rgb(67.16156%, 40.306091%, 86.698914%)" offset="0.84375"/>
          <stop stopOpacity="1" stopColor="rgb(67.472839%, 40.357971%, 86.785889%)" offset="0.847656"/>
          <stop stopOpacity="1" stopColor="rgb(67.784119%, 40.409851%, 86.87439%)" offset="0.851562"/>
          <stop stopOpacity="1" stopColor="rgb(68.093872%, 40.461731%, 86.961365%)" offset="0.855469"/>
          <stop stopOpacity="1" stopColor="rgb(68.405151%, 40.515137%, 87.049866%)" offset="0.859375"/>
          <stop stopOpacity="1" stopColor="rgb(68.716431%, 40.567017%, 87.136841%)" offset="0.863281"/>
          <stop stopOpacity="1" stopColor="rgb(69.02771%, 40.618896%, 87.223816%)" offset="0.867187"/>
          <stop stopOpacity="1" stopColor="rgb(69.337463%, 40.670776%, 87.310791%)" offset="0.871094"/>
          <stop stopOpacity="1" stopColor="rgb(69.648743%, 40.724182%, 87.399292%)" offset="0.875"/>
          <stop stopOpacity="1" stopColor="rgb(69.960022%, 40.776062%, 87.486267%)" offset="0.878906"/>
          <stop stopOpacity="1" stopColor="rgb(70.271301%, 40.829468%, 87.574768%)" offset="0.882813"/>
          <stop stopOpacity="1" stopColor="rgb(70.581055%, 40.881348%, 87.661743%)" offset="0.886719"/>
          <stop stopOpacity="1" stopColor="rgb(70.892334%, 40.933228%, 87.748718%)" offset="0.890625"/>
          <stop stopOpacity="1" stopColor="rgb(71.203613%, 40.985107%, 87.835693%)" offset="0.894531"/>
          <stop stopOpacity="1" stopColor="rgb(71.514893%, 41.038513%, 87.924194%)" offset="0.898438"/>
          <stop stopOpacity="1" stopColor="rgb(71.826172%, 41.090393%, 88.011169%)" offset="0.902344"/>
          <stop stopOpacity="1" stopColor="rgb(72.137451%, 41.142273%, 88.09967%)" offset="0.90625"/>
          <stop stopOpacity="1" stopColor="rgb(72.447205%, 41.194153%, 88.186646%)" offset="0.910156"/>
          <stop stopOpacity="1" stopColor="rgb(72.758484%, 41.247559%, 88.273621%)" offset="0.914062"/>
          <stop stopOpacity="1" stopColor="rgb(73.069763%, 41.299438%, 88.360596%)" offset="0.917969"/>
          <stop stopOpacity="1" stopColor="rgb(73.381042%, 41.352844%, 88.449097%)" offset="0.921875"/>
          <stop stopOpacity="1" stopColor="rgb(73.690796%, 41.404724%, 88.536072%)" offset="0.925781"/>
          <stop stopOpacity="1" stopColor="rgb(74.002075%, 41.456604%, 88.624573%)" offset="0.929688"/>
          <stop stopOpacity="1" stopColor="rgb(74.313354%, 41.508484%, 88.711548%)" offset="0.933594"/>
          <stop stopOpacity="1" stopColor="rgb(74.624634%, 41.56189%, 88.798523%)" offset="0.9375"/>
          <stop stopOpacity="1" stopColor="rgb(74.934387%, 41.61377%, 88.885498%)" offset="0.941406"/>
          <stop stopOpacity="1" stopColor="rgb(75.245667%, 41.667175%, 88.973999%)" offset="0.945312"/>
          <stop stopOpacity="1" stopColor="rgb(75.556946%, 41.719055%, 89.060974%)" offset="0.949219"/>
          <stop stopOpacity="1" stopColor="rgb(75.868225%, 41.770935%, 89.149475%)" offset="0.953125"/>
          <stop stopOpacity="1" stopColor="rgb(76.177979%, 41.822815%, 89.23645%)" offset="0.957031"/>
          <stop stopOpacity="1" stopColor="rgb(76.489258%, 41.876221%, 89.324951%)" offset="0.960938"/>
          <stop stopOpacity="1" stopColor="rgb(76.800537%, 41.928101%, 89.411926%)" offset="0.964844"/>
          <stop stopOpacity="1" stopColor="rgb(77.111816%, 41.97998%, 89.498901%)" offset="0.96875"/>
          <stop stopOpacity="1" stopColor="rgb(77.42157%, 42.03186%, 89.585876%)" offset="0.972656"/>
          <stop stopOpacity="1" stopColor="rgb(77.732849%, 42.085266%, 89.674377%)" offset="0.976563"/>
          <stop stopOpacity="1" stopColor="rgb(78.044128%, 42.137146%, 89.761353%)" offset="0.980469"/>
          <stop stopOpacity="1" stopColor="rgb(78.355408%, 42.190552%, 89.849854%)" offset="0.984375"/>
          <stop stopOpacity="1" stopColor="rgb(78.666687%, 42.242432%, 89.936829%)" offset="0.988281"/>
          <stop stopOpacity="1" stopColor="rgb(78.977966%, 42.294312%, 90.023804%)" offset="0.992188"/>
          <stop stopOpacity="1" stopColor="rgb(79.28772%, 42.346191%, 90.110779%)" offset="0.996094"/>
          <stop stopOpacity="1" stopColor="rgb(79.598999%, 42.399597%, 90.19928%)" offset="1"/>
        </linearGradient>
        <linearGradient x1="-2.187912"
                        gradientTransform="matrix(1, 0.00000000948305, -0.00000000948305, 1, 0.00000801817, -0.00000245312)"
                        y1="2.5" x2="73.025983" gradientUnits="userSpaceOnUse" y2="2.5" id="494c874b06">
          <stop stopOpacity="1" stopColor="rgb(0%, 28.999329%, 67.799377%)" offset="0"/>
          <stop stopOpacity="1" stopColor="rgb(0.0900269%, 29.014587%, 67.825317%)" offset="0.03125"/>
          <stop stopOpacity="1" stopColor="rgb(0.511169%, 29.084778%, 67.94281%)" offset="0.0351563"/>
          <stop stopOpacity="1" stopColor="rgb(0.842285%, 29.141235%, 68.035889%)" offset="0.0390625"/>
          <stop stopOpacity="1" stopColor="rgb(1.171875%, 29.196167%, 68.128967%)" offset="0.0429688"/>
          <stop stopOpacity="1" stopColor="rgb(1.502991%, 29.252625%, 68.222046%)" offset="0.046875"/>
          <stop stopOpacity="1" stopColor="rgb(1.832581%, 29.307556%, 68.315125%)" offset="0.0507813"/>
          <stop stopOpacity="1" stopColor="rgb(2.16217%, 29.364014%, 68.408203%)" offset="0.0546875"/>
          <stop stopOpacity="1" stopColor="rgb(2.49176%, 29.418945%, 68.501282%)" offset="0.0585938"/>
          <stop stopOpacity="1" stopColor="rgb(2.822876%, 29.473877%, 68.59436%)" offset="0.0625"/>
          <stop stopOpacity="1" stopColor="rgb(3.152466%, 29.528809%, 68.685913%)" offset="0.0664062"/>
          <stop stopOpacity="1" stopColor="rgb(3.483582%, 29.585266%, 68.778992%)" offset="0.0703125"/>
          <stop stopOpacity="1" stopColor="rgb(3.813171%, 29.640198%, 68.87207%)" offset="0.0742188"/>
          <stop stopOpacity="1" stopColor="rgb(4.144287%, 29.696655%, 68.965149%)" offset="0.078125"/>
          <stop stopOpacity="1" stopColor="rgb(4.473877%, 29.751587%, 69.058228%)" offset="0.0820313"/>
          <stop stopOpacity="1" stopColor="rgb(4.803467%, 29.808044%, 69.151306%)" offset="0.0859375"/>
          <stop stopOpacity="1" stopColor="rgb(5.133057%, 29.862976%, 69.244385%)" offset="0.0898438"/>
          <stop stopOpacity="1" stopColor="rgb(5.464172%, 29.919434%, 69.337463%)" offset="0.09375"/>
          <stop stopOpacity="1" stopColor="rgb(5.793762%, 29.974365%, 69.430542%)" offset="0.0976563"/>
          <stop stopOpacity="1" stopColor="rgb(6.124878%, 30.030823%, 69.523621%)" offset="0.101563"/>
          <stop stopOpacity="1" stopColor="rgb(6.454468%, 30.085754%, 69.615173%)" offset="0.105469"/>
          <stop stopOpacity="1" stopColor="rgb(6.784058%, 30.142212%, 69.708252%)" offset="0.109375"/>
          <stop stopOpacity="1" stopColor="rgb(7.113647%, 30.197144%, 69.801331%)" offset="0.113281"/>
          <stop stopOpacity="1" stopColor="rgb(7.444763%, 30.252075%, 69.894409%)" offset="0.117188"/>
          <stop stopOpacity="1" stopColor="rgb(7.774353%, 30.307007%, 69.987488%)" offset="0.121094"/>
          <stop stopOpacity="1" stopColor="rgb(8.105469%, 30.363464%, 70.080566%)" offset="0.125"/>
          <stop stopOpacity="1" stopColor="rgb(8.435059%, 30.418396%, 70.173645%)" offset="0.128906"/>
          <stop stopOpacity="1" stopColor="rgb(8.766174%, 30.474854%, 70.266724%)" offset="0.132813"/>
          <stop stopOpacity="1" stopColor="rgb(9.095764%, 30.529785%, 70.358276%)" offset="0.136719"/>
          <stop stopOpacity="1" stopColor="rgb(9.425354%, 30.586243%, 70.451355%)" offset="0.140625"/>
          <stop stopOpacity="1" stopColor="rgb(9.754944%, 30.641174%, 70.544434%)" offset="0.144531"/>
          <stop stopOpacity="1" stopColor="rgb(10.08606%, 30.697632%, 70.637512%)" offset="0.148438"/>
          <stop stopOpacity="1" stopColor="rgb(10.415649%, 30.752563%, 70.730591%)" offset="0.152344"/>
          <stop stopOpacity="1" stopColor="rgb(10.746765%, 30.809021%, 70.823669%)" offset="0.15625"/>
          <stop stopOpacity="1" stopColor="rgb(11.076355%, 30.863953%, 70.916748%)" offset="0.160156"/>
          <stop stopOpacity="1" stopColor="rgb(11.407471%, 30.918884%, 71.009827%)" offset="0.164063"/>
          <stop stopOpacity="1" stopColor="rgb(11.737061%, 30.973816%, 71.101379%)" offset="0.167969"/>
          <stop stopOpacity="1" stopColor="rgb(12.06665%, 31.030273%, 71.194458%)" offset="0.171875"/>
          <stop stopOpacity="1" stopColor="rgb(12.39624%, 31.085205%, 71.287537%)" offset="0.175781"/>
          <stop stopOpacity="1" stopColor="rgb(12.727356%, 31.141663%, 71.380615%)" offset="0.179688"/>
          <stop stopOpacity="1" stopColor="rgb(13.056946%, 31.196594%, 71.473694%)" offset="0.183594"/>
          <stop stopOpacity="1" stopColor="rgb(13.388062%, 31.253052%, 71.566772%)" offset="0.1875"/>
          <stop stopOpacity="1" stopColor="rgb(13.717651%, 31.307983%, 71.659851%)" offset="0.191406"/>
          <stop stopOpacity="1" stopColor="rgb(14.047241%, 31.364441%, 71.75293%)" offset="0.195313"/>
          <stop stopOpacity="1" stopColor="rgb(14.376831%, 31.419373%, 71.846008%)" offset="0.199219"/>
          <stop stopOpacity="1" stopColor="rgb(14.707947%, 31.47583%, 71.939087%)" offset="0.203125"/>
          <stop stopOpacity="1" stopColor="rgb(15.037537%, 31.530762%, 72.03064%)" offset="0.207031"/>
          <stop stopOpacity="1" stopColor="rgb(15.368652%, 31.587219%, 72.123718%)" offset="0.210938"/>
          <stop stopOpacity="1" stopColor="rgb(15.698242%, 31.642151%, 72.216797%)" offset="0.214844"/>
          <stop stopOpacity="1" stopColor="rgb(16.029358%, 31.697083%, 72.309875%)" offset="0.21875"/>
          <stop stopOpacity="1" stopColor="rgb(16.358948%, 31.752014%, 72.402954%)" offset="0.222656"/>
          <stop stopOpacity="1" stopColor="rgb(16.688538%, 31.808472%, 72.496033%)" offset="0.226563"/>
          <stop stopOpacity="1" stopColor="rgb(17.018127%, 31.863403%, 72.589111%)" offset="0.230469"/>
          <stop stopOpacity="1" stopColor="rgb(17.349243%, 31.919861%, 72.68219%)" offset="0.234375"/>
          <stop stopOpacity="1" stopColor="rgb(17.678833%, 31.974792%, 72.773743%)" offset="0.238281"/>
          <stop stopOpacity="1" stopColor="rgb(18.009949%, 32.03125%, 72.866821%)" offset="0.242188"/>
          <stop stopOpacity="1" stopColor="rgb(18.339539%, 32.086182%, 72.9599%)" offset="0.246094"/>
          <stop stopOpacity="1" stopColor="rgb(18.670654%, 32.142639%, 73.052979%)" offset="0.25"/>
          <stop stopOpacity="1" stopColor="rgb(19.000244%, 32.197571%, 73.146057%)" offset="0.253906"/>
          <stop stopOpacity="1" stopColor="rgb(19.329834%, 32.254028%, 73.239136%)" offset="0.257813"/>
          <stop stopOpacity="1" stopColor="rgb(19.659424%, 32.30896%, 73.332214%)" offset="0.261719"/>
          <stop stopOpacity="1" stopColor="rgb(19.99054%, 32.363892%, 73.425293%)" offset="0.265625"/>
          <stop stopOpacity="1" stopColor="rgb(20.320129%, 32.418823%, 73.518372%)" offset="0.269531"/>
          <stop stopOpacity="1" stopColor="rgb(20.651245%, 32.475281%, 73.61145%)" offset="0.273438"/>
          <stop stopOpacity="1" stopColor="rgb(20.980835%, 32.530212%, 73.703003%)" offset="0.277344"/>
          <stop stopOpacity="1" stopColor="rgb(21.310425%, 32.58667%, 73.796082%)" offset="0.28125"/>
          <stop stopOpacity="1" stopColor="rgb(21.640015%, 32.641602%, 73.88916%)" offset="0.285156"/>
          <stop stopOpacity="1" stopColor="rgb(21.97113%, 32.698059%, 73.982239%)" offset="0.289063"/>
          <stop stopOpacity="1" stopColor="rgb(22.30072%, 32.752991%, 74.075317%)" offset="0.292969"/>
          <stop stopOpacity="1" stopColor="rgb(22.631836%, 32.809448%, 74.168396%)" offset="0.296875"/>
          <stop stopOpacity="1" stopColor="rgb(22.961426%, 32.86438%, 74.261475%)" offset="0.300781"/>
          <stop stopOpacity="1" stopColor="rgb(23.292542%, 32.920837%, 74.354553%)" offset="0.304688"/>
          <stop stopOpacity="1" stopColor="rgb(23.622131%, 32.975769%, 74.446106%)" offset="0.308594"/>
          <stop stopOpacity="1" stopColor="rgb(23.951721%, 33.032227%, 74.539185%)" offset="0.3125"/>
          <stop stopOpacity="1" stopColor="rgb(24.281311%, 33.087158%, 74.632263%)" offset="0.316406"/>
          <stop stopOpacity="1" stopColor="rgb(24.612427%, 33.14209%, 74.725342%)" offset="0.320313"/>
          <stop stopOpacity="1" stopColor="rgb(24.942017%, 33.197021%, 74.81842%)" offset="0.324219"/>
          <stop stopOpacity="1" stopColor="rgb(25.273132%, 33.253479%, 74.911499%)" offset="0.328125"/>
          <stop stopOpacity="1" stopColor="rgb(25.602722%, 33.308411%, 75.004578%)" offset="0.332031"/>
          <stop stopOpacity="1" stopColor="rgb(25.933838%, 33.364868%, 75.097656%)" offset="0.335938"/>
          <stop stopOpacity="1" stopColor="rgb(26.263428%, 33.4198%, 75.189209%)" offset="0.339844"/>
          <stop stopOpacity="1" stopColor="rgb(26.593018%, 33.476257%, 75.282288%)" offset="0.34375"/>
          <stop stopOpacity="1" stopColor="rgb(26.922607%, 33.531189%, 75.375366%)" offset="0.347656"/>
          <stop stopOpacity="1" stopColor="rgb(27.253723%, 33.587646%, 75.468445%)" offset="0.351563"/>
          <stop stopOpacity="1" stopColor="rgb(27.583313%, 33.642578%, 75.561523%)" offset="0.355469"/>
          <stop stopOpacity="1" stopColor="rgb(27.914429%, 33.699036%, 75.654602%)" offset="0.359375"/>
          <stop stopOpacity="1" stopColor="rgb(28.244019%, 33.753967%, 75.747681%)" offset="0.363281"/>
          <stop stopOpacity="1" stopColor="rgb(28.573608%, 33.808899%, 75.840759%)" offset="0.367188"/>
          <stop stopOpacity="1" stopColor="rgb(28.903198%, 33.863831%, 75.933838%)" offset="0.371094"/>
          <stop stopOpacity="1" stopColor="rgb(29.234314%, 33.920288%, 76.026917%)" offset="0.375"/>
          <stop stopOpacity="1" stopColor="rgb(29.563904%, 33.97522%, 76.118469%)" offset="0.378906"/>
          <stop stopOpacity="1" stopColor="rgb(29.89502%, 34.031677%, 76.211548%)" offset="0.382813"/>
          <stop stopOpacity="1" stopColor="rgb(30.224609%, 34.086609%, 76.304626%)" offset="0.386719"/>
          <stop stopOpacity="1" stopColor="rgb(30.555725%, 34.143066%, 76.397705%)" offset="0.390625"/>
          <stop stopOpacity="1" stopColor="rgb(30.885315%, 34.197998%, 76.490784%)" offset="0.394531"/>
          <stop stopOpacity="1" stopColor="rgb(31.214905%, 34.254456%, 76.583862%)" offset="0.398438"/>
          <stop stopOpacity="1" stopColor="rgb(31.544495%, 34.309387%, 76.676941%)" offset="0.402344"/>
          <stop stopOpacity="1" stopColor="rgb(31.87561%, 34.365845%, 76.77002%)" offset="0.40625"/>
          <stop stopOpacity="1" stopColor="rgb(32.2052%, 34.420776%, 76.861572%)" offset="0.410156"/>
          <stop stopOpacity="1" stopColor="rgb(32.536316%, 34.477234%, 76.954651%)" offset="0.414063"/>
          <stop stopOpacity="1" stopColor="rgb(32.865906%, 34.532166%, 77.047729%)" offset="0.417969"/>
          <stop stopOpacity="1" stopColor="rgb(33.197021%, 34.587097%, 77.140808%)" offset="0.421875"/>
          <stop stopOpacity="1" stopColor="rgb(33.526611%, 34.642029%, 77.233887%)" offset="0.425781"/>
          <stop stopOpacity="1" stopColor="rgb(33.856201%, 34.698486%, 77.326965%)" offset="0.429688"/>
          <stop stopOpacity="1" stopColor="rgb(34.185791%, 34.753418%, 77.420044%)" offset="0.433594"/>
          <stop stopOpacity="1" stopColor="rgb(34.516907%, 34.809875%, 77.513123%)" offset="0.4375"/>
          <stop stopOpacity="1" stopColor="rgb(34.846497%, 34.864807%, 77.606201%)" offset="0.441406"/>
          <stop stopOpacity="1" stopColor="rgb(35.177612%, 34.921265%, 77.69928%)" offset="0.445313"/>
          <stop stopOpacity="1" stopColor="rgb(35.507202%, 34.976196%, 77.790833%)" offset="0.449219"/>
          <stop stopOpacity="1" stopColor="rgb(35.836792%, 35.032654%, 77.883911%)" offset="0.453125"/>
          <stop stopOpacity="1" stopColor="rgb(36.166382%, 35.087585%, 77.97699%)" offset="0.457031"/>
          <stop stopOpacity="1" stopColor="rgb(36.497498%, 35.144043%, 78.070068%)" offset="0.460938"/>
          <stop stopOpacity="1" stopColor="rgb(36.827087%, 35.198975%, 78.163147%)" offset="0.464844"/>
          <stop stopOpacity="1" stopColor="rgb(37.158203%, 35.253906%, 78.256226%)" offset="0.46875"/>
          <stop stopOpacity="1" stopColor="rgb(37.487793%, 35.308838%, 78.349304%)" offset="0.472656"/>
          <stop stopOpacity="1" stopColor="rgb(37.818909%, 35.365295%, 78.442383%)" offset="0.476562"/>
          <stop stopOpacity="1" stopColor="rgb(38.148499%, 35.420227%, 78.533936%)" offset="0.480469"/>
          <stop stopOpacity="1" stopColor="rgb(38.478088%, 35.476685%, 78.627014%)" offset="0.484375"/>
          <stop stopOpacity="1" stopColor="rgb(38.807678%, 35.531616%, 78.720093%)" offset="0.488281"/>
          <stop stopOpacity="1" stopColor="rgb(39.138794%, 35.588074%, 78.813171%)" offset="0.492188"/>
          <stop stopOpacity="1" stopColor="rgb(39.468384%, 35.643005%, 78.90625%)" offset="0.496094"/>
          <stop stopOpacity="1" stopColor="rgb(39.7995%, 35.699463%, 78.999329%)" offset="0.5"/>
          <stop stopOpacity="1" stopColor="rgb(40.129089%, 35.754395%, 79.092407%)" offset="0.503906"/>
          <stop stopOpacity="1" stopColor="rgb(40.460205%, 35.810852%, 79.185486%)" offset="0.507813"/>
          <stop stopOpacity="1" stopColor="rgb(40.789795%, 35.865784%, 79.277039%)" offset="0.511719"/>
          <stop stopOpacity="1" stopColor="rgb(41.119385%, 35.922241%, 79.370117%)" offset="0.515625"/>
          <stop stopOpacity="1" stopColor="rgb(41.448975%, 35.977173%, 79.463196%)" offset="0.519531"/>
          <stop stopOpacity="1" stopColor="rgb(41.78009%, 36.032104%, 79.556274%)" offset="0.523438"/>
          <stop stopOpacity="1" stopColor="rgb(42.10968%, 36.087036%, 79.649353%)" offset="0.527344"/>
          <stop stopOpacity="1" stopColor="rgb(42.440796%, 36.143494%, 79.742432%)" offset="0.53125"/>
          <stop stopOpacity="1" stopColor="rgb(42.770386%, 36.198425%, 79.83551%)" offset="0.535156"/>
          <stop stopOpacity="1" stopColor="rgb(43.099976%, 36.254883%, 79.928589%)" offset="0.539063"/>
          <stop stopOpacity="1" stopColor="rgb(43.429565%, 36.309814%, 80.021667%)" offset="0.542969"/>
          <stop stopOpacity="1" stopColor="rgb(43.760681%, 36.366272%, 80.114746%)" offset="0.546875"/>
          <stop stopOpacity="1" stopColor="rgb(44.090271%, 36.421204%, 80.206299%)" offset="0.550781"/>
          <stop stopOpacity="1" stopColor="rgb(44.421387%, 36.477661%, 80.299377%)" offset="0.554688"/>
          <stop stopOpacity="1" stopColor="rgb(44.750977%, 36.532593%, 80.392456%)" offset="0.558594"/>
          <stop stopOpacity="1" stopColor="rgb(45.082092%, 36.58905%, 80.485535%)" offset="0.5625"/>
          <stop stopOpacity="1" stopColor="rgb(45.411682%, 36.643982%, 80.578613%)" offset="0.566406"/>
          <stop stopOpacity="1" stopColor="rgb(45.741272%, 36.698914%, 80.671692%)" offset="0.570312"/>
          <stop stopOpacity="1" stopColor="rgb(46.070862%, 36.753845%, 80.764771%)" offset="0.574219"/>
          <stop stopOpacity="1" stopColor="rgb(46.401978%, 36.810303%, 80.857849%)" offset="0.578125"/>
          <stop stopOpacity="1" stopColor="rgb(46.731567%, 36.865234%, 80.949402%)" offset="0.582031"/>
          <stop stopOpacity="1" stopColor="rgb(47.062683%, 36.921692%, 81.04248%)" offset="0.585938"/>
          <stop stopOpacity="1" stopColor="rgb(47.392273%, 36.976624%, 81.135559%)" offset="0.589844"/>
          <stop stopOpacity="1" stopColor="rgb(47.723389%, 37.033081%, 81.228638%)" offset="0.59375"/>
          <stop stopOpacity="1" stopColor="rgb(48.052979%, 37.088013%, 81.321716%)" offset="0.597656"/>
          <stop stopOpacity="1" stopColor="rgb(48.382568%, 37.14447%, 81.414795%)" offset="0.601563"/>
          <stop stopOpacity="1" stopColor="rgb(48.712158%, 37.199402%, 81.507874%)" offset="0.605469"/>
          <stop stopOpacity="1" stopColor="rgb(49.043274%, 37.255859%, 81.600952%)" offset="0.609375"/>
          <stop stopOpacity="1" stopColor="rgb(49.372864%, 37.310791%, 81.694031%)" offset="0.613281"/>
          <stop stopOpacity="1" stopColor="rgb(49.703979%, 37.367249%, 81.787109%)" offset="0.617188"/>
          <stop stopOpacity="1" stopColor="rgb(50.033569%, 37.42218%, 81.878662%)" offset="0.621094"/>
          <stop stopOpacity="1" stopColor="rgb(50.364685%, 37.477112%, 81.971741%)" offset="0.625"/>
          <stop stopOpacity="1" stopColor="rgb(50.694275%, 37.532043%, 82.064819%)" offset="0.628906"/>
          <stop stopOpacity="1" stopColor="rgb(51.023865%, 37.588501%, 82.157898%)" offset="0.632813"/>
          <stop stopOpacity="1" stopColor="rgb(51.353455%, 37.643433%, 82.250977%)" offset="0.636719"/>
          <stop stopOpacity="1" stopColor="rgb(51.68457%, 37.69989%, 82.344055%)" offset="0.640625"/>
          <stop stopOpacity="1" stopColor="rgb(52.01416%, 37.754822%, 82.437134%)" offset="0.644531"/>
          <stop stopOpacity="1" stopColor="rgb(52.345276%, 37.811279%, 82.530212%)" offset="0.648438"/>
          <stop stopOpacity="1" stopColor="rgb(52.674866%, 37.866211%, 82.621765%)" offset="0.652344"/>
          <stop stopOpacity="1" stopColor="rgb(53.004456%, 37.922668%, 82.714844%)" offset="0.65625"/>
          <stop stopOpacity="1" stopColor="rgb(53.334045%, 37.9776%, 82.807922%)" offset="0.660156"/>
          <stop stopOpacity="1" stopColor="rgb(53.665161%, 38.034058%, 82.901001%)" offset="0.664063"/>
          <stop stopOpacity="1" stopColor="rgb(53.994751%, 38.088989%, 82.99408%)" offset="0.667969"/>
          <stop stopOpacity="1" stopColor="rgb(54.325867%, 38.143921%, 83.087158%)" offset="0.671875"/>
          <stop stopOpacity="1" stopColor="rgb(54.655457%, 38.198853%, 83.180237%)" offset="0.675781"/>
          <stop stopOpacity="1" stopColor="rgb(54.986572%, 38.25531%, 83.273315%)" offset="0.679688"/>
          <stop stopOpacity="1" stopColor="rgb(55.316162%, 38.310242%, 83.364868%)" offset="0.683594"/>
          <stop stopOpacity="1" stopColor="rgb(55.645752%, 38.366699%, 83.457947%)" offset="0.6875"/>
          <stop stopOpacity="1" stopColor="rgb(55.975342%, 38.421631%, 83.551025%)" offset="0.691406"/>
          <stop stopOpacity="1" stopColor="rgb(56.306458%, 38.478088%, 83.644104%)" offset="0.695313"/>
          <stop stopOpacity="1" stopColor="rgb(56.636047%, 38.53302%, 83.737183%)" offset="0.699219"/>
          <stop stopOpacity="1" stopColor="rgb(56.967163%, 38.589478%, 83.830261%)" offset="0.703125"/>
          <stop stopOpacity="1" stopColor="rgb(57.296753%, 38.644409%, 83.92334%)" offset="0.707031"/>
          <stop stopOpacity="1" stopColor="rgb(57.627869%, 38.700867%, 84.016418%)" offset="0.710938"/>
          <stop stopOpacity="1" stopColor="rgb(57.957458%, 38.755798%, 84.109497%)" offset="0.714844"/>
          <stop stopOpacity="1" stopColor="rgb(58.287048%, 38.812256%, 84.202576%)" offset="0.71875"/>
          <stop stopOpacity="1" stopColor="rgb(58.616638%, 38.867188%, 84.294128%)" offset="0.722656"/>
          <stop stopOpacity="1" stopColor="rgb(58.947754%, 38.922119%, 84.387207%)" offset="0.726563"/>
          <stop stopOpacity="1" stopColor="rgb(59.277344%, 38.977051%, 84.480286%)" offset="0.730469"/>
          <stop stopOpacity="1" stopColor="rgb(59.608459%, 39.033508%, 84.573364%)" offset="0.734375"/>
          <stop stopOpacity="1" stopColor="rgb(59.938049%, 39.08844%, 84.666443%)" offset="0.738281"/>
          <stop stopOpacity="1" stopColor="rgb(60.267639%, 39.144897%, 84.759521%)" offset="0.742188"/>
          <stop stopOpacity="1" stopColor="rgb(60.597229%, 39.199829%, 84.8526%)" offset="0.746094"/>
          <stop stopOpacity="1" stopColor="rgb(60.928345%, 39.256287%, 84.945679%)" offset="0.75"/>
          <stop stopOpacity="1" stopColor="rgb(61.257935%, 39.311218%, 85.037231%)" offset="0.753906"/>
          <stop stopOpacity="1" stopColor="rgb(61.58905%, 39.367676%, 85.13031%)" offset="0.757813"/>
          <stop stopOpacity="1" stopColor="rgb(61.91864%, 39.422607%, 85.223389%)" offset="0.761719"/>
          <stop stopOpacity="1" stopColor="rgb(62.249756%, 39.479065%, 85.316467%)" offset="0.765625"/>
          <stop stopOpacity="1" stopColor="rgb(62.579346%, 39.533997%, 85.409546%)" offset="0.769531"/>
          <stop stopOpacity="1" stopColor="rgb(62.908936%, 39.588928%, 85.502625%)" offset="0.773438"/>
          <stop stopOpacity="1" stopColor="rgb(63.238525%, 39.64386%, 85.595703%)" offset="0.777344"/>
          <stop stopOpacity="1" stopColor="rgb(63.569641%, 39.700317%, 85.688782%)" offset="0.78125"/>
          <stop stopOpacity="1" stopColor="rgb(63.899231%, 39.755249%, 85.78186%)" offset="0.785156"/>
          <stop stopOpacity="1" stopColor="rgb(64.230347%, 39.811707%, 85.874939%)" offset="0.789063"/>
          <stop stopOpacity="1" stopColor="rgb(64.559937%, 39.866638%, 85.966492%)" offset="0.792969"/>
          <stop stopOpacity="1" stopColor="rgb(64.891052%, 39.923096%, 86.05957%)" offset="0.796875"/>
          <stop stopOpacity="1" stopColor="rgb(65.220642%, 39.978027%, 86.152649%)" offset="0.800781"/>
          <stop stopOpacity="1" stopColor="rgb(65.550232%, 40.034485%, 86.245728%)" offset="0.804688"/>
          <stop stopOpacity="1" stopColor="rgb(65.879822%, 40.089417%, 86.338806%)" offset="0.808594"/>
          <stop stopOpacity="1" stopColor="rgb(66.210938%, 40.145874%, 86.431885%)" offset="0.8125"/>
          <stop stopOpacity="1" stopColor="rgb(66.540527%, 40.200806%, 86.524963%)" offset="0.816406"/>
          <stop stopOpacity="1" stopColor="rgb(66.871643%, 40.257263%, 86.618042%)" offset="0.820313"/>
          <stop stopOpacity="1" stopColor="rgb(67.201233%, 40.312195%, 86.709595%)" offset="0.824219"/>
          <stop stopOpacity="1" stopColor="rgb(67.530823%, 40.367126%, 86.802673%)" offset="0.828125"/>
          <stop stopOpacity="1" stopColor="rgb(67.860413%, 40.422058%, 86.895752%)" offset="0.832031"/>
          <stop stopOpacity="1" stopColor="rgb(68.191528%, 40.478516%, 86.988831%)" offset="0.835938"/>
          <stop stopOpacity="1" stopColor="rgb(68.521118%, 40.533447%, 87.081909%)" offset="0.839844"/>
          <stop stopOpacity="1" stopColor="rgb(68.852234%, 40.589905%, 87.174988%)" offset="0.84375"/>
          <stop stopOpacity="1" stopColor="rgb(69.181824%, 40.644836%, 87.268066%)" offset="0.847656"/>
          <stop stopOpacity="1" stopColor="rgb(69.512939%, 40.701294%, 87.361145%)" offset="0.851563"/>
          <stop stopOpacity="1" stopColor="rgb(69.842529%, 40.756226%, 87.452698%)" offset="0.855469"/>
          <stop stopOpacity="1" stopColor="rgb(70.172119%, 40.812683%, 87.545776%)" offset="0.859375"/>
          <stop stopOpacity="1" stopColor="rgb(70.501709%, 40.867615%, 87.638855%)" offset="0.863281"/>
          <stop stopOpacity="1" stopColor="rgb(70.832825%, 40.924072%, 87.731934%)" offset="0.867188"/>
          <stop stopOpacity="1" stopColor="rgb(71.162415%, 40.979004%, 87.825012%)" offset="0.871094"/>
          <stop stopOpacity="1" stopColor="rgb(71.49353%, 41.033936%, 87.918091%)" offset="0.875"/>
          <stop stopOpacity="1" stopColor="rgb(71.82312%, 41.088867%, 88.011169%)" offset="0.878906"/>
          <stop stopOpacity="1" stopColor="rgb(72.154236%, 41.145325%, 88.104248%)" offset="0.882813"/>
          <stop stopOpacity="1" stopColor="rgb(72.483826%, 41.200256%, 88.197327%)" offset="0.886719"/>
          <stop stopOpacity="1" stopColor="rgb(72.813416%, 41.256714%, 88.290405%)" offset="0.890625"/>
          <stop stopOpacity="1" stopColor="rgb(73.143005%, 41.311646%, 88.381958%)" offset="0.894531"/>
          <stop stopOpacity="1" stopColor="rgb(73.474121%, 41.368103%, 88.475037%)" offset="0.898438"/>
          <stop stopOpacity="1" stopColor="rgb(73.803711%, 41.423035%, 88.568115%)" offset="0.902344"/>
          <stop stopOpacity="1" stopColor="rgb(74.134827%, 41.479492%, 88.661194%)" offset="0.90625"/>
          <stop stopOpacity="1" stopColor="rgb(74.464417%, 41.534424%, 88.754272%)" offset="0.910156"/>
          <stop stopOpacity="1" stopColor="rgb(74.794006%, 41.590881%, 88.847351%)" offset="0.914063"/>
          <stop stopOpacity="1" stopColor="rgb(75.123596%, 41.645813%, 88.94043%)" offset="0.917969"/>
          <stop stopOpacity="1" stopColor="rgb(75.454712%, 41.702271%, 89.033508%)" offset="0.921875"/>
          <stop stopOpacity="1" stopColor="rgb(75.784302%, 41.757202%, 89.125061%)" offset="0.925781"/>
          <stop stopOpacity="1" stopColor="rgb(76.115417%, 41.812134%, 89.21814%)" offset="0.929688"/>
          <stop stopOpacity="1" stopColor="rgb(76.445007%, 41.867065%, 89.311218%)" offset="0.933594"/>
          <stop stopOpacity="1" stopColor="rgb(76.776123%, 41.923523%, 89.404297%)" offset="0.9375"/>
          <stop stopOpacity="1" stopColor="rgb(77.105713%, 41.978455%, 89.497375%)" offset="0.941406"/>
          <stop stopOpacity="1" stopColor="rgb(77.435303%, 42.034912%, 89.590454%)" offset="0.945312"/>
          <stop stopOpacity="1" stopColor="rgb(77.764893%, 42.089844%, 89.683533%)" offset="0.949219"/>
          <stop stopOpacity="1" stopColor="rgb(78.096008%, 42.146301%, 89.776611%)" offset="0.953125"/>
          <stop stopOpacity="1" stopColor="rgb(78.425598%, 42.201233%, 89.86969%)" offset="0.957031"/>
          <stop stopOpacity="1" stopColor="rgb(78.756714%, 42.25769%, 89.962769%)" offset="0.960938"/>
          <stop stopOpacity="1" stopColor="rgb(79.086304%, 42.312622%, 90.054321%)" offset="0.964844"/>
          <stop stopOpacity="1" stopColor="rgb(79.417419%, 42.36908%, 90.1474%)" offset="0.96875"/>
          <stop stopOpacity="1" stopColor="rgb(79.507446%, 42.384338%, 90.17334%)" offset="1"/>
        </linearGradient>
        <linearGradient x1="-2.187903"
                        gradientTransform="matrix(1, -0.00000000135472, 0.00000000135472, 1, -0.000000695133, -0.00000397841)"
                        y1="2.5" x2="73.025991" gradientUnits="userSpaceOnUse" y2="2.5" id="2f78460c2c">
          <stop stopOpacity="1" stopColor="rgb(0%, 28.999329%, 67.799377%)" offset="0"/>
          <stop stopOpacity="1" stopColor="rgb(0.0900269%, 29.014587%, 67.825317%)" offset="0.03125"/>
          <stop stopOpacity="1" stopColor="rgb(0.511169%, 29.084778%, 67.94281%)" offset="0.0351563"/>
          <stop stopOpacity="1" stopColor="rgb(0.842285%, 29.141235%, 68.035889%)" offset="0.0390625"/>
          <stop stopOpacity="1" stopColor="rgb(1.171875%, 29.196167%, 68.128967%)" offset="0.0429688"/>
          <stop stopOpacity="1" stopColor="rgb(1.502991%, 29.252625%, 68.222046%)" offset="0.046875"/>
          <stop stopOpacity="1" stopColor="rgb(1.832581%, 29.307556%, 68.315125%)" offset="0.0507813"/>
          <stop stopOpacity="1" stopColor="rgb(2.16217%, 29.364014%, 68.408203%)" offset="0.0546875"/>
          <stop stopOpacity="1" stopColor="rgb(2.49176%, 29.418945%, 68.501282%)" offset="0.0585938"/>
          <stop stopOpacity="1" stopColor="rgb(2.822876%, 29.473877%, 68.59436%)" offset="0.0625"/>
          <stop stopOpacity="1" stopColor="rgb(3.152466%, 29.528809%, 68.685913%)" offset="0.0664063"/>
          <stop stopOpacity="1" stopColor="rgb(3.483582%, 29.585266%, 68.778992%)" offset="0.0703125"/>
          <stop stopOpacity="1" stopColor="rgb(3.813171%, 29.640198%, 68.87207%)" offset="0.0742188"/>
          <stop stopOpacity="1" stopColor="rgb(4.144287%, 29.696655%, 68.965149%)" offset="0.078125"/>
          <stop stopOpacity="1" stopColor="rgb(4.473877%, 29.751587%, 69.058228%)" offset="0.0820313"/>
          <stop stopOpacity="1" stopColor="rgb(4.803467%, 29.808044%, 69.151306%)" offset="0.0859375"/>
          <stop stopOpacity="1" stopColor="rgb(5.133057%, 29.862976%, 69.244385%)" offset="0.0898438"/>
          <stop stopOpacity="1" stopColor="rgb(5.464172%, 29.919434%, 69.337463%)" offset="0.09375"/>
          <stop stopOpacity="1" stopColor="rgb(5.793762%, 29.974365%, 69.430542%)" offset="0.0976563"/>
          <stop stopOpacity="1" stopColor="rgb(6.124878%, 30.030823%, 69.523621%)" offset="0.101563"/>
          <stop stopOpacity="1" stopColor="rgb(6.454468%, 30.085754%, 69.615173%)" offset="0.105469"/>
          <stop stopOpacity="1" stopColor="rgb(6.784058%, 30.142212%, 69.708252%)" offset="0.109375"/>
          <stop stopOpacity="1" stopColor="rgb(7.113647%, 30.197144%, 69.801331%)" offset="0.113281"/>
          <stop stopOpacity="1" stopColor="rgb(7.444763%, 30.252075%, 69.894409%)" offset="0.117188"/>
          <stop stopOpacity="1" stopColor="rgb(7.774353%, 30.307007%, 69.987488%)" offset="0.121094"/>
          <stop stopOpacity="1" stopColor="rgb(8.105469%, 30.363464%, 70.080566%)" offset="0.125"/>
          <stop stopOpacity="1" stopColor="rgb(8.435059%, 30.418396%, 70.173645%)" offset="0.128906"/>
          <stop stopOpacity="1" stopColor="rgb(8.766174%, 30.474854%, 70.266724%)" offset="0.132813"/>
          <stop stopOpacity="1" stopColor="rgb(9.095764%, 30.529785%, 70.358276%)" offset="0.136719"/>
          <stop stopOpacity="1" stopColor="rgb(9.425354%, 30.586243%, 70.451355%)" offset="0.140625"/>
          <stop stopOpacity="1" stopColor="rgb(9.754944%, 30.641174%, 70.544434%)" offset="0.144531"/>
          <stop stopOpacity="1" stopColor="rgb(10.08606%, 30.697632%, 70.637512%)" offset="0.148438"/>
          <stop stopOpacity="1" stopColor="rgb(10.415649%, 30.752563%, 70.730591%)" offset="0.152344"/>
          <stop stopOpacity="1" stopColor="rgb(10.746765%, 30.809021%, 70.823669%)" offset="0.15625"/>
          <stop stopOpacity="1" stopColor="rgb(11.076355%, 30.863953%, 70.916748%)" offset="0.160156"/>
          <stop stopOpacity="1" stopColor="rgb(11.407471%, 30.918884%, 71.009827%)" offset="0.164063"/>
          <stop stopOpacity="1" stopColor="rgb(11.737061%, 30.973816%, 71.101379%)" offset="0.167969"/>
          <stop stopOpacity="1" stopColor="rgb(12.06665%, 31.030273%, 71.194458%)" offset="0.171875"/>
          <stop stopOpacity="1" stopColor="rgb(12.39624%, 31.085205%, 71.287537%)" offset="0.175781"/>
          <stop stopOpacity="1" stopColor="rgb(12.727356%, 31.141663%, 71.380615%)" offset="0.179688"/>
          <stop stopOpacity="1" stopColor="rgb(13.056946%, 31.196594%, 71.473694%)" offset="0.183594"/>
          <stop stopOpacity="1" stopColor="rgb(13.388062%, 31.253052%, 71.566772%)" offset="0.1875"/>
          <stop stopOpacity="1" stopColor="rgb(13.717651%, 31.307983%, 71.659851%)" offset="0.191406"/>
          <stop stopOpacity="1" stopColor="rgb(14.047241%, 31.364441%, 71.75293%)" offset="0.195313"/>
          <stop stopOpacity="1" stopColor="rgb(14.376831%, 31.419373%, 71.846008%)" offset="0.199219"/>
          <stop stopOpacity="1" stopColor="rgb(14.707947%, 31.47583%, 71.939087%)" offset="0.203125"/>
          <stop stopOpacity="1" stopColor="rgb(15.037537%, 31.530762%, 72.03064%)" offset="0.207031"/>
          <stop stopOpacity="1" stopColor="rgb(15.368652%, 31.587219%, 72.123718%)" offset="0.210938"/>
          <stop stopOpacity="1" stopColor="rgb(15.698242%, 31.642151%, 72.216797%)" offset="0.214844"/>
          <stop stopOpacity="1" stopColor="rgb(16.029358%, 31.697083%, 72.309875%)" offset="0.21875"/>
          <stop stopOpacity="1" stopColor="rgb(16.358948%, 31.752014%, 72.402954%)" offset="0.222656"/>
          <stop stopOpacity="1" stopColor="rgb(16.688538%, 31.808472%, 72.496033%)" offset="0.226563"/>
          <stop stopOpacity="1" stopColor="rgb(17.018127%, 31.863403%, 72.589111%)" offset="0.230469"/>
          <stop stopOpacity="1" stopColor="rgb(17.349243%, 31.919861%, 72.68219%)" offset="0.234375"/>
          <stop stopOpacity="1" stopColor="rgb(17.678833%, 31.974792%, 72.773743%)" offset="0.238281"/>
          <stop stopOpacity="1" stopColor="rgb(18.009949%, 32.03125%, 72.866821%)" offset="0.242188"/>
          <stop stopOpacity="1" stopColor="rgb(18.339539%, 32.086182%, 72.9599%)" offset="0.246094"/>
          <stop stopOpacity="1" stopColor="rgb(18.670654%, 32.142639%, 73.052979%)" offset="0.25"/>
          <stop stopOpacity="1" stopColor="rgb(19.000244%, 32.197571%, 73.146057%)" offset="0.253906"/>
          <stop stopOpacity="1" stopColor="rgb(19.329834%, 32.254028%, 73.239136%)" offset="0.257813"/>
          <stop stopOpacity="1" stopColor="rgb(19.659424%, 32.30896%, 73.332214%)" offset="0.261719"/>
          <stop stopOpacity="1" stopColor="rgb(19.99054%, 32.363892%, 73.425293%)" offset="0.265625"/>
          <stop stopOpacity="1" stopColor="rgb(20.320129%, 32.418823%, 73.518372%)" offset="0.269531"/>
          <stop stopOpacity="1" stopColor="rgb(20.651245%, 32.475281%, 73.61145%)" offset="0.273438"/>
          <stop stopOpacity="1" stopColor="rgb(20.980835%, 32.530212%, 73.703003%)" offset="0.277344"/>
          <stop stopOpacity="1" stopColor="rgb(21.310425%, 32.58667%, 73.796082%)" offset="0.28125"/>
          <stop stopOpacity="1" stopColor="rgb(21.640015%, 32.641602%, 73.88916%)" offset="0.285156"/>
          <stop stopOpacity="1" stopColor="rgb(21.97113%, 32.698059%, 73.982239%)" offset="0.289063"/>
          <stop stopOpacity="1" stopColor="rgb(22.30072%, 32.752991%, 74.075317%)" offset="0.292969"/>
          <stop stopOpacity="1" stopColor="rgb(22.631836%, 32.809448%, 74.168396%)" offset="0.296875"/>
          <stop stopOpacity="1" stopColor="rgb(22.961426%, 32.86438%, 74.261475%)" offset="0.300781"/>
          <stop stopOpacity="1" stopColor="rgb(23.292542%, 32.920837%, 74.354553%)" offset="0.304688"/>
          <stop stopOpacity="1" stopColor="rgb(23.622131%, 32.975769%, 74.446106%)" offset="0.308594"/>
          <stop stopOpacity="1" stopColor="rgb(23.951721%, 33.032227%, 74.539185%)" offset="0.3125"/>
          <stop stopOpacity="1" stopColor="rgb(24.281311%, 33.087158%, 74.632263%)" offset="0.316406"/>
          <stop stopOpacity="1" stopColor="rgb(24.612427%, 33.14209%, 74.725342%)" offset="0.320313"/>
          <stop stopOpacity="1" stopColor="rgb(24.942017%, 33.197021%, 74.81842%)" offset="0.324219"/>
          <stop stopOpacity="1" stopColor="rgb(25.273132%, 33.253479%, 74.911499%)" offset="0.328125"/>
          <stop stopOpacity="1" stopColor="rgb(25.602722%, 33.308411%, 75.004578%)" offset="0.332031"/>
          <stop stopOpacity="1" stopColor="rgb(25.933838%, 33.364868%, 75.097656%)" offset="0.335938"/>
          <stop stopOpacity="1" stopColor="rgb(26.263428%, 33.4198%, 75.189209%)" offset="0.339844"/>
          <stop stopOpacity="1" stopColor="rgb(26.593018%, 33.476257%, 75.282288%)" offset="0.34375"/>
          <stop stopOpacity="1" stopColor="rgb(26.922607%, 33.531189%, 75.375366%)" offset="0.347656"/>
          <stop stopOpacity="1" stopColor="rgb(27.253723%, 33.587646%, 75.468445%)" offset="0.351563"/>
          <stop stopOpacity="1" stopColor="rgb(27.583313%, 33.642578%, 75.561523%)" offset="0.355469"/>
          <stop stopOpacity="1" stopColor="rgb(27.914429%, 33.699036%, 75.654602%)" offset="0.359375"/>
          <stop stopOpacity="1" stopColor="rgb(28.244019%, 33.753967%, 75.747681%)" offset="0.363281"/>
          <stop stopOpacity="1" stopColor="rgb(28.573608%, 33.808899%, 75.840759%)" offset="0.367188"/>
          <stop stopOpacity="1" stopColor="rgb(28.903198%, 33.863831%, 75.933838%)" offset="0.371094"/>
          <stop stopOpacity="1" stopColor="rgb(29.234314%, 33.920288%, 76.026917%)" offset="0.375"/>
          <stop stopOpacity="1" stopColor="rgb(29.563904%, 33.97522%, 76.118469%)" offset="0.378906"/>
          <stop stopOpacity="1" stopColor="rgb(29.89502%, 34.031677%, 76.211548%)" offset="0.382813"/>
          <stop stopOpacity="1" stopColor="rgb(30.224609%, 34.086609%, 76.304626%)" offset="0.386719"/>
          <stop stopOpacity="1" stopColor="rgb(30.555725%, 34.143066%, 76.397705%)" offset="0.390625"/>
          <stop stopOpacity="1" stopColor="rgb(30.885315%, 34.197998%, 76.490784%)" offset="0.394531"/>
          <stop stopOpacity="1" stopColor="rgb(31.214905%, 34.254456%, 76.583862%)" offset="0.398438"/>
          <stop stopOpacity="1" stopColor="rgb(31.544495%, 34.309387%, 76.676941%)" offset="0.402344"/>
          <stop stopOpacity="1" stopColor="rgb(31.87561%, 34.365845%, 76.77002%)" offset="0.40625"/>
          <stop stopOpacity="1" stopColor="rgb(32.2052%, 34.420776%, 76.861572%)" offset="0.410156"/>
          <stop stopOpacity="1" stopColor="rgb(32.536316%, 34.477234%, 76.954651%)" offset="0.414063"/>
          <stop stopOpacity="1" stopColor="rgb(32.865906%, 34.532166%, 77.047729%)" offset="0.417969"/>
          <stop stopOpacity="1" stopColor="rgb(33.197021%, 34.587097%, 77.140808%)" offset="0.421875"/>
          <stop stopOpacity="1" stopColor="rgb(33.526611%, 34.642029%, 77.233887%)" offset="0.425781"/>
          <stop stopOpacity="1" stopColor="rgb(33.856201%, 34.698486%, 77.326965%)" offset="0.429688"/>
          <stop stopOpacity="1" stopColor="rgb(34.185791%, 34.753418%, 77.420044%)" offset="0.433594"/>
          <stop stopOpacity="1" stopColor="rgb(34.516907%, 34.809875%, 77.513123%)" offset="0.4375"/>
          <stop stopOpacity="1" stopColor="rgb(34.846497%, 34.864807%, 77.606201%)" offset="0.441406"/>
          <stop stopOpacity="1" stopColor="rgb(35.177612%, 34.921265%, 77.69928%)" offset="0.445313"/>
          <stop stopOpacity="1" stopColor="rgb(35.507202%, 34.976196%, 77.790833%)" offset="0.449219"/>
          <stop stopOpacity="1" stopColor="rgb(35.836792%, 35.032654%, 77.883911%)" offset="0.453125"/>
          <stop stopOpacity="1" stopColor="rgb(36.166382%, 35.087585%, 77.97699%)" offset="0.457031"/>
          <stop stopOpacity="1" stopColor="rgb(36.497498%, 35.144043%, 78.070068%)" offset="0.460938"/>
          <stop stopOpacity="1" stopColor="rgb(36.827087%, 35.198975%, 78.163147%)" offset="0.464844"/>
          <stop stopOpacity="1" stopColor="rgb(37.158203%, 35.253906%, 78.256226%)" offset="0.46875"/>
          <stop stopOpacity="1" stopColor="rgb(37.487793%, 35.308838%, 78.349304%)" offset="0.472656"/>
          <stop stopOpacity="1" stopColor="rgb(37.818909%, 35.365295%, 78.442383%)" offset="0.476563"/>
          <stop stopOpacity="1" stopColor="rgb(38.148499%, 35.420227%, 78.533936%)" offset="0.480469"/>
          <stop stopOpacity="1" stopColor="rgb(38.478088%, 35.476685%, 78.627014%)" offset="0.484375"/>
          <stop stopOpacity="1" stopColor="rgb(38.807678%, 35.531616%, 78.720093%)" offset="0.488281"/>
          <stop stopOpacity="1" stopColor="rgb(39.138794%, 35.588074%, 78.813171%)" offset="0.492188"/>
          <stop stopOpacity="1" stopColor="rgb(39.468384%, 35.643005%, 78.90625%)" offset="0.496094"/>
          <stop stopOpacity="1" stopColor="rgb(39.7995%, 35.699463%, 78.999329%)" offset="0.5"/>
          <stop stopOpacity="1" stopColor="rgb(40.129089%, 35.754395%, 79.092407%)" offset="0.503906"/>
          <stop stopOpacity="1" stopColor="rgb(40.460205%, 35.810852%, 79.185486%)" offset="0.507813"/>
          <stop stopOpacity="1" stopColor="rgb(40.789795%, 35.865784%, 79.277039%)" offset="0.511719"/>
          <stop stopOpacity="1" stopColor="rgb(41.119385%, 35.922241%, 79.370117%)" offset="0.515625"/>
          <stop stopOpacity="1" stopColor="rgb(41.448975%, 35.977173%, 79.463196%)" offset="0.519531"/>
          <stop stopOpacity="1" stopColor="rgb(41.78009%, 36.032104%, 79.556274%)" offset="0.523438"/>
          <stop stopOpacity="1" stopColor="rgb(42.10968%, 36.087036%, 79.649353%)" offset="0.527344"/>
          <stop stopOpacity="1" stopColor="rgb(42.440796%, 36.143494%, 79.742432%)" offset="0.53125"/>
          <stop stopOpacity="1" stopColor="rgb(42.770386%, 36.198425%, 79.83551%)" offset="0.535156"/>
          <stop stopOpacity="1" stopColor="rgb(43.101501%, 36.254883%, 79.928589%)" offset="0.539063"/>
          <stop stopOpacity="1" stopColor="rgb(43.431091%, 36.309814%, 80.021667%)" offset="0.542969"/>
          <stop stopOpacity="1" stopColor="rgb(43.760681%, 36.366272%, 80.114746%)" offset="0.546875"/>
          <stop stopOpacity="1" stopColor="rgb(44.090271%, 36.421204%, 80.206299%)" offset="0.550781"/>
          <stop stopOpacity="1" stopColor="rgb(44.421387%, 36.477661%, 80.299377%)" offset="0.554688"/>
          <stop stopOpacity="1" stopColor="rgb(44.750977%, 36.532593%, 80.392456%)" offset="0.558594"/>
          <stop stopOpacity="1" stopColor="rgb(45.082092%, 36.58905%, 80.485535%)" offset="0.5625"/>
          <stop stopOpacity="1" stopColor="rgb(45.411682%, 36.643982%, 80.578613%)" offset="0.566406"/>
          <stop stopOpacity="1" stopColor="rgb(45.741272%, 36.698914%, 80.671692%)" offset="0.570313"/>
          <stop stopOpacity="1" stopColor="rgb(46.070862%, 36.753845%, 80.764771%)" offset="0.574219"/>
          <stop stopOpacity="1" stopColor="rgb(46.401978%, 36.810303%, 80.857849%)" offset="0.578125"/>
          <stop stopOpacity="1" stopColor="rgb(46.731567%, 36.865234%, 80.949402%)" offset="0.582031"/>
          <stop stopOpacity="1" stopColor="rgb(47.062683%, 36.921692%, 81.04248%)" offset="0.585938"/>
          <stop stopOpacity="1" stopColor="rgb(47.392273%, 36.976624%, 81.135559%)" offset="0.589844"/>
          <stop stopOpacity="1" stopColor="rgb(47.723389%, 37.033081%, 81.228638%)" offset="0.59375"/>
          <stop stopOpacity="1" stopColor="rgb(48.052979%, 37.088013%, 81.321716%)" offset="0.597656"/>
          <stop stopOpacity="1" stopColor="rgb(48.382568%, 37.14447%, 81.414795%)" offset="0.601562"/>
          <stop stopOpacity="1" stopColor="rgb(48.712158%, 37.199402%, 81.507874%)" offset="0.605469"/>
          <stop stopOpacity="1" stopColor="rgb(49.043274%, 37.255859%, 81.600952%)" offset="0.609375"/>
          <stop stopOpacity="1" stopColor="rgb(49.372864%, 37.310791%, 81.694031%)" offset="0.613281"/>
          <stop stopOpacity="1" stopColor="rgb(49.703979%, 37.367249%, 81.787109%)" offset="0.617188"/>
          <stop stopOpacity="1" stopColor="rgb(50.033569%, 37.42218%, 81.878662%)" offset="0.621094"/>
          <stop stopOpacity="1" stopColor="rgb(50.364685%, 37.477112%, 81.971741%)" offset="0.625"/>
          <stop stopOpacity="1" stopColor="rgb(50.694275%, 37.532043%, 82.064819%)" offset="0.628906"/>
          <stop stopOpacity="1" stopColor="rgb(51.023865%, 37.588501%, 82.157898%)" offset="0.632813"/>
          <stop stopOpacity="1" stopColor="rgb(51.353455%, 37.643433%, 82.250977%)" offset="0.636719"/>
          <stop stopOpacity="1" stopColor="rgb(51.68457%, 37.69989%, 82.344055%)" offset="0.640625"/>
          <stop stopOpacity="1" stopColor="rgb(52.01416%, 37.754822%, 82.437134%)" offset="0.644531"/>
          <stop stopOpacity="1" stopColor="rgb(52.345276%, 37.811279%, 82.530212%)" offset="0.648438"/>
          <stop stopOpacity="1" stopColor="rgb(52.674866%, 37.866211%, 82.621765%)" offset="0.652344"/>
          <stop stopOpacity="1" stopColor="rgb(53.004456%, 37.922668%, 82.714844%)" offset="0.65625"/>
          <stop stopOpacity="1" stopColor="rgb(53.334045%, 37.9776%, 82.807922%)" offset="0.660156"/>
          <stop stopOpacity="1" stopColor="rgb(53.665161%, 38.034058%, 82.901001%)" offset="0.664062"/>
          <stop stopOpacity="1" stopColor="rgb(53.994751%, 38.088989%, 82.99408%)" offset="0.667969"/>
          <stop stopOpacity="1" stopColor="rgb(54.325867%, 38.143921%, 83.087158%)" offset="0.671875"/>
          <stop stopOpacity="1" stopColor="rgb(54.655457%, 38.198853%, 83.180237%)" offset="0.675781"/>
          <stop stopOpacity="1" stopColor="rgb(54.986572%, 38.25531%, 83.273315%)" offset="0.679688"/>
          <stop stopOpacity="1" stopColor="rgb(55.316162%, 38.310242%, 83.364868%)" offset="0.683594"/>
          <stop stopOpacity="1" stopColor="rgb(55.645752%, 38.366699%, 83.457947%)" offset="0.6875"/>
          <stop stopOpacity="1" stopColor="rgb(55.975342%, 38.421631%, 83.551025%)" offset="0.691406"/>
          <stop stopOpacity="1" stopColor="rgb(56.306458%, 38.478088%, 83.644104%)" offset="0.695312"/>
          <stop stopOpacity="1" stopColor="rgb(56.636047%, 38.53302%, 83.737183%)" offset="0.699219"/>
          <stop stopOpacity="1" stopColor="rgb(56.967163%, 38.589478%, 83.830261%)" offset="0.703125"/>
          <stop stopOpacity="1" stopColor="rgb(57.296753%, 38.644409%, 83.92334%)" offset="0.707031"/>
          <stop stopOpacity="1" stopColor="rgb(57.627869%, 38.700867%, 84.016418%)" offset="0.710938"/>
          <stop stopOpacity="1" stopColor="rgb(57.957458%, 38.755798%, 84.109497%)" offset="0.714844"/>
          <stop stopOpacity="1" stopColor="rgb(58.287048%, 38.812256%, 84.202576%)" offset="0.71875"/>
          <stop stopOpacity="1" stopColor="rgb(58.616638%, 38.867188%, 84.294128%)" offset="0.722656"/>
          <stop stopOpacity="1" stopColor="rgb(58.947754%, 38.922119%, 84.387207%)" offset="0.726563"/>
          <stop stopOpacity="1" stopColor="rgb(59.277344%, 38.977051%, 84.480286%)" offset="0.730469"/>
          <stop stopOpacity="1" stopColor="rgb(59.608459%, 39.033508%, 84.573364%)" offset="0.734375"/>
          <stop stopOpacity="1" stopColor="rgb(59.938049%, 39.08844%, 84.666443%)" offset="0.738281"/>
          <stop stopOpacity="1" stopColor="rgb(60.267639%, 39.144897%, 84.759521%)" offset="0.742188"/>
          <stop stopOpacity="1" stopColor="rgb(60.597229%, 39.199829%, 84.8526%)" offset="0.746094"/>
          <stop stopOpacity="1" stopColor="rgb(60.928345%, 39.256287%, 84.945679%)" offset="0.75"/>
          <stop stopOpacity="1" stopColor="rgb(61.257935%, 39.311218%, 85.037231%)" offset="0.753906"/>
          <stop stopOpacity="1" stopColor="rgb(61.58905%, 39.367676%, 85.13031%)" offset="0.757813"/>
          <stop stopOpacity="1" stopColor="rgb(61.91864%, 39.422607%, 85.223389%)" offset="0.761719"/>
          <stop stopOpacity="1" stopColor="rgb(62.249756%, 39.479065%, 85.316467%)" offset="0.765625"/>
          <stop stopOpacity="1" stopColor="rgb(62.579346%, 39.533997%, 85.409546%)" offset="0.769531"/>
          <stop stopOpacity="1" stopColor="rgb(62.908936%, 39.588928%, 85.502625%)" offset="0.773438"/>
          <stop stopOpacity="1" stopColor="rgb(63.238525%, 39.64386%, 85.595703%)" offset="0.777344"/>
          <stop stopOpacity="1" stopColor="rgb(63.569641%, 39.700317%, 85.688782%)" offset="0.78125"/>
          <stop stopOpacity="1" stopColor="rgb(63.899231%, 39.755249%, 85.78186%)" offset="0.785156"/>
          <stop stopOpacity="1" stopColor="rgb(64.230347%, 39.811707%, 85.874939%)" offset="0.789063"/>
          <stop stopOpacity="1" stopColor="rgb(64.559937%, 39.866638%, 85.966492%)" offset="0.792969"/>
          <stop stopOpacity="1" stopColor="rgb(64.891052%, 39.923096%, 86.05957%)" offset="0.796875"/>
          <stop stopOpacity="1" stopColor="rgb(65.220642%, 39.978027%, 86.152649%)" offset="0.800781"/>
          <stop stopOpacity="1" stopColor="rgb(65.550232%, 40.034485%, 86.245728%)" offset="0.804688"/>
          <stop stopOpacity="1" stopColor="rgb(65.879822%, 40.089417%, 86.338806%)" offset="0.808594"/>
          <stop stopOpacity="1" stopColor="rgb(66.210938%, 40.145874%, 86.431885%)" offset="0.8125"/>
          <stop stopOpacity="1" stopColor="rgb(66.540527%, 40.200806%, 86.524963%)" offset="0.816406"/>
          <stop stopOpacity="1" stopColor="rgb(66.871643%, 40.257263%, 86.618042%)" offset="0.820312"/>
          <stop stopOpacity="1" stopColor="rgb(67.201233%, 40.312195%, 86.709595%)" offset="0.824219"/>
          <stop stopOpacity="1" stopColor="rgb(67.530823%, 40.367126%, 86.802673%)" offset="0.828125"/>
          <stop stopOpacity="1" stopColor="rgb(67.860413%, 40.422058%, 86.895752%)" offset="0.832031"/>
          <stop stopOpacity="1" stopColor="rgb(68.191528%, 40.478516%, 86.988831%)" offset="0.835938"/>
          <stop stopOpacity="1" stopColor="rgb(68.521118%, 40.533447%, 87.081909%)" offset="0.839844"/>
          <stop stopOpacity="1" stopColor="rgb(68.852234%, 40.589905%, 87.174988%)" offset="0.84375"/>
          <stop stopOpacity="1" stopColor="rgb(69.181824%, 40.644836%, 87.268066%)" offset="0.847656"/>
          <stop stopOpacity="1" stopColor="rgb(69.512939%, 40.701294%, 87.361145%)" offset="0.851563"/>
          <stop stopOpacity="1" stopColor="rgb(69.842529%, 40.756226%, 87.452698%)" offset="0.855469"/>
          <stop stopOpacity="1" stopColor="rgb(70.172119%, 40.812683%, 87.545776%)" offset="0.859375"/>
          <stop stopOpacity="1" stopColor="rgb(70.501709%, 40.867615%, 87.638855%)" offset="0.863281"/>
          <stop stopOpacity="1" stopColor="rgb(70.832825%, 40.924072%, 87.731934%)" offset="0.867188"/>
          <stop stopOpacity="1" stopColor="rgb(71.162415%, 40.979004%, 87.825012%)" offset="0.871094"/>
          <stop stopOpacity="1" stopColor="rgb(71.49353%, 41.033936%, 87.918091%)" offset="0.875"/>
          <stop stopOpacity="1" stopColor="rgb(71.82312%, 41.088867%, 88.011169%)" offset="0.878906"/>
          <stop stopOpacity="1" stopColor="rgb(72.154236%, 41.145325%, 88.104248%)" offset="0.882813"/>
          <stop stopOpacity="1" stopColor="rgb(72.483826%, 41.200256%, 88.197327%)" offset="0.886719"/>
          <stop stopOpacity="1" stopColor="rgb(72.813416%, 41.256714%, 88.290405%)" offset="0.890625"/>
          <stop stopOpacity="1" stopColor="rgb(73.143005%, 41.311646%, 88.381958%)" offset="0.894531"/>
          <stop stopOpacity="1" stopColor="rgb(73.474121%, 41.368103%, 88.475037%)" offset="0.898438"/>
          <stop stopOpacity="1" stopColor="rgb(73.803711%, 41.423035%, 88.568115%)" offset="0.902344"/>
          <stop stopOpacity="1" stopColor="rgb(74.134827%, 41.479492%, 88.661194%)" offset="0.90625"/>
          <stop stopOpacity="1" stopColor="rgb(74.464417%, 41.534424%, 88.754272%)" offset="0.910156"/>
          <stop stopOpacity="1" stopColor="rgb(74.794006%, 41.590881%, 88.847351%)" offset="0.914063"/>
          <stop stopOpacity="1" stopColor="rgb(75.123596%, 41.645813%, 88.94043%)" offset="0.917969"/>
          <stop stopOpacity="1" stopColor="rgb(75.454712%, 41.702271%, 89.033508%)" offset="0.921875"/>
          <stop stopOpacity="1" stopColor="rgb(75.784302%, 41.757202%, 89.125061%)" offset="0.925781"/>
          <stop stopOpacity="1" stopColor="rgb(76.115417%, 41.812134%, 89.21814%)" offset="0.929688"/>
          <stop stopOpacity="1" stopColor="rgb(76.445007%, 41.867065%, 89.311218%)" offset="0.933594"/>
          <stop stopOpacity="1" stopColor="rgb(76.776123%, 41.923523%, 89.404297%)" offset="0.9375"/>
          <stop stopOpacity="1" stopColor="rgb(77.105713%, 41.978455%, 89.497375%)" offset="0.941406"/>
          <stop stopOpacity="1" stopColor="rgb(77.435303%, 42.034912%, 89.590454%)" offset="0.945312"/>
          <stop stopOpacity="1" stopColor="rgb(77.764893%, 42.089844%, 89.683533%)" offset="0.949219"/>
          <stop stopOpacity="1" stopColor="rgb(78.096008%, 42.146301%, 89.776611%)" offset="0.953125"/>
          <stop stopOpacity="1" stopColor="rgb(78.425598%, 42.201233%, 89.86969%)" offset="0.957031"/>
          <stop stopOpacity="1" stopColor="rgb(78.756714%, 42.25769%, 89.962769%)" offset="0.960938"/>
          <stop stopOpacity="1" stopColor="rgb(79.086304%, 42.312622%, 90.054321%)" offset="0.964844"/>
          <stop stopOpacity="1" stopColor="rgb(79.417419%, 42.36908%, 90.1474%)" offset="0.96875"/>
          <stop stopOpacity="1" stopColor="rgb(79.507446%, 42.384338%, 90.17334%)" offset="1"/>
        </linearGradient>
        <clipPath id="7e3aa69e81">
          <path
            d="M 108.144531 271.492188 L 201.453125 271.492188 L 201.453125 364.800781 L 108.144531 364.800781 Z M 108.144531 271.492188 "
            clipRule="nonzero"/>
        </clipPath>
        <clipPath id="b307d783b7">
          <path
            d="M 154.800781 271.492188 C 129.035156 271.492188 108.144531 292.378906 108.144531 318.144531 C 108.144531 343.914062 129.035156 364.800781 154.800781 364.800781 C 180.566406 364.800781 201.453125 343.914062 201.453125 318.144531 C 201.453125 292.378906 180.566406 271.492188 154.800781 271.492188 Z M 154.800781 271.492188 "
            clipRule="nonzero"/>
        </clipPath>
        <linearGradient x1="-0.0000112653" gradientTransform="matrix(0.364487, 0, 0, 0.364487, 108.145373, 271.49132)"
                        y1="128" x2="255.999985" gradientUnits="userSpaceOnUse" y2="128" id="c1563d9744">
          <stop stopOpacity="1" stopColor="rgb(0%, 28.999329%, 67.799377%)" offset="0"/>
          <stop stopOpacity="1" stopColor="rgb(0.309753%, 29.051208%, 67.886353%)" offset="0.00390625"/>
          <stop stopOpacity="1" stopColor="rgb(0.621033%, 29.104614%, 67.974854%)" offset="0.0078125"/>
          <stop stopOpacity="1" stopColor="rgb(0.932312%, 29.156494%, 68.061829%)" offset="0.0117188"/>
          <stop stopOpacity="1" stopColor="rgb(1.243591%, 29.208374%, 68.148804%)" offset="0.015625"/>
          <stop stopOpacity="1" stopColor="rgb(1.553345%, 29.260254%, 68.235779%)" offset="0.0195312"/>
          <stop stopOpacity="1" stopColor="rgb(1.864624%, 29.31366%, 68.32428%)" offset="0.0234375"/>
          <stop stopOpacity="1" stopColor="rgb(2.175903%, 29.36554%, 68.411255%)" offset="0.0273438"/>
          <stop stopOpacity="1" stopColor="rgb(2.487183%, 29.417419%, 68.499756%)" offset="0.03125"/>
          <stop stopOpacity="1" stopColor="rgb(2.796936%, 29.469299%, 68.586731%)" offset="0.0351562"/>
          <stop stopOpacity="1" stopColor="rgb(3.108215%, 29.522705%, 68.673706%)" offset="0.0390625"/>
          <stop stopOpacity="1" stopColor="rgb(3.419495%, 29.574585%, 68.760681%)" offset="0.0429688"/>
          <stop stopOpacity="1" stopColor="rgb(3.730774%, 29.627991%, 68.849182%)" offset="0.046875"/>
          <stop stopOpacity="1" stopColor="rgb(4.040527%, 29.679871%, 68.936157%)" offset="0.0507812"/>
          <stop stopOpacity="1" stopColor="rgb(4.351807%, 29.73175%, 69.024658%)" offset="0.0546875"/>
          <stop stopOpacity="1" stopColor="rgb(4.663086%, 29.78363%, 69.111633%)" offset="0.0585938"/>
          <stop stopOpacity="1" stopColor="rgb(4.974365%, 29.837036%, 69.198608%)" offset="0.0625"/>
          <stop stopOpacity="1" stopColor="rgb(5.284119%, 29.888916%, 69.285583%)" offset="0.0664062"/>
          <stop stopOpacity="1" stopColor="rgb(5.595398%, 29.940796%, 69.374084%)" offset="0.0703125"/>
          <stop stopOpacity="1" stopColor="rgb(5.906677%, 29.992676%, 69.46106%)" offset="0.0742188"/>
          <stop stopOpacity="1" stopColor="rgb(6.217957%, 30.046082%, 69.549561%)" offset="0.078125"/>
          <stop stopOpacity="1" stopColor="rgb(6.529236%, 30.097961%, 69.636536%)" offset="0.0820312"/>
          <stop stopOpacity="1" stopColor="rgb(6.840515%, 30.151367%, 69.723511%)" offset="0.0859375"/>
          <stop stopOpacity="1" stopColor="rgb(7.150269%, 30.203247%, 69.810486%)" offset="0.0898438"/>
          <stop stopOpacity="1" stopColor="rgb(7.461548%, 30.255127%, 69.898987%)" offset="0.09375"/>
          <stop stopOpacity="1" stopColor="rgb(7.772827%, 30.307007%, 69.985962%)" offset="0.0976562"/>
          <stop stopOpacity="1" stopColor="rgb(8.084106%, 30.360413%, 70.074463%)" offset="0.101562"/>
          <stop stopOpacity="1" stopColor="rgb(8.39386%, 30.412292%, 70.161438%)" offset="0.105469"/>
          <stop stopOpacity="1" stopColor="rgb(8.705139%, 30.464172%, 70.249939%)" offset="0.109375"/>
          <stop stopOpacity="1" stopColor="rgb(9.016418%, 30.516052%, 70.336914%)" offset="0.113281"/>
          <stop stopOpacity="1" stopColor="rgb(9.327698%, 30.569458%, 70.423889%)" offset="0.117188"/>
          <stop stopOpacity="1" stopColor="rgb(9.637451%, 30.621338%, 70.510864%)" offset="0.121094"/>
          <stop stopOpacity="1" stopColor="rgb(9.94873%, 30.674744%, 70.599365%)" offset="0.125"/>
          <stop stopOpacity="1" stopColor="rgb(10.26001%, 30.726624%, 70.68634%)" offset="0.128906"/>
          <stop stopOpacity="1" stopColor="rgb(10.571289%, 30.778503%, 70.774841%)" offset="0.132812"/>
          <stop stopOpacity="1" stopColor="rgb(10.881042%, 30.830383%, 70.861816%)" offset="0.136719"/>
          <stop stopOpacity="1" stopColor="rgb(11.192322%, 30.883789%, 70.948792%)" offset="0.140625"/>
          <stop stopOpacity="1" stopColor="rgb(11.503601%, 30.935669%, 71.035767%)" offset="0.144531"/>
          <stop stopOpacity="1" stopColor="rgb(11.81488%, 30.987549%, 71.124268%)" offset="0.148438"/>
          <stop stopOpacity="1" stopColor="rgb(12.12616%, 31.039429%, 71.211243%)" offset="0.152344"/>
          <stop stopOpacity="1" stopColor="rgb(12.437439%, 31.092834%, 71.299744%)" offset="0.15625"/>
          <stop stopOpacity="1" stopColor="rgb(12.747192%, 31.144714%, 71.386719%)" offset="0.160156"/>
          <stop stopOpacity="1" stopColor="rgb(13.058472%, 31.19812%, 71.473694%)" offset="0.164062"/>
          <stop stopOpacity="1" stopColor="rgb(13.369751%, 31.25%, 71.560669%)" offset="0.167969"/>
          <stop stopOpacity="1" stopColor="rgb(13.68103%, 31.30188%, 71.64917%)" offset="0.171875"/>
          <stop stopOpacity="1" stopColor="rgb(13.990784%, 31.35376%, 71.736145%)" offset="0.175781"/>
          <stop stopOpacity="1" stopColor="rgb(14.302063%, 31.407166%, 71.824646%)" offset="0.179688"/>
          <stop stopOpacity="1" stopColor="rgb(14.613342%, 31.459045%, 71.911621%)" offset="0.183594"/>
          <stop stopOpacity="1" stopColor="rgb(14.924622%, 31.512451%, 71.998596%)" offset="0.1875"/>
          <stop stopOpacity="1" stopColor="rgb(15.234375%, 31.564331%, 72.085571%)" offset="0.191406"/>
          <stop stopOpacity="1" stopColor="rgb(15.545654%, 31.616211%, 72.174072%)" offset="0.195312"/>
          <stop stopOpacity="1" stopColor="rgb(15.856934%, 31.668091%, 72.261047%)" offset="0.199219"/>
          <stop stopOpacity="1" stopColor="rgb(16.168213%, 31.721497%, 72.349548%)" offset="0.203125"/>
          <stop stopOpacity="1" stopColor="rgb(16.477966%, 31.773376%, 72.436523%)" offset="0.207031"/>
          <stop stopOpacity="1" stopColor="rgb(16.789246%, 31.825256%, 72.523499%)" offset="0.210938"/>
          <stop stopOpacity="1" stopColor="rgb(17.100525%, 31.877136%, 72.610474%)" offset="0.214844"/>
          <stop stopOpacity="1" stopColor="rgb(17.411804%, 31.930542%, 72.698975%)" offset="0.21875"/>
          <stop stopOpacity="1" stopColor="rgb(17.723083%, 31.982422%, 72.78595%)" offset="0.222656"/>
          <stop stopOpacity="1" stopColor="rgb(18.034363%, 32.035828%, 72.874451%)" offset="0.226562"/>
          <stop stopOpacity="1" stopColor="rgb(18.344116%, 32.087708%, 72.961426%)" offset="0.230469"/>
          <stop stopOpacity="1" stopColor="rgb(18.655396%, 32.139587%, 73.049927%)" offset="0.234375"/>
          <stop stopOpacity="1" stopColor="rgb(18.966675%, 32.191467%, 73.136902%)" offset="0.238281"/>
          <stop stopOpacity="1" stopColor="rgb(19.277954%, 32.244873%, 73.223877%)" offset="0.242188"/>
          <stop stopOpacity="1" stopColor="rgb(19.587708%, 32.296753%, 73.310852%)" offset="0.246094"/>
          <stop stopOpacity="1" stopColor="rgb(19.898987%, 32.348633%, 73.399353%)" offset="0.25"/>
          <stop stopOpacity="1" stopColor="rgb(20.210266%, 32.400513%, 73.486328%)" offset="0.253906"/>
          <stop stopOpacity="1" stopColor="rgb(20.521545%, 32.453918%, 73.574829%)" offset="0.257812"/>
          <stop stopOpacity="1" stopColor="rgb(20.831299%, 32.505798%, 73.661804%)" offset="0.261719"/>
          <stop stopOpacity="1" stopColor="rgb(21.142578%, 32.559204%, 73.748779%)" offset="0.265625"/>
          <stop stopOpacity="1" stopColor="rgb(21.453857%, 32.611084%, 73.835754%)" offset="0.269531"/>
          <stop stopOpacity="1" stopColor="rgb(21.765137%, 32.662964%, 73.924255%)" offset="0.273438"/>
          <stop stopOpacity="1" stopColor="rgb(22.07489%, 32.714844%, 74.01123%)" offset="0.277344"/>
          <stop stopOpacity="1" stopColor="rgb(22.386169%, 32.76825%, 74.099731%)" offset="0.28125"/>
          <stop stopOpacity="1" stopColor="rgb(22.697449%, 32.820129%, 74.186707%)" offset="0.285156"/>
          <stop stopOpacity="1" stopColor="rgb(23.008728%, 32.872009%, 74.273682%)" offset="0.289062"/>
          <stop stopOpacity="1" stopColor="rgb(23.318481%, 32.923889%, 74.360657%)" offset="0.292969"/>
          <stop stopOpacity="1" stopColor="rgb(23.629761%, 32.977295%, 74.449158%)" offset="0.296875"/>
          <stop stopOpacity="1" stopColor="rgb(23.94104%, 33.029175%, 74.536133%)" offset="0.300781"/>
          <stop stopOpacity="1" stopColor="rgb(24.252319%, 33.082581%, 74.624634%)" offset="0.304688"/>
          <stop stopOpacity="1" stopColor="rgb(24.563599%, 33.13446%, 74.711609%)" offset="0.308594"/>
          <stop stopOpacity="1" stopColor="rgb(24.874878%, 33.18634%, 74.798584%)" offset="0.3125"/>
          <stop stopOpacity="1" stopColor="rgb(25.184631%, 33.23822%, 74.885559%)" offset="0.316406"/>
          <stop stopOpacity="1" stopColor="rgb(25.495911%, 33.291626%, 74.97406%)" offset="0.320312"/>
          <stop stopOpacity="1" stopColor="rgb(25.80719%, 33.343506%, 75.061035%)" offset="0.324219"/>
          <stop stopOpacity="1" stopColor="rgb(26.118469%, 33.395386%, 75.149536%)" offset="0.328125"/>
          <stop stopOpacity="1" stopColor="rgb(26.428223%, 33.447266%, 75.236511%)" offset="0.332031"/>
          <stop stopOpacity="1" stopColor="rgb(26.739502%, 33.500671%, 75.323486%)" offset="0.335938"/>
          <stop stopOpacity="1" stopColor="rgb(27.050781%, 33.552551%, 75.410461%)" offset="0.339844"/>
          <stop stopOpacity="1" stopColor="rgb(27.362061%, 33.605957%, 75.498962%)" offset="0.34375"/>
          <stop stopOpacity="1" stopColor="rgb(27.671814%, 33.657837%, 75.585938%)" offset="0.347656"/>
          <stop stopOpacity="1" stopColor="rgb(27.983093%, 33.709717%, 75.674438%)" offset="0.351562"/>
          <stop stopOpacity="1" stopColor="rgb(28.294373%, 33.761597%, 75.761414%)" offset="0.355469"/>
          <stop stopOpacity="1" stopColor="rgb(28.605652%, 33.815002%, 75.849915%)" offset="0.359375"/>
          <stop stopOpacity="1" stopColor="rgb(28.915405%, 33.866882%, 75.93689%)" offset="0.363281"/>
          <stop stopOpacity="1" stopColor="rgb(29.226685%, 33.920288%, 76.023865%)" offset="0.367188"/>
          <stop stopOpacity="1" stopColor="rgb(29.537964%, 33.972168%, 76.11084%)" offset="0.371094"/>
          <stop stopOpacity="1" stopColor="rgb(29.849243%, 34.024048%, 76.199341%)" offset="0.375"/>
          <stop stopOpacity="1" stopColor="rgb(30.160522%, 34.075928%, 76.286316%)" offset="0.378906"/>
          <stop stopOpacity="1" stopColor="rgb(30.471802%, 34.129333%, 76.374817%)" offset="0.382812"/>
          <stop stopOpacity="1" stopColor="rgb(30.781555%, 34.181213%, 76.461792%)" offset="0.386719"/>
          <stop stopOpacity="1" stopColor="rgb(31.092834%, 34.233093%, 76.548767%)" offset="0.390625"/>
          <stop stopOpacity="1" stopColor="rgb(31.404114%, 34.284973%, 76.635742%)" offset="0.394531"/>
          <stop stopOpacity="1" stopColor="rgb(31.715393%, 34.338379%, 76.724243%)" offset="0.398438"/>
          <stop stopOpacity="1" stopColor="rgb(32.025146%, 34.390259%, 76.811218%)" offset="0.402344"/>
          <stop stopOpacity="1" stopColor="rgb(32.336426%, 34.443665%, 76.899719%)" offset="0.40625"/>
          <stop stopOpacity="1" stopColor="rgb(32.647705%, 34.495544%, 76.986694%)" offset="0.410156"/>
          <stop stopOpacity="1" stopColor="rgb(32.958984%, 34.547424%, 77.073669%)" offset="0.414062"/>
          <stop stopOpacity="1" stopColor="rgb(33.268738%, 34.599304%, 77.160645%)" offset="0.417969"/>
          <stop stopOpacity="1" stopColor="rgb(33.580017%, 34.65271%, 77.249146%)" offset="0.421875"/>
          <stop stopOpacity="1" stopColor="rgb(33.891296%, 34.70459%, 77.336121%)" offset="0.425781"/>
          <stop stopOpacity="1" stopColor="rgb(34.202576%, 34.75647%, 77.424622%)" offset="0.429688"/>
          <stop stopOpacity="1" stopColor="rgb(34.512329%, 34.80835%, 77.511597%)" offset="0.433594"/>
          <stop stopOpacity="1" stopColor="rgb(34.823608%, 34.861755%, 77.598572%)" offset="0.4375"/>
          <stop stopOpacity="1" stopColor="rgb(35.134888%, 34.913635%, 77.685547%)" offset="0.441406"/>
          <stop stopOpacity="1" stopColor="rgb(35.446167%, 34.967041%, 77.774048%)" offset="0.445312"/>
          <stop stopOpacity="1" stopColor="rgb(35.757446%, 35.018921%, 77.861023%)" offset="0.449219"/>
          <stop stopOpacity="1" stopColor="rgb(36.068726%, 35.070801%, 77.949524%)" offset="0.453125"/>
          <stop stopOpacity="1" stopColor="rgb(36.378479%, 35.122681%, 78.036499%)" offset="0.457031"/>
          <stop stopOpacity="1" stopColor="rgb(36.689758%, 35.176086%, 78.123474%)" offset="0.460938"/>
          <stop stopOpacity="1" stopColor="rgb(37.001038%, 35.227966%, 78.210449%)" offset="0.464844"/>
          <stop stopOpacity="1" stopColor="rgb(37.312317%, 35.279846%, 78.29895%)" offset="0.46875"/>
          <stop stopOpacity="1" stopColor="rgb(37.62207%, 35.331726%, 78.385925%)" offset="0.472656"/>
          <stop stopOpacity="1" stopColor="rgb(37.93335%, 35.385132%, 78.474426%)" offset="0.476562"/>
          <stop stopOpacity="1" stopColor="rgb(38.244629%, 35.437012%, 78.561401%)" offset="0.480469"/>
          <stop stopOpacity="1" stopColor="rgb(38.555908%, 35.490417%, 78.649902%)" offset="0.484375"/>
          <stop stopOpacity="1" stopColor="rgb(38.865662%, 35.542297%, 78.736877%)" offset="0.488281"/>
          <stop stopOpacity="1" stopColor="rgb(39.176941%, 35.594177%, 78.823853%)" offset="0.492188"/>
          <stop stopOpacity="1" stopColor="rgb(39.48822%, 35.646057%, 78.910828%)" offset="0.496094"/>
          <stop stopOpacity="1" stopColor="rgb(39.7995%, 35.699463%, 78.999329%)" offset="0.5"/>
          <stop stopOpacity="1" stopColor="rgb(40.109253%, 35.751343%, 79.086304%)" offset="0.503906"/>
          <stop stopOpacity="1" stopColor="rgb(40.420532%, 35.803223%, 79.174805%)" offset="0.507812"/>
          <stop stopOpacity="1" stopColor="rgb(40.731812%, 35.855103%, 79.26178%)" offset="0.511719"/>
          <stop stopOpacity="1" stopColor="rgb(41.043091%, 35.908508%, 79.348755%)" offset="0.515625"/>
          <stop stopOpacity="1" stopColor="rgb(41.352844%, 35.960388%, 79.43573%)" offset="0.519531"/>
          <stop stopOpacity="1" stopColor="rgb(41.664124%, 36.013794%, 79.524231%)" offset="0.523437"/>
          <stop stopOpacity="1" stopColor="rgb(41.975403%, 36.065674%, 79.611206%)" offset="0.527344"/>
          <stop stopOpacity="1" stopColor="rgb(42.286682%, 36.117554%, 79.699707%)" offset="0.53125"/>
          <stop stopOpacity="1" stopColor="rgb(42.597961%, 36.169434%, 79.786682%)" offset="0.535156"/>
          <stop stopOpacity="1" stopColor="rgb(42.909241%, 36.222839%, 79.873657%)" offset="0.539062"/>
          <stop stopOpacity="1" stopColor="rgb(43.218994%, 36.274719%, 79.960632%)" offset="0.542969"/>
          <stop stopOpacity="1" stopColor="rgb(43.530273%, 36.326599%, 80.049133%)" offset="0.546875"/>
          <stop stopOpacity="1" stopColor="rgb(43.841553%, 36.378479%, 80.136108%)" offset="0.550781"/>
          <stop stopOpacity="1" stopColor="rgb(44.152832%, 36.431885%, 80.224609%)" offset="0.554688"/>
          <stop stopOpacity="1" stopColor="rgb(44.462585%, 36.483765%, 80.311584%)" offset="0.558594"/>
          <stop stopOpacity="1" stopColor="rgb(44.773865%, 36.53717%, 80.39856%)" offset="0.5625"/>
          <stop stopOpacity="1" stopColor="rgb(45.085144%, 36.58905%, 80.485535%)" offset="0.566406"/>
          <stop stopOpacity="1" stopColor="rgb(45.396423%, 36.64093%, 80.574036%)" offset="0.570312"/>
          <stop stopOpacity="1" stopColor="rgb(45.706177%, 36.69281%, 80.661011%)" offset="0.574219"/>
          <stop stopOpacity="1" stopColor="rgb(46.017456%, 36.746216%, 80.749512%)" offset="0.578125"/>
          <stop stopOpacity="1" stopColor="rgb(46.328735%, 36.798096%, 80.836487%)" offset="0.582031"/>
          <stop stopOpacity="1" stopColor="rgb(46.640015%, 36.851501%, 80.924988%)" offset="0.585938"/>
          <stop stopOpacity="1" stopColor="rgb(46.949768%, 36.903381%, 81.011963%)" offset="0.589844"/>
          <stop stopOpacity="1" stopColor="rgb(47.261047%, 36.955261%, 81.098938%)" offset="0.59375"/>
          <stop stopOpacity="1" stopColor="rgb(47.572327%, 37.007141%, 81.185913%)" offset="0.597656"/>
          <stop stopOpacity="1" stopColor="rgb(47.883606%, 37.060547%, 81.274414%)" offset="0.601562"/>
          <stop stopOpacity="1" stopColor="rgb(48.194885%, 37.112427%, 81.361389%)" offset="0.605469"/>
          <stop stopOpacity="1" stopColor="rgb(48.506165%, 37.164307%, 81.44989%)" offset="0.609375"/>
          <stop stopOpacity="1" stopColor="rgb(48.815918%, 37.216187%, 81.536865%)" offset="0.613281"/>
          <stop stopOpacity="1" stopColor="rgb(49.127197%, 37.269592%, 81.62384%)" offset="0.617187"/>
          <stop stopOpacity="1" stopColor="rgb(49.438477%, 37.321472%, 81.710815%)" offset="0.621094"/>
          <stop stopOpacity="1" stopColor="rgb(49.749756%, 37.374878%, 81.799316%)" offset="0.625"/>
          <stop stopOpacity="1" stopColor="rgb(50.059509%, 37.426758%, 81.886292%)" offset="0.628906"/>
          <stop stopOpacity="1" stopColor="rgb(50.370789%, 37.478638%, 81.974792%)" offset="0.632813"/>
          <stop stopOpacity="1" stopColor="rgb(50.682068%, 37.530518%, 82.061768%)" offset="0.636719"/>
          <stop stopOpacity="1" stopColor="rgb(50.993347%, 37.583923%, 82.148743%)" offset="0.640625"/>
          <stop stopOpacity="1" stopColor="rgb(51.303101%, 37.635803%, 82.235718%)" offset="0.644531"/>
          <stop stopOpacity="1" stopColor="rgb(51.61438%, 37.687683%, 82.324219%)" offset="0.648438"/>
          <stop stopOpacity="1" stopColor="rgb(51.925659%, 37.739563%, 82.411194%)" offset="0.652344"/>
          <stop stopOpacity="1" stopColor="rgb(52.236938%, 37.792969%, 82.499695%)" offset="0.65625"/>
          <stop stopOpacity="1" stopColor="rgb(52.546692%, 37.844849%, 82.58667%)" offset="0.660156"/>
          <stop stopOpacity="1" stopColor="rgb(52.857971%, 37.898254%, 82.673645%)" offset="0.664062"/>
          <stop stopOpacity="1" stopColor="rgb(53.16925%, 37.950134%, 82.76062%)" offset="0.667969"/>
          <stop stopOpacity="1" stopColor="rgb(53.48053%, 38.002014%, 82.849121%)" offset="0.671875"/>
          <stop stopOpacity="1" stopColor="rgb(53.791809%, 38.053894%, 82.936096%)" offset="0.675781"/>
          <stop stopOpacity="1" stopColor="rgb(54.103088%, 38.1073%, 83.024597%)" offset="0.679688"/>
          <stop stopOpacity="1" stopColor="rgb(54.412842%, 38.15918%, 83.111572%)" offset="0.683594"/>
          <stop stopOpacity="1" stopColor="rgb(54.724121%, 38.21106%, 83.198547%)" offset="0.6875"/>
          <stop stopOpacity="1" stopColor="rgb(55.0354%, 38.262939%, 83.285522%)" offset="0.691406"/>
          <stop stopOpacity="1" stopColor="rgb(55.34668%, 38.316345%, 83.374023%)" offset="0.695312"/>
          <stop stopOpacity="1" stopColor="rgb(55.656433%, 38.368225%, 83.460999%)" offset="0.699219"/>
          <stop stopOpacity="1" stopColor="rgb(55.967712%, 38.421631%, 83.5495%)" offset="0.703125"/>
          <stop stopOpacity="1" stopColor="rgb(56.278992%, 38.473511%, 83.636475%)" offset="0.707031"/>
          <stop stopOpacity="1" stopColor="rgb(56.590271%, 38.525391%, 83.724976%)" offset="0.710938"/>
          <stop stopOpacity="1" stopColor="rgb(56.900024%, 38.577271%, 83.811951%)" offset="0.714844"/>
          <stop stopOpacity="1" stopColor="rgb(57.211304%, 38.630676%, 83.898926%)" offset="0.71875"/>
          <stop stopOpacity="1" stopColor="rgb(57.522583%, 38.682556%, 83.985901%)" offset="0.722656"/>
          <stop stopOpacity="1" stopColor="rgb(57.833862%, 38.734436%, 84.074402%)" offset="0.726563"/>
          <stop stopOpacity="1" stopColor="rgb(58.143616%, 38.786316%, 84.161377%)" offset="0.730469"/>
          <stop stopOpacity="1" stopColor="rgb(58.454895%, 38.839722%, 84.249878%)" offset="0.734375"/>
          <stop stopOpacity="1" stopColor="rgb(58.766174%, 38.891602%, 84.336853%)" offset="0.738281"/>
          <stop stopOpacity="1" stopColor="rgb(59.077454%, 38.945007%, 84.423828%)" offset="0.742188"/>
          <stop stopOpacity="1" stopColor="rgb(59.387207%, 38.996887%, 84.510803%)" offset="0.746094"/>
          <stop stopOpacity="1" stopColor="rgb(59.698486%, 39.048767%, 84.599304%)" offset="0.75"/>
          <stop stopOpacity="1" stopColor="rgb(60.009766%, 39.100647%, 84.686279%)" offset="0.753906"/>
          <stop stopOpacity="1" stopColor="rgb(60.321045%, 39.154053%, 84.77478%)" offset="0.757812"/>
          <stop stopOpacity="1" stopColor="rgb(60.632324%, 39.205933%, 84.861755%)" offset="0.761719"/>
          <stop stopOpacity="1" stopColor="rgb(60.943604%, 39.259338%, 84.94873%)" offset="0.765625"/>
          <stop stopOpacity="1" stopColor="rgb(61.253357%, 39.311218%, 85.035706%)" offset="0.769531"/>
          <stop stopOpacity="1" stopColor="rgb(61.564636%, 39.363098%, 85.124207%)" offset="0.773437"/>
          <stop stopOpacity="1" stopColor="rgb(61.875916%, 39.414978%, 85.211182%)" offset="0.777344"/>
          <stop stopOpacity="1" stopColor="rgb(62.187195%, 39.468384%, 85.299683%)" offset="0.78125"/>
          <stop stopOpacity="1" stopColor="rgb(62.496948%, 39.520264%, 85.386658%)" offset="0.785156"/>
          <stop stopOpacity="1" stopColor="rgb(62.808228%, 39.572144%, 85.473633%)" offset="0.789062"/>
          <stop stopOpacity="1" stopColor="rgb(63.119507%, 39.624023%, 85.560608%)" offset="0.792969"/>
          <stop stopOpacity="1" stopColor="rgb(63.430786%, 39.677429%, 85.649109%)" offset="0.796875"/>
          <stop stopOpacity="1" stopColor="rgb(63.74054%, 39.729309%, 85.736084%)" offset="0.800781"/>
          <stop stopOpacity="1" stopColor="rgb(64.051819%, 39.782715%, 85.824585%)" offset="0.804688"/>
          <stop stopOpacity="1" stopColor="rgb(64.363098%, 39.834595%, 85.91156%)" offset="0.808594"/>
          <stop stopOpacity="1" stopColor="rgb(64.674377%, 39.886475%, 85.998535%)" offset="0.8125"/>
          <stop stopOpacity="1" stopColor="rgb(64.984131%, 39.938354%, 86.08551%)" offset="0.816406"/>
          <stop stopOpacity="1" stopColor="rgb(65.29541%, 39.99176%, 86.174011%)" offset="0.820312"/>
          <stop stopOpacity="1" stopColor="rgb(65.606689%, 40.04364%, 86.260986%)" offset="0.824219"/>
          <stop stopOpacity="1" stopColor="rgb(65.917969%, 40.09552%, 86.349487%)" offset="0.828125"/>
          <stop stopOpacity="1" stopColor="rgb(66.229248%, 40.1474%, 86.436462%)" offset="0.832031"/>
          <stop stopOpacity="1" stopColor="rgb(66.540527%, 40.200806%, 86.524963%)" offset="0.835938"/>
          <stop stopOpacity="1" stopColor="rgb(66.850281%, 40.252686%, 86.611938%)" offset="0.839844"/>
          <stop stopOpacity="1" stopColor="rgb(67.16156%, 40.306091%, 86.698914%)" offset="0.84375"/>
          <stop stopOpacity="1" stopColor="rgb(67.472839%, 40.357971%, 86.785889%)" offset="0.847656"/>
          <stop stopOpacity="1" stopColor="rgb(67.784119%, 40.409851%, 86.87439%)" offset="0.851562"/>
          <stop stopOpacity="1" stopColor="rgb(68.093872%, 40.461731%, 86.961365%)" offset="0.855469"/>
          <stop stopOpacity="1" stopColor="rgb(68.405151%, 40.515137%, 87.049866%)" offset="0.859375"/>
          <stop stopOpacity="1" stopColor="rgb(68.716431%, 40.567017%, 87.136841%)" offset="0.863281"/>
          <stop stopOpacity="1" stopColor="rgb(69.02771%, 40.618896%, 87.223816%)" offset="0.867187"/>
          <stop stopOpacity="1" stopColor="rgb(69.337463%, 40.670776%, 87.310791%)" offset="0.871094"/>
          <stop stopOpacity="1" stopColor="rgb(69.648743%, 40.724182%, 87.399292%)" offset="0.875"/>
          <stop stopOpacity="1" stopColor="rgb(69.960022%, 40.776062%, 87.486267%)" offset="0.878906"/>
          <stop stopOpacity="1" stopColor="rgb(70.271301%, 40.829468%, 87.574768%)" offset="0.882813"/>
          <stop stopOpacity="1" stopColor="rgb(70.581055%, 40.881348%, 87.661743%)" offset="0.886719"/>
          <stop stopOpacity="1" stopColor="rgb(70.892334%, 40.933228%, 87.748718%)" offset="0.890625"/>
          <stop stopOpacity="1" stopColor="rgb(71.203613%, 40.985107%, 87.835693%)" offset="0.894531"/>
          <stop stopOpacity="1" stopColor="rgb(71.514893%, 41.038513%, 87.924194%)" offset="0.898438"/>
          <stop stopOpacity="1" stopColor="rgb(71.826172%, 41.090393%, 88.011169%)" offset="0.902344"/>
          <stop stopOpacity="1" stopColor="rgb(72.137451%, 41.142273%, 88.09967%)" offset="0.90625"/>
          <stop stopOpacity="1" stopColor="rgb(72.447205%, 41.194153%, 88.186646%)" offset="0.910156"/>
          <stop stopOpacity="1" stopColor="rgb(72.758484%, 41.247559%, 88.273621%)" offset="0.914062"/>
          <stop stopOpacity="1" stopColor="rgb(73.069763%, 41.299438%, 88.360596%)" offset="0.917969"/>
          <stop stopOpacity="1" stopColor="rgb(73.381042%, 41.352844%, 88.449097%)" offset="0.921875"/>
          <stop stopOpacity="1" stopColor="rgb(73.690796%, 41.404724%, 88.536072%)" offset="0.925781"/>
          <stop stopOpacity="1" stopColor="rgb(74.002075%, 41.456604%, 88.624573%)" offset="0.929688"/>
          <stop stopOpacity="1" stopColor="rgb(74.313354%, 41.508484%, 88.711548%)" offset="0.933594"/>
          <stop stopOpacity="1" stopColor="rgb(74.624634%, 41.56189%, 88.798523%)" offset="0.9375"/>
          <stop stopOpacity="1" stopColor="rgb(74.934387%, 41.61377%, 88.885498%)" offset="0.941406"/>
          <stop stopOpacity="1" stopColor="rgb(75.245667%, 41.667175%, 88.973999%)" offset="0.945312"/>
          <stop stopOpacity="1" stopColor="rgb(75.556946%, 41.719055%, 89.060974%)" offset="0.949219"/>
          <stop stopOpacity="1" stopColor="rgb(75.868225%, 41.770935%, 89.149475%)" offset="0.953125"/>
          <stop stopOpacity="1" stopColor="rgb(76.177979%, 41.822815%, 89.23645%)" offset="0.957031"/>
          <stop stopOpacity="1" stopColor="rgb(76.489258%, 41.876221%, 89.324951%)" offset="0.960938"/>
          <stop stopOpacity="1" stopColor="rgb(76.800537%, 41.928101%, 89.411926%)" offset="0.964844"/>
          <stop stopOpacity="1" stopColor="rgb(77.111816%, 41.97998%, 89.498901%)" offset="0.96875"/>
          <stop stopOpacity="1" stopColor="rgb(77.42157%, 42.03186%, 89.585876%)" offset="0.972656"/>
          <stop stopOpacity="1" stopColor="rgb(77.732849%, 42.085266%, 89.674377%)" offset="0.976563"/>
          <stop stopOpacity="1" stopColor="rgb(78.044128%, 42.137146%, 89.761353%)" offset="0.980469"/>
          <stop stopOpacity="1" stopColor="rgb(78.355408%, 42.190552%, 89.849854%)" offset="0.984375"/>
          <stop stopOpacity="1" stopColor="rgb(78.666687%, 42.242432%, 89.936829%)" offset="0.988281"/>
          <stop stopOpacity="1" stopColor="rgb(78.977966%, 42.294312%, 90.023804%)" offset="0.992188"/>
          <stop stopOpacity="1" stopColor="rgb(79.28772%, 42.346191%, 90.110779%)" offset="0.996094"/>
          <stop stopOpacity="1" stopColor="rgb(79.598999%, 42.399597%, 90.19928%)" offset="1"/>
        </linearGradient>
        <clipPath id="afb1bc4b1f">
          <path
            d="M 256.945312 271.492188 L 350.253906 271.492188 L 350.253906 364.800781 L 256.945312 364.800781 Z M 256.945312 271.492188 "
            clipRule="nonzero"/>
        </clipPath>
        <clipPath id="849913e20b">
          <path
            d="M 303.601562 271.492188 C 277.835938 271.492188 256.945312 292.378906 256.945312 318.144531 C 256.945312 343.914062 277.835938 364.800781 303.601562 364.800781 C 329.367188 364.800781 350.253906 343.914062 350.253906 318.144531 C 350.253906 292.378906 329.367188 271.492188 303.601562 271.492188 Z M 303.601562 271.492188 "
            clipRule="nonzero"/>
        </clipPath>
        <linearGradient x1="0.00000903707" gradientTransform="matrix(0.364487, 0, 0, 0.364487, 256.946528, 271.49132)"
                        y1="128" x2="256.000006" gradientUnits="userSpaceOnUse" y2="128" id="6d9a6436d6">
          <stop stopOpacity="1" stopColor="rgb(0%, 28.999329%, 67.799377%)" offset="0"/>
          <stop stopOpacity="1" stopColor="rgb(0.309753%, 29.051208%, 67.886353%)" offset="0.00390625"/>
          <stop stopOpacity="1" stopColor="rgb(0.621033%, 29.104614%, 67.974854%)" offset="0.0078125"/>
          <stop stopOpacity="1" stopColor="rgb(0.932312%, 29.156494%, 68.061829%)" offset="0.0117188"/>
          <stop stopOpacity="1" stopColor="rgb(1.243591%, 29.208374%, 68.148804%)" offset="0.015625"/>
          <stop stopOpacity="1" stopColor="rgb(1.553345%, 29.260254%, 68.235779%)" offset="0.0195313"/>
          <stop stopOpacity="1" stopColor="rgb(1.864624%, 29.31366%, 68.32428%)" offset="0.0234375"/>
          <stop stopOpacity="1" stopColor="rgb(2.175903%, 29.36554%, 68.411255%)" offset="0.0273437"/>
          <stop stopOpacity="1" stopColor="rgb(2.487183%, 29.417419%, 68.499756%)" offset="0.03125"/>
          <stop stopOpacity="1" stopColor="rgb(2.796936%, 29.469299%, 68.586731%)" offset="0.0351562"/>
          <stop stopOpacity="1" stopColor="rgb(3.108215%, 29.522705%, 68.673706%)" offset="0.0390625"/>
          <stop stopOpacity="1" stopColor="rgb(3.419495%, 29.574585%, 68.760681%)" offset="0.0429688"/>
          <stop stopOpacity="1" stopColor="rgb(3.730774%, 29.627991%, 68.849182%)" offset="0.046875"/>
          <stop stopOpacity="1" stopColor="rgb(4.040527%, 29.679871%, 68.936157%)" offset="0.0507812"/>
          <stop stopOpacity="1" stopColor="rgb(4.351807%, 29.73175%, 69.024658%)" offset="0.0546875"/>
          <stop stopOpacity="1" stopColor="rgb(4.663086%, 29.78363%, 69.111633%)" offset="0.0585938"/>
          <stop stopOpacity="1" stopColor="rgb(4.974365%, 29.837036%, 69.198608%)" offset="0.0625"/>
          <stop stopOpacity="1" stopColor="rgb(5.284119%, 29.888916%, 69.285583%)" offset="0.0664063"/>
          <stop stopOpacity="1" stopColor="rgb(5.595398%, 29.940796%, 69.374084%)" offset="0.0703125"/>
          <stop stopOpacity="1" stopColor="rgb(5.906677%, 29.992676%, 69.46106%)" offset="0.0742188"/>
          <stop stopOpacity="1" stopColor="rgb(6.217957%, 30.046082%, 69.549561%)" offset="0.078125"/>
          <stop stopOpacity="1" stopColor="rgb(6.529236%, 30.097961%, 69.636536%)" offset="0.0820312"/>
          <stop stopOpacity="1" stopColor="rgb(6.840515%, 30.151367%, 69.723511%)" offset="0.0859375"/>
          <stop stopOpacity="1" stopColor="rgb(7.150269%, 30.203247%, 69.810486%)" offset="0.0898438"/>
          <stop stopOpacity="1" stopColor="rgb(7.461548%, 30.255127%, 69.898987%)" offset="0.09375"/>
          <stop stopOpacity="1" stopColor="rgb(7.772827%, 30.307007%, 69.985962%)" offset="0.0976562"/>
          <stop stopOpacity="1" stopColor="rgb(8.084106%, 30.360413%, 70.074463%)" offset="0.101562"/>
          <stop stopOpacity="1" stopColor="rgb(8.39386%, 30.412292%, 70.161438%)" offset="0.105469"/>
          <stop stopOpacity="1" stopColor="rgb(8.705139%, 30.464172%, 70.249939%)" offset="0.109375"/>
          <stop stopOpacity="1" stopColor="rgb(9.016418%, 30.516052%, 70.336914%)" offset="0.113281"/>
          <stop stopOpacity="1" stopColor="rgb(9.327698%, 30.569458%, 70.423889%)" offset="0.117188"/>
          <stop stopOpacity="1" stopColor="rgb(9.637451%, 30.621338%, 70.510864%)" offset="0.121094"/>
          <stop stopOpacity="1" stopColor="rgb(9.94873%, 30.674744%, 70.599365%)" offset="0.125"/>
          <stop stopOpacity="1" stopColor="rgb(10.26001%, 30.726624%, 70.68634%)" offset="0.128906"/>
          <stop stopOpacity="1" stopColor="rgb(10.571289%, 30.778503%, 70.774841%)" offset="0.132813"/>
          <stop stopOpacity="1" stopColor="rgb(10.881042%, 30.830383%, 70.861816%)" offset="0.136719"/>
          <stop stopOpacity="1" stopColor="rgb(11.192322%, 30.883789%, 70.948792%)" offset="0.140625"/>
          <stop stopOpacity="1" stopColor="rgb(11.503601%, 30.935669%, 71.035767%)" offset="0.144531"/>
          <stop stopOpacity="1" stopColor="rgb(11.81488%, 30.987549%, 71.124268%)" offset="0.148438"/>
          <stop stopOpacity="1" stopColor="rgb(12.12616%, 31.039429%, 71.211243%)" offset="0.152344"/>
          <stop stopOpacity="1" stopColor="rgb(12.437439%, 31.092834%, 71.299744%)" offset="0.15625"/>
          <stop stopOpacity="1" stopColor="rgb(12.747192%, 31.144714%, 71.386719%)" offset="0.160156"/>
          <stop stopOpacity="1" stopColor="rgb(13.058472%, 31.19812%, 71.473694%)" offset="0.164062"/>
          <stop stopOpacity="1" stopColor="rgb(13.369751%, 31.25%, 71.560669%)" offset="0.167969"/>
          <stop stopOpacity="1" stopColor="rgb(13.68103%, 31.30188%, 71.64917%)" offset="0.171875"/>
          <stop stopOpacity="1" stopColor="rgb(13.990784%, 31.35376%, 71.736145%)" offset="0.175781"/>
          <stop stopOpacity="1" stopColor="rgb(14.302063%, 31.407166%, 71.824646%)" offset="0.179688"/>
          <stop stopOpacity="1" stopColor="rgb(14.613342%, 31.459045%, 71.911621%)" offset="0.183594"/>
          <stop stopOpacity="1" stopColor="rgb(14.924622%, 31.512451%, 71.998596%)" offset="0.1875"/>
          <stop stopOpacity="1" stopColor="rgb(15.234375%, 31.564331%, 72.085571%)" offset="0.191406"/>
          <stop stopOpacity="1" stopColor="rgb(15.545654%, 31.616211%, 72.174072%)" offset="0.195312"/>
          <stop stopOpacity="1" stopColor="rgb(15.856934%, 31.668091%, 72.261047%)" offset="0.199219"/>
          <stop stopOpacity="1" stopColor="rgb(16.168213%, 31.721497%, 72.349548%)" offset="0.203125"/>
          <stop stopOpacity="1" stopColor="rgb(16.477966%, 31.773376%, 72.436523%)" offset="0.207031"/>
          <stop stopOpacity="1" stopColor="rgb(16.789246%, 31.825256%, 72.523499%)" offset="0.210938"/>
          <stop stopOpacity="1" stopColor="rgb(17.100525%, 31.877136%, 72.610474%)" offset="0.214844"/>
          <stop stopOpacity="1" stopColor="rgb(17.411804%, 31.930542%, 72.698975%)" offset="0.21875"/>
          <stop stopOpacity="1" stopColor="rgb(17.723083%, 31.982422%, 72.78595%)" offset="0.222656"/>
          <stop stopOpacity="1" stopColor="rgb(18.034363%, 32.035828%, 72.874451%)" offset="0.226562"/>
          <stop stopOpacity="1" stopColor="rgb(18.344116%, 32.087708%, 72.961426%)" offset="0.230469"/>
          <stop stopOpacity="1" stopColor="rgb(18.655396%, 32.139587%, 73.049927%)" offset="0.234375"/>
          <stop stopOpacity="1" stopColor="rgb(18.966675%, 32.191467%, 73.136902%)" offset="0.238281"/>
          <stop stopOpacity="1" stopColor="rgb(19.277954%, 32.244873%, 73.223877%)" offset="0.242187"/>
          <stop stopOpacity="1" stopColor="rgb(19.587708%, 32.296753%, 73.310852%)" offset="0.246094"/>
          <stop stopOpacity="1" stopColor="rgb(19.898987%, 32.348633%, 73.399353%)" offset="0.25"/>
          <stop stopOpacity="1" stopColor="rgb(20.210266%, 32.400513%, 73.486328%)" offset="0.253906"/>
          <stop stopOpacity="1" stopColor="rgb(20.521545%, 32.453918%, 73.574829%)" offset="0.257812"/>
          <stop stopOpacity="1" stopColor="rgb(20.831299%, 32.505798%, 73.661804%)" offset="0.261719"/>
          <stop stopOpacity="1" stopColor="rgb(21.142578%, 32.559204%, 73.748779%)" offset="0.265625"/>
          <stop stopOpacity="1" stopColor="rgb(21.453857%, 32.611084%, 73.835754%)" offset="0.269531"/>
          <stop stopOpacity="1" stopColor="rgb(21.765137%, 32.662964%, 73.924255%)" offset="0.273438"/>
          <stop stopOpacity="1" stopColor="rgb(22.07489%, 32.714844%, 74.01123%)" offset="0.277344"/>
          <stop stopOpacity="1" stopColor="rgb(22.386169%, 32.76825%, 74.099731%)" offset="0.28125"/>
          <stop stopOpacity="1" stopColor="rgb(22.697449%, 32.820129%, 74.186707%)" offset="0.285156"/>
          <stop stopOpacity="1" stopColor="rgb(23.008728%, 32.872009%, 74.273682%)" offset="0.289063"/>
          <stop stopOpacity="1" stopColor="rgb(23.318481%, 32.923889%, 74.360657%)" offset="0.292969"/>
          <stop stopOpacity="1" stopColor="rgb(23.629761%, 32.977295%, 74.449158%)" offset="0.296875"/>
          <stop stopOpacity="1" stopColor="rgb(23.94104%, 33.029175%, 74.536133%)" offset="0.300781"/>
          <stop stopOpacity="1" stopColor="rgb(24.252319%, 33.082581%, 74.624634%)" offset="0.304688"/>
          <stop stopOpacity="1" stopColor="rgb(24.563599%, 33.13446%, 74.711609%)" offset="0.308594"/>
          <stop stopOpacity="1" stopColor="rgb(24.874878%, 33.18634%, 74.798584%)" offset="0.3125"/>
          <stop stopOpacity="1" stopColor="rgb(25.184631%, 33.23822%, 74.885559%)" offset="0.316406"/>
          <stop stopOpacity="1" stopColor="rgb(25.495911%, 33.291626%, 74.97406%)" offset="0.320312"/>
          <stop stopOpacity="1" stopColor="rgb(25.80719%, 33.343506%, 75.061035%)" offset="0.324219"/>
          <stop stopOpacity="1" stopColor="rgb(26.118469%, 33.395386%, 75.149536%)" offset="0.328125"/>
          <stop stopOpacity="1" stopColor="rgb(26.428223%, 33.447266%, 75.236511%)" offset="0.332031"/>
          <stop stopOpacity="1" stopColor="rgb(26.739502%, 33.500671%, 75.323486%)" offset="0.335938"/>
          <stop stopOpacity="1" stopColor="rgb(27.050781%, 33.552551%, 75.410461%)" offset="0.339844"/>
          <stop stopOpacity="1" stopColor="rgb(27.362061%, 33.605957%, 75.498962%)" offset="0.34375"/>
          <stop stopOpacity="1" stopColor="rgb(27.671814%, 33.657837%, 75.585938%)" offset="0.347656"/>
          <stop stopOpacity="1" stopColor="rgb(27.983093%, 33.709717%, 75.674438%)" offset="0.351562"/>
          <stop stopOpacity="1" stopColor="rgb(28.294373%, 33.761597%, 75.761414%)" offset="0.355469"/>
          <stop stopOpacity="1" stopColor="rgb(28.605652%, 33.815002%, 75.849915%)" offset="0.359375"/>
          <stop stopOpacity="1" stopColor="rgb(28.915405%, 33.866882%, 75.93689%)" offset="0.363281"/>
          <stop stopOpacity="1" stopColor="rgb(29.226685%, 33.920288%, 76.023865%)" offset="0.367188"/>
          <stop stopOpacity="1" stopColor="rgb(29.537964%, 33.972168%, 76.11084%)" offset="0.371094"/>
          <stop stopOpacity="1" stopColor="rgb(29.849243%, 34.024048%, 76.199341%)" offset="0.375"/>
          <stop stopOpacity="1" stopColor="rgb(30.160522%, 34.075928%, 76.286316%)" offset="0.378906"/>
          <stop stopOpacity="1" stopColor="rgb(30.471802%, 34.129333%, 76.374817%)" offset="0.382812"/>
          <stop stopOpacity="1" stopColor="rgb(30.781555%, 34.181213%, 76.461792%)" offset="0.386719"/>
          <stop stopOpacity="1" stopColor="rgb(31.092834%, 34.233093%, 76.548767%)" offset="0.390625"/>
          <stop stopOpacity="1" stopColor="rgb(31.404114%, 34.284973%, 76.635742%)" offset="0.394531"/>
          <stop stopOpacity="1" stopColor="rgb(31.715393%, 34.338379%, 76.724243%)" offset="0.398438"/>
          <stop stopOpacity="1" stopColor="rgb(32.025146%, 34.390259%, 76.811218%)" offset="0.402344"/>
          <stop stopOpacity="1" stopColor="rgb(32.336426%, 34.443665%, 76.899719%)" offset="0.40625"/>
          <stop stopOpacity="1" stopColor="rgb(32.647705%, 34.495544%, 76.986694%)" offset="0.410156"/>
          <stop stopOpacity="1" stopColor="rgb(32.958984%, 34.547424%, 77.073669%)" offset="0.414062"/>
          <stop stopOpacity="1" stopColor="rgb(33.268738%, 34.599304%, 77.160645%)" offset="0.417969"/>
          <stop stopOpacity="1" stopColor="rgb(33.580017%, 34.65271%, 77.249146%)" offset="0.421875"/>
          <stop stopOpacity="1" stopColor="rgb(33.891296%, 34.70459%, 77.336121%)" offset="0.425781"/>
          <stop stopOpacity="1" stopColor="rgb(34.202576%, 34.75647%, 77.424622%)" offset="0.429688"/>
          <stop stopOpacity="1" stopColor="rgb(34.512329%, 34.80835%, 77.511597%)" offset="0.433594"/>
          <stop stopOpacity="1" stopColor="rgb(34.823608%, 34.861755%, 77.598572%)" offset="0.4375"/>
          <stop stopOpacity="1" stopColor="rgb(35.134888%, 34.913635%, 77.685547%)" offset="0.441406"/>
          <stop stopOpacity="1" stopColor="rgb(35.446167%, 34.967041%, 77.774048%)" offset="0.445312"/>
          <stop stopOpacity="1" stopColor="rgb(35.757446%, 35.018921%, 77.861023%)" offset="0.449219"/>
          <stop stopOpacity="1" stopColor="rgb(36.068726%, 35.070801%, 77.949524%)" offset="0.453125"/>
          <stop stopOpacity="1" stopColor="rgb(36.378479%, 35.122681%, 78.036499%)" offset="0.457031"/>
          <stop stopOpacity="1" stopColor="rgb(36.689758%, 35.176086%, 78.125%)" offset="0.460937"/>
          <stop stopOpacity="1" stopColor="rgb(37.001038%, 35.227966%, 78.211975%)" offset="0.464844"/>
          <stop stopOpacity="1" stopColor="rgb(37.312317%, 35.279846%, 78.29895%)" offset="0.46875"/>
          <stop stopOpacity="1" stopColor="rgb(37.62207%, 35.331726%, 78.385925%)" offset="0.472656"/>
          <stop stopOpacity="1" stopColor="rgb(37.93335%, 35.385132%, 78.474426%)" offset="0.476562"/>
          <stop stopOpacity="1" stopColor="rgb(38.244629%, 35.437012%, 78.561401%)" offset="0.480469"/>
          <stop stopOpacity="1" stopColor="rgb(38.555908%, 35.490417%, 78.649902%)" offset="0.484375"/>
          <stop stopOpacity="1" stopColor="rgb(38.865662%, 35.542297%, 78.736877%)" offset="0.488281"/>
          <stop stopOpacity="1" stopColor="rgb(39.176941%, 35.594177%, 78.823853%)" offset="0.492188"/>
          <stop stopOpacity="1" stopColor="rgb(39.48822%, 35.646057%, 78.910828%)" offset="0.496094"/>
          <stop stopOpacity="1" stopColor="rgb(39.7995%, 35.699463%, 78.999329%)" offset="0.5"/>
          <stop stopOpacity="1" stopColor="rgb(40.109253%, 35.751343%, 79.086304%)" offset="0.503906"/>
          <stop stopOpacity="1" stopColor="rgb(40.420532%, 35.803223%, 79.174805%)" offset="0.507812"/>
          <stop stopOpacity="1" stopColor="rgb(40.731812%, 35.855103%, 79.26178%)" offset="0.511719"/>
          <stop stopOpacity="1" stopColor="rgb(41.043091%, 35.908508%, 79.348755%)" offset="0.515625"/>
          <stop stopOpacity="1" stopColor="rgb(41.352844%, 35.960388%, 79.43573%)" offset="0.519531"/>
          <stop stopOpacity="1" stopColor="rgb(41.664124%, 36.013794%, 79.524231%)" offset="0.523438"/>
          <stop stopOpacity="1" stopColor="rgb(41.975403%, 36.065674%, 79.611206%)" offset="0.527344"/>
          <stop stopOpacity="1" stopColor="rgb(42.286682%, 36.117554%, 79.699707%)" offset="0.53125"/>
          <stop stopOpacity="1" stopColor="rgb(42.597961%, 36.169434%, 79.786682%)" offset="0.535156"/>
          <stop stopOpacity="1" stopColor="rgb(42.909241%, 36.222839%, 79.873657%)" offset="0.539062"/>
          <stop stopOpacity="1" stopColor="rgb(43.218994%, 36.274719%, 79.960632%)" offset="0.542969"/>
          <stop stopOpacity="1" stopColor="rgb(43.530273%, 36.328125%, 80.049133%)" offset="0.546875"/>
          <stop stopOpacity="1" stopColor="rgb(43.841553%, 36.380005%, 80.136108%)" offset="0.550781"/>
          <stop stopOpacity="1" stopColor="rgb(44.152832%, 36.431885%, 80.224609%)" offset="0.554688"/>
          <stop stopOpacity="1" stopColor="rgb(44.462585%, 36.483765%, 80.311584%)" offset="0.558594"/>
          <stop stopOpacity="1" stopColor="rgb(44.773865%, 36.53717%, 80.39856%)" offset="0.5625"/>
          <stop stopOpacity="1" stopColor="rgb(45.085144%, 36.58905%, 80.485535%)" offset="0.566406"/>
          <stop stopOpacity="1" stopColor="rgb(45.396423%, 36.64093%, 80.574036%)" offset="0.570313"/>
          <stop stopOpacity="1" stopColor="rgb(45.706177%, 36.69281%, 80.661011%)" offset="0.574219"/>
          <stop stopOpacity="1" stopColor="rgb(46.017456%, 36.746216%, 80.749512%)" offset="0.578125"/>
          <stop stopOpacity="1" stopColor="rgb(46.328735%, 36.798096%, 80.836487%)" offset="0.582031"/>
          <stop stopOpacity="1" stopColor="rgb(46.640015%, 36.851501%, 80.924988%)" offset="0.585938"/>
          <stop stopOpacity="1" stopColor="rgb(46.949768%, 36.903381%, 81.011963%)" offset="0.589844"/>
          <stop stopOpacity="1" stopColor="rgb(47.261047%, 36.955261%, 81.098938%)" offset="0.59375"/>
          <stop stopOpacity="1" stopColor="rgb(47.572327%, 37.007141%, 81.185913%)" offset="0.597656"/>
          <stop stopOpacity="1" stopColor="rgb(47.883606%, 37.060547%, 81.274414%)" offset="0.601562"/>
          <stop stopOpacity="1" stopColor="rgb(48.194885%, 37.112427%, 81.361389%)" offset="0.605469"/>
          <stop stopOpacity="1" stopColor="rgb(48.506165%, 37.164307%, 81.44989%)" offset="0.609375"/>
          <stop stopOpacity="1" stopColor="rgb(48.815918%, 37.216187%, 81.536865%)" offset="0.613281"/>
          <stop stopOpacity="1" stopColor="rgb(49.127197%, 37.269592%, 81.62384%)" offset="0.617188"/>
          <stop stopOpacity="1" stopColor="rgb(49.438477%, 37.321472%, 81.710815%)" offset="0.621094"/>
          <stop stopOpacity="1" stopColor="rgb(49.749756%, 37.374878%, 81.799316%)" offset="0.625"/>
          <stop stopOpacity="1" stopColor="rgb(50.059509%, 37.426758%, 81.886292%)" offset="0.628906"/>
          <stop stopOpacity="1" stopColor="rgb(50.370789%, 37.478638%, 81.974792%)" offset="0.632812"/>
          <stop stopOpacity="1" stopColor="rgb(50.682068%, 37.530518%, 82.061768%)" offset="0.636719"/>
          <stop stopOpacity="1" stopColor="rgb(50.993347%, 37.583923%, 82.148743%)" offset="0.640625"/>
          <stop stopOpacity="1" stopColor="rgb(51.303101%, 37.635803%, 82.235718%)" offset="0.644531"/>
          <stop stopOpacity="1" stopColor="rgb(51.61438%, 37.687683%, 82.324219%)" offset="0.648437"/>
          <stop stopOpacity="1" stopColor="rgb(51.925659%, 37.739563%, 82.411194%)" offset="0.652344"/>
          <stop stopOpacity="1" stopColor="rgb(52.236938%, 37.792969%, 82.499695%)" offset="0.65625"/>
          <stop stopOpacity="1" stopColor="rgb(52.546692%, 37.844849%, 82.58667%)" offset="0.660156"/>
          <stop stopOpacity="1" stopColor="rgb(52.857971%, 37.898254%, 82.673645%)" offset="0.664063"/>
          <stop stopOpacity="1" stopColor="rgb(53.16925%, 37.950134%, 82.76062%)" offset="0.667969"/>
          <stop stopOpacity="1" stopColor="rgb(53.48053%, 38.002014%, 82.849121%)" offset="0.671875"/>
          <stop stopOpacity="1" stopColor="rgb(53.791809%, 38.053894%, 82.936096%)" offset="0.675781"/>
          <stop stopOpacity="1" stopColor="rgb(54.103088%, 38.1073%, 83.024597%)" offset="0.679688"/>
          <stop stopOpacity="1" stopColor="rgb(54.412842%, 38.15918%, 83.111572%)" offset="0.683594"/>
          <stop stopOpacity="1" stopColor="rgb(54.724121%, 38.21106%, 83.198547%)" offset="0.6875"/>
          <stop stopOpacity="1" stopColor="rgb(55.0354%, 38.262939%, 83.285522%)" offset="0.691406"/>
          <stop stopOpacity="1" stopColor="rgb(55.34668%, 38.316345%, 83.374023%)" offset="0.695312"/>
          <stop stopOpacity="1" stopColor="rgb(55.656433%, 38.368225%, 83.460999%)" offset="0.699219"/>
          <stop stopOpacity="1" stopColor="rgb(55.967712%, 38.421631%, 83.5495%)" offset="0.703125"/>
          <stop stopOpacity="1" stopColor="rgb(56.278992%, 38.473511%, 83.636475%)" offset="0.707031"/>
          <stop stopOpacity="1" stopColor="rgb(56.590271%, 38.525391%, 83.724976%)" offset="0.710938"/>
          <stop stopOpacity="1" stopColor="rgb(56.900024%, 38.577271%, 83.811951%)" offset="0.714844"/>
          <stop stopOpacity="1" stopColor="rgb(57.211304%, 38.630676%, 83.898926%)" offset="0.71875"/>
          <stop stopOpacity="1" stopColor="rgb(57.522583%, 38.682556%, 83.985901%)" offset="0.722656"/>
          <stop stopOpacity="1" stopColor="rgb(57.833862%, 38.734436%, 84.074402%)" offset="0.726562"/>
          <stop stopOpacity="1" stopColor="rgb(58.143616%, 38.786316%, 84.161377%)" offset="0.730469"/>
          <stop stopOpacity="1" stopColor="rgb(58.454895%, 38.839722%, 84.249878%)" offset="0.734375"/>
          <stop stopOpacity="1" stopColor="rgb(58.766174%, 38.891602%, 84.336853%)" offset="0.738281"/>
          <stop stopOpacity="1" stopColor="rgb(59.077454%, 38.945007%, 84.423828%)" offset="0.742187"/>
          <stop stopOpacity="1" stopColor="rgb(59.387207%, 38.996887%, 84.510803%)" offset="0.746094"/>
          <stop stopOpacity="1" stopColor="rgb(59.698486%, 39.048767%, 84.599304%)" offset="0.75"/>
          <stop stopOpacity="1" stopColor="rgb(60.009766%, 39.100647%, 84.686279%)" offset="0.753906"/>
          <stop stopOpacity="1" stopColor="rgb(60.321045%, 39.154053%, 84.77478%)" offset="0.757813"/>
          <stop stopOpacity="1" stopColor="rgb(60.632324%, 39.205933%, 84.861755%)" offset="0.761719"/>
          <stop stopOpacity="1" stopColor="rgb(60.943604%, 39.259338%, 84.94873%)" offset="0.765625"/>
          <stop stopOpacity="1" stopColor="rgb(61.253357%, 39.311218%, 85.035706%)" offset="0.769531"/>
          <stop stopOpacity="1" stopColor="rgb(61.564636%, 39.363098%, 85.124207%)" offset="0.773438"/>
          <stop stopOpacity="1" stopColor="rgb(61.875916%, 39.414978%, 85.211182%)" offset="0.777344"/>
          <stop stopOpacity="1" stopColor="rgb(62.187195%, 39.468384%, 85.299683%)" offset="0.78125"/>
          <stop stopOpacity="1" stopColor="rgb(62.496948%, 39.520264%, 85.386658%)" offset="0.785156"/>
          <stop stopOpacity="1" stopColor="rgb(62.808228%, 39.572144%, 85.473633%)" offset="0.789062"/>
          <stop stopOpacity="1" stopColor="rgb(63.119507%, 39.624023%, 85.560608%)" offset="0.792969"/>
          <stop stopOpacity="1" stopColor="rgb(63.430786%, 39.677429%, 85.649109%)" offset="0.796875"/>
          <stop stopOpacity="1" stopColor="rgb(63.74054%, 39.729309%, 85.736084%)" offset="0.800781"/>
          <stop stopOpacity="1" stopColor="rgb(64.051819%, 39.782715%, 85.824585%)" offset="0.804688"/>
          <stop stopOpacity="1" stopColor="rgb(64.363098%, 39.834595%, 85.91156%)" offset="0.808594"/>
          <stop stopOpacity="1" stopColor="rgb(64.674377%, 39.886475%, 85.998535%)" offset="0.8125"/>
          <stop stopOpacity="1" stopColor="rgb(64.984131%, 39.938354%, 86.08551%)" offset="0.816406"/>
          <stop stopOpacity="1" stopColor="rgb(65.29541%, 39.99176%, 86.174011%)" offset="0.820312"/>
          <stop stopOpacity="1" stopColor="rgb(65.606689%, 40.04364%, 86.260986%)" offset="0.824219"/>
          <stop stopOpacity="1" stopColor="rgb(65.917969%, 40.09552%, 86.349487%)" offset="0.828125"/>
          <stop stopOpacity="1" stopColor="rgb(66.229248%, 40.1474%, 86.436462%)" offset="0.832031"/>
          <stop stopOpacity="1" stopColor="rgb(66.540527%, 40.200806%, 86.524963%)" offset="0.835937"/>
          <stop stopOpacity="1" stopColor="rgb(66.850281%, 40.252686%, 86.611938%)" offset="0.839844"/>
          <stop stopOpacity="1" stopColor="rgb(67.16156%, 40.306091%, 86.698914%)" offset="0.84375"/>
          <stop stopOpacity="1" stopColor="rgb(67.472839%, 40.357971%, 86.785889%)" offset="0.847656"/>
          <stop stopOpacity="1" stopColor="rgb(67.784119%, 40.409851%, 86.87439%)" offset="0.851563"/>
          <stop stopOpacity="1" stopColor="rgb(68.093872%, 40.461731%, 86.961365%)" offset="0.855469"/>
          <stop stopOpacity="1" stopColor="rgb(68.405151%, 40.515137%, 87.049866%)" offset="0.859375"/>
          <stop stopOpacity="1" stopColor="rgb(68.716431%, 40.567017%, 87.136841%)" offset="0.863281"/>
          <stop stopOpacity="1" stopColor="rgb(69.02771%, 40.618896%, 87.223816%)" offset="0.867188"/>
          <stop stopOpacity="1" stopColor="rgb(69.337463%, 40.670776%, 87.310791%)" offset="0.871094"/>
          <stop stopOpacity="1" stopColor="rgb(69.648743%, 40.724182%, 87.399292%)" offset="0.875"/>
          <stop stopOpacity="1" stopColor="rgb(69.960022%, 40.776062%, 87.486267%)" offset="0.878906"/>
          <stop stopOpacity="1" stopColor="rgb(70.271301%, 40.829468%, 87.574768%)" offset="0.882812"/>
          <stop stopOpacity="1" stopColor="rgb(70.581055%, 40.881348%, 87.661743%)" offset="0.886719"/>
          <stop stopOpacity="1" stopColor="rgb(70.892334%, 40.933228%, 87.748718%)" offset="0.890625"/>
          <stop stopOpacity="1" stopColor="rgb(71.203613%, 40.985107%, 87.835693%)" offset="0.894531"/>
          <stop stopOpacity="1" stopColor="rgb(71.514893%, 41.038513%, 87.924194%)" offset="0.898438"/>
          <stop stopOpacity="1" stopColor="rgb(71.826172%, 41.090393%, 88.011169%)" offset="0.902344"/>
          <stop stopOpacity="1" stopColor="rgb(72.137451%, 41.142273%, 88.09967%)" offset="0.90625"/>
          <stop stopOpacity="1" stopColor="rgb(72.447205%, 41.194153%, 88.186646%)" offset="0.910156"/>
          <stop stopOpacity="1" stopColor="rgb(72.758484%, 41.247559%, 88.273621%)" offset="0.914062"/>
          <stop stopOpacity="1" stopColor="rgb(73.069763%, 41.299438%, 88.360596%)" offset="0.917969"/>
          <stop stopOpacity="1" stopColor="rgb(73.381042%, 41.352844%, 88.449097%)" offset="0.921875"/>
          <stop stopOpacity="1" stopColor="rgb(73.690796%, 41.404724%, 88.536072%)" offset="0.925781"/>
          <stop stopOpacity="1" stopColor="rgb(74.002075%, 41.456604%, 88.624573%)" offset="0.929687"/>
          <stop stopOpacity="1" stopColor="rgb(74.313354%, 41.508484%, 88.711548%)" offset="0.933594"/>
          <stop stopOpacity="1" stopColor="rgb(74.624634%, 41.56189%, 88.798523%)" offset="0.9375"/>
          <stop stopOpacity="1" stopColor="rgb(74.934387%, 41.61377%, 88.885498%)" offset="0.941406"/>
          <stop stopOpacity="1" stopColor="rgb(75.245667%, 41.667175%, 88.973999%)" offset="0.945312"/>
          <stop stopOpacity="1" stopColor="rgb(75.556946%, 41.719055%, 89.060974%)" offset="0.949219"/>
          <stop stopOpacity="1" stopColor="rgb(75.868225%, 41.770935%, 89.149475%)" offset="0.953125"/>
          <stop stopOpacity="1" stopColor="rgb(76.177979%, 41.822815%, 89.23645%)" offset="0.957031"/>
          <stop stopOpacity="1" stopColor="rgb(76.489258%, 41.876221%, 89.324951%)" offset="0.960938"/>
          <stop stopOpacity="1" stopColor="rgb(76.800537%, 41.928101%, 89.411926%)" offset="0.964844"/>
          <stop stopOpacity="1" stopColor="rgb(77.111816%, 41.97998%, 89.498901%)" offset="0.96875"/>
          <stop stopOpacity="1" stopColor="rgb(77.423096%, 42.03186%, 89.585876%)" offset="0.972656"/>
          <stop stopOpacity="1" stopColor="rgb(77.734375%, 42.085266%, 89.674377%)" offset="0.976562"/>
          <stop stopOpacity="1" stopColor="rgb(78.044128%, 42.137146%, 89.761353%)" offset="0.980469"/>
          <stop stopOpacity="1" stopColor="rgb(78.355408%, 42.190552%, 89.849854%)" offset="0.984375"/>
          <stop stopOpacity="1" stopColor="rgb(78.666687%, 42.242432%, 89.936829%)" offset="0.988281"/>
          <stop stopOpacity="1" stopColor="rgb(78.977966%, 42.294312%, 90.023804%)" offset="0.992188"/>
          <stop stopOpacity="1" stopColor="rgb(79.28772%, 42.346191%, 90.110779%)" offset="0.996094"/>
          <stop stopOpacity="1" stopColor="rgb(79.598999%, 42.399597%, 90.19928%)" offset="1"/>
        </linearGradient>
        <linearGradient x1="-2.18791"
                        gradientTransform="matrix(1, 0.00000000948305, -0.00000000948305, 1, 0.00000560135, 0.00000934373)"
                        y1="2.5" x2="73.025986" gradientUnits="userSpaceOnUse" y2="2.5" id="ec8a3210b4">
          <stop stopOpacity="1" stopColor="rgb(0%, 28.999329%, 67.799377%)" offset="0"/>
          <stop stopOpacity="1" stopColor="rgb(0.0900269%, 29.014587%, 67.825317%)" offset="0.03125"/>
          <stop stopOpacity="1" stopColor="rgb(0.511169%, 29.084778%, 67.94281%)" offset="0.0351562"/>
          <stop stopOpacity="1" stopColor="rgb(0.842285%, 29.141235%, 68.035889%)" offset="0.0390625"/>
          <stop stopOpacity="1" stopColor="rgb(1.171875%, 29.196167%, 68.128967%)" offset="0.0429688"/>
          <stop stopOpacity="1" stopColor="rgb(1.502991%, 29.252625%, 68.222046%)" offset="0.046875"/>
          <stop stopOpacity="1" stopColor="rgb(1.832581%, 29.307556%, 68.315125%)" offset="0.0507813"/>
          <stop stopOpacity="1" stopColor="rgb(2.16217%, 29.364014%, 68.408203%)" offset="0.0546875"/>
          <stop stopOpacity="1" stopColor="rgb(2.49176%, 29.418945%, 68.501282%)" offset="0.0585938"/>
          <stop stopOpacity="1" stopColor="rgb(2.822876%, 29.473877%, 68.59436%)" offset="0.0625"/>
          <stop stopOpacity="1" stopColor="rgb(3.152466%, 29.528809%, 68.685913%)" offset="0.0664063"/>
          <stop stopOpacity="1" stopColor="rgb(3.483582%, 29.585266%, 68.778992%)" offset="0.0703125"/>
          <stop stopOpacity="1" stopColor="rgb(3.813171%, 29.640198%, 68.87207%)" offset="0.0742188"/>
          <stop stopOpacity="1" stopColor="rgb(4.144287%, 29.696655%, 68.965149%)" offset="0.078125"/>
          <stop stopOpacity="1" stopColor="rgb(4.473877%, 29.751587%, 69.058228%)" offset="0.0820313"/>
          <stop stopOpacity="1" stopColor="rgb(4.803467%, 29.808044%, 69.151306%)" offset="0.0859375"/>
          <stop stopOpacity="1" stopColor="rgb(5.133057%, 29.862976%, 69.244385%)" offset="0.0898438"/>
          <stop stopOpacity="1" stopColor="rgb(5.464172%, 29.919434%, 69.337463%)" offset="0.09375"/>
          <stop stopOpacity="1" stopColor="rgb(5.793762%, 29.974365%, 69.430542%)" offset="0.0976563"/>
          <stop stopOpacity="1" stopColor="rgb(6.124878%, 30.030823%, 69.523621%)" offset="0.101563"/>
          <stop stopOpacity="1" stopColor="rgb(6.454468%, 30.085754%, 69.615173%)" offset="0.105469"/>
          <stop stopOpacity="1" stopColor="rgb(6.784058%, 30.142212%, 69.708252%)" offset="0.109375"/>
          <stop stopOpacity="1" stopColor="rgb(7.113647%, 30.197144%, 69.801331%)" offset="0.113281"/>
          <stop stopOpacity="1" stopColor="rgb(7.444763%, 30.252075%, 69.894409%)" offset="0.117188"/>
          <stop stopOpacity="1" stopColor="rgb(7.774353%, 30.307007%, 69.987488%)" offset="0.121094"/>
          <stop stopOpacity="1" stopColor="rgb(8.105469%, 30.363464%, 70.080566%)" offset="0.125"/>
          <stop stopOpacity="1" stopColor="rgb(8.435059%, 30.418396%, 70.173645%)" offset="0.128906"/>
          <stop stopOpacity="1" stopColor="rgb(8.766174%, 30.474854%, 70.266724%)" offset="0.132813"/>
          <stop stopOpacity="1" stopColor="rgb(9.095764%, 30.529785%, 70.358276%)" offset="0.136719"/>
          <stop stopOpacity="1" stopColor="rgb(9.425354%, 30.586243%, 70.451355%)" offset="0.140625"/>
          <stop stopOpacity="1" stopColor="rgb(9.754944%, 30.641174%, 70.544434%)" offset="0.144531"/>
          <stop stopOpacity="1" stopColor="rgb(10.08606%, 30.697632%, 70.637512%)" offset="0.148438"/>
          <stop stopOpacity="1" stopColor="rgb(10.415649%, 30.752563%, 70.730591%)" offset="0.152344"/>
          <stop stopOpacity="1" stopColor="rgb(10.746765%, 30.809021%, 70.823669%)" offset="0.15625"/>
          <stop stopOpacity="1" stopColor="rgb(11.076355%, 30.863953%, 70.916748%)" offset="0.160156"/>
          <stop stopOpacity="1" stopColor="rgb(11.407471%, 30.918884%, 71.009827%)" offset="0.164063"/>
          <stop stopOpacity="1" stopColor="rgb(11.737061%, 30.973816%, 71.101379%)" offset="0.167969"/>
          <stop stopOpacity="1" stopColor="rgb(12.06665%, 31.030273%, 71.194458%)" offset="0.171875"/>
          <stop stopOpacity="1" stopColor="rgb(12.39624%, 31.085205%, 71.287537%)" offset="0.175781"/>
          <stop stopOpacity="1" stopColor="rgb(12.727356%, 31.141663%, 71.380615%)" offset="0.179688"/>
          <stop stopOpacity="1" stopColor="rgb(13.056946%, 31.196594%, 71.473694%)" offset="0.183594"/>
          <stop stopOpacity="1" stopColor="rgb(13.388062%, 31.253052%, 71.566772%)" offset="0.1875"/>
          <stop stopOpacity="1" stopColor="rgb(13.717651%, 31.307983%, 71.659851%)" offset="0.191406"/>
          <stop stopOpacity="1" stopColor="rgb(14.047241%, 31.364441%, 71.75293%)" offset="0.195313"/>
          <stop stopOpacity="1" stopColor="rgb(14.376831%, 31.419373%, 71.846008%)" offset="0.199219"/>
          <stop stopOpacity="1" stopColor="rgb(14.707947%, 31.47583%, 71.939087%)" offset="0.203125"/>
          <stop stopOpacity="1" stopColor="rgb(15.037537%, 31.530762%, 72.03064%)" offset="0.207031"/>
          <stop stopOpacity="1" stopColor="rgb(15.368652%, 31.587219%, 72.123718%)" offset="0.210938"/>
          <stop stopOpacity="1" stopColor="rgb(15.698242%, 31.642151%, 72.216797%)" offset="0.214844"/>
          <stop stopOpacity="1" stopColor="rgb(16.029358%, 31.697083%, 72.309875%)" offset="0.21875"/>
          <stop stopOpacity="1" stopColor="rgb(16.358948%, 31.752014%, 72.402954%)" offset="0.222656"/>
          <stop stopOpacity="1" stopColor="rgb(16.688538%, 31.808472%, 72.496033%)" offset="0.226563"/>
          <stop stopOpacity="1" stopColor="rgb(17.018127%, 31.863403%, 72.589111%)" offset="0.230469"/>
          <stop stopOpacity="1" stopColor="rgb(17.349243%, 31.919861%, 72.68219%)" offset="0.234375"/>
          <stop stopOpacity="1" stopColor="rgb(17.678833%, 31.974792%, 72.773743%)" offset="0.238281"/>
          <stop stopOpacity="1" stopColor="rgb(18.009949%, 32.03125%, 72.866821%)" offset="0.242188"/>
          <stop stopOpacity="1" stopColor="rgb(18.339539%, 32.086182%, 72.9599%)" offset="0.246094"/>
          <stop stopOpacity="1" stopColor="rgb(18.670654%, 32.142639%, 73.052979%)" offset="0.25"/>
          <stop stopOpacity="1" stopColor="rgb(19.000244%, 32.197571%, 73.146057%)" offset="0.253906"/>
          <stop stopOpacity="1" stopColor="rgb(19.329834%, 32.254028%, 73.239136%)" offset="0.257812"/>
          <stop stopOpacity="1" stopColor="rgb(19.659424%, 32.30896%, 73.332214%)" offset="0.261719"/>
          <stop stopOpacity="1" stopColor="rgb(19.99054%, 32.363892%, 73.425293%)" offset="0.265625"/>
          <stop stopOpacity="1" stopColor="rgb(20.320129%, 32.418823%, 73.518372%)" offset="0.269531"/>
          <stop stopOpacity="1" stopColor="rgb(20.651245%, 32.475281%, 73.61145%)" offset="0.273438"/>
          <stop stopOpacity="1" stopColor="rgb(20.980835%, 32.530212%, 73.703003%)" offset="0.277344"/>
          <stop stopOpacity="1" stopColor="rgb(21.310425%, 32.58667%, 73.796082%)" offset="0.28125"/>
          <stop stopOpacity="1" stopColor="rgb(21.640015%, 32.641602%, 73.88916%)" offset="0.285156"/>
          <stop stopOpacity="1" stopColor="rgb(21.97113%, 32.698059%, 73.982239%)" offset="0.289062"/>
          <stop stopOpacity="1" stopColor="rgb(22.30072%, 32.752991%, 74.075317%)" offset="0.292969"/>
          <stop stopOpacity="1" stopColor="rgb(22.631836%, 32.809448%, 74.168396%)" offset="0.296875"/>
          <stop stopOpacity="1" stopColor="rgb(22.961426%, 32.86438%, 74.261475%)" offset="0.300781"/>
          <stop stopOpacity="1" stopColor="rgb(23.292542%, 32.920837%, 74.354553%)" offset="0.304688"/>
          <stop stopOpacity="1" stopColor="rgb(23.622131%, 32.975769%, 74.446106%)" offset="0.308594"/>
          <stop stopOpacity="1" stopColor="rgb(23.951721%, 33.032227%, 74.539185%)" offset="0.3125"/>
          <stop stopOpacity="1" stopColor="rgb(24.281311%, 33.087158%, 74.632263%)" offset="0.316406"/>
          <stop stopOpacity="1" stopColor="rgb(24.612427%, 33.14209%, 74.725342%)" offset="0.320312"/>
          <stop stopOpacity="1" stopColor="rgb(24.942017%, 33.197021%, 74.81842%)" offset="0.324219"/>
          <stop stopOpacity="1" stopColor="rgb(25.273132%, 33.253479%, 74.911499%)" offset="0.328125"/>
          <stop stopOpacity="1" stopColor="rgb(25.602722%, 33.308411%, 75.004578%)" offset="0.332031"/>
          <stop stopOpacity="1" stopColor="rgb(25.933838%, 33.364868%, 75.097656%)" offset="0.335938"/>
          <stop stopOpacity="1" stopColor="rgb(26.263428%, 33.4198%, 75.189209%)" offset="0.339844"/>
          <stop stopOpacity="1" stopColor="rgb(26.593018%, 33.476257%, 75.282288%)" offset="0.34375"/>
          <stop stopOpacity="1" stopColor="rgb(26.922607%, 33.531189%, 75.375366%)" offset="0.347656"/>
          <stop stopOpacity="1" stopColor="rgb(27.253723%, 33.587646%, 75.468445%)" offset="0.351562"/>
          <stop stopOpacity="1" stopColor="rgb(27.583313%, 33.642578%, 75.561523%)" offset="0.355469"/>
          <stop stopOpacity="1" stopColor="rgb(27.914429%, 33.699036%, 75.654602%)" offset="0.359375"/>
          <stop stopOpacity="1" stopColor="rgb(28.244019%, 33.753967%, 75.747681%)" offset="0.363281"/>
          <stop stopOpacity="1" stopColor="rgb(28.573608%, 33.808899%, 75.840759%)" offset="0.367188"/>
          <stop stopOpacity="1" stopColor="rgb(28.903198%, 33.863831%, 75.933838%)" offset="0.371094"/>
          <stop stopOpacity="1" stopColor="rgb(29.234314%, 33.920288%, 76.026917%)" offset="0.375"/>
          <stop stopOpacity="1" stopColor="rgb(29.563904%, 33.97522%, 76.118469%)" offset="0.378906"/>
          <stop stopOpacity="1" stopColor="rgb(29.89502%, 34.031677%, 76.211548%)" offset="0.382813"/>
          <stop stopOpacity="1" stopColor="rgb(30.224609%, 34.086609%, 76.304626%)" offset="0.386719"/>
          <stop stopOpacity="1" stopColor="rgb(30.555725%, 34.143066%, 76.397705%)" offset="0.390625"/>
          <stop stopOpacity="1" stopColor="rgb(30.885315%, 34.197998%, 76.490784%)" offset="0.394531"/>
          <stop stopOpacity="1" stopColor="rgb(31.214905%, 34.254456%, 76.583862%)" offset="0.398438"/>
          <stop stopOpacity="1" stopColor="rgb(31.544495%, 34.309387%, 76.676941%)" offset="0.402344"/>
          <stop stopOpacity="1" stopColor="rgb(31.87561%, 34.365845%, 76.77002%)" offset="0.40625"/>
          <stop stopOpacity="1" stopColor="rgb(32.2052%, 34.420776%, 76.861572%)" offset="0.410156"/>
          <stop stopOpacity="1" stopColor="rgb(32.536316%, 34.477234%, 76.954651%)" offset="0.414063"/>
          <stop stopOpacity="1" stopColor="rgb(32.865906%, 34.532166%, 77.047729%)" offset="0.417969"/>
          <stop stopOpacity="1" stopColor="rgb(33.197021%, 34.587097%, 77.140808%)" offset="0.421875"/>
          <stop stopOpacity="1" stopColor="rgb(33.526611%, 34.642029%, 77.233887%)" offset="0.425781"/>
          <stop stopOpacity="1" stopColor="rgb(33.856201%, 34.698486%, 77.326965%)" offset="0.429688"/>
          <stop stopOpacity="1" stopColor="rgb(34.185791%, 34.753418%, 77.420044%)" offset="0.433594"/>
          <stop stopOpacity="1" stopColor="rgb(34.516907%, 34.809875%, 77.513123%)" offset="0.4375"/>
          <stop stopOpacity="1" stopColor="rgb(34.846497%, 34.864807%, 77.606201%)" offset="0.441406"/>
          <stop stopOpacity="1" stopColor="rgb(35.177612%, 34.921265%, 77.69928%)" offset="0.445313"/>
          <stop stopOpacity="1" stopColor="rgb(35.507202%, 34.976196%, 77.790833%)" offset="0.449219"/>
          <stop stopOpacity="1" stopColor="rgb(35.836792%, 35.032654%, 77.883911%)" offset="0.453125"/>
          <stop stopOpacity="1" stopColor="rgb(36.166382%, 35.087585%, 77.97699%)" offset="0.457031"/>
          <stop stopOpacity="1" stopColor="rgb(36.497498%, 35.144043%, 78.070068%)" offset="0.460938"/>
          <stop stopOpacity="1" stopColor="rgb(36.827087%, 35.198975%, 78.163147%)" offset="0.464844"/>
          <stop stopOpacity="1" stopColor="rgb(37.158203%, 35.253906%, 78.256226%)" offset="0.46875"/>
          <stop stopOpacity="1" stopColor="rgb(37.487793%, 35.308838%, 78.349304%)" offset="0.472656"/>
          <stop stopOpacity="1" stopColor="rgb(37.818909%, 35.365295%, 78.442383%)" offset="0.476563"/>
          <stop stopOpacity="1" stopColor="rgb(38.148499%, 35.420227%, 78.533936%)" offset="0.480469"/>
          <stop stopOpacity="1" stopColor="rgb(38.478088%, 35.476685%, 78.627014%)" offset="0.484375"/>
          <stop stopOpacity="1" stopColor="rgb(38.807678%, 35.531616%, 78.720093%)" offset="0.488281"/>
          <stop stopOpacity="1" stopColor="rgb(39.138794%, 35.588074%, 78.813171%)" offset="0.492188"/>
          <stop stopOpacity="1" stopColor="rgb(39.468384%, 35.643005%, 78.90625%)" offset="0.496094"/>
          <stop stopOpacity="1" stopColor="rgb(39.7995%, 35.699463%, 78.999329%)" offset="0.5"/>
          <stop stopOpacity="1" stopColor="rgb(40.129089%, 35.754395%, 79.092407%)" offset="0.503906"/>
          <stop stopOpacity="1" stopColor="rgb(40.460205%, 35.810852%, 79.185486%)" offset="0.507812"/>
          <stop stopOpacity="1" stopColor="rgb(40.789795%, 35.865784%, 79.277039%)" offset="0.511719"/>
          <stop stopOpacity="1" stopColor="rgb(41.119385%, 35.922241%, 79.370117%)" offset="0.515625"/>
          <stop stopOpacity="1" stopColor="rgb(41.448975%, 35.977173%, 79.463196%)" offset="0.519531"/>
          <stop stopOpacity="1" stopColor="rgb(41.78009%, 36.032104%, 79.556274%)" offset="0.523438"/>
          <stop stopOpacity="1" stopColor="rgb(42.10968%, 36.087036%, 79.649353%)" offset="0.527344"/>
          <stop stopOpacity="1" stopColor="rgb(42.440796%, 36.143494%, 79.742432%)" offset="0.53125"/>
          <stop stopOpacity="1" stopColor="rgb(42.770386%, 36.198425%, 79.83551%)" offset="0.535156"/>
          <stop stopOpacity="1" stopColor="rgb(43.099976%, 36.254883%, 79.928589%)" offset="0.539063"/>
          <stop stopOpacity="1" stopColor="rgb(43.429565%, 36.309814%, 80.021667%)" offset="0.542969"/>
          <stop stopOpacity="1" stopColor="rgb(43.760681%, 36.366272%, 80.114746%)" offset="0.546875"/>
          <stop stopOpacity="1" stopColor="rgb(44.090271%, 36.421204%, 80.206299%)" offset="0.550781"/>
          <stop stopOpacity="1" stopColor="rgb(44.421387%, 36.477661%, 80.299377%)" offset="0.554688"/>
          <stop stopOpacity="1" stopColor="rgb(44.750977%, 36.532593%, 80.392456%)" offset="0.558594"/>
          <stop stopOpacity="1" stopColor="rgb(45.082092%, 36.58905%, 80.485535%)" offset="0.5625"/>
          <stop stopOpacity="1" stopColor="rgb(45.411682%, 36.643982%, 80.578613%)" offset="0.566406"/>
          <stop stopOpacity="1" stopColor="rgb(45.741272%, 36.698914%, 80.671692%)" offset="0.570313"/>
          <stop stopOpacity="1" stopColor="rgb(46.070862%, 36.753845%, 80.764771%)" offset="0.574219"/>
          <stop stopOpacity="1" stopColor="rgb(46.401978%, 36.810303%, 80.857849%)" offset="0.578125"/>
          <stop stopOpacity="1" stopColor="rgb(46.731567%, 36.865234%, 80.949402%)" offset="0.582031"/>
          <stop stopOpacity="1" stopColor="rgb(47.062683%, 36.921692%, 81.04248%)" offset="0.585938"/>
          <stop stopOpacity="1" stopColor="rgb(47.392273%, 36.976624%, 81.135559%)" offset="0.589844"/>
          <stop stopOpacity="1" stopColor="rgb(47.723389%, 37.033081%, 81.228638%)" offset="0.59375"/>
          <stop stopOpacity="1" stopColor="rgb(48.052979%, 37.088013%, 81.321716%)" offset="0.597656"/>
          <stop stopOpacity="1" stopColor="rgb(48.382568%, 37.14447%, 81.414795%)" offset="0.601563"/>
          <stop stopOpacity="1" stopColor="rgb(48.712158%, 37.199402%, 81.507874%)" offset="0.605469"/>
          <stop stopOpacity="1" stopColor="rgb(49.043274%, 37.255859%, 81.600952%)" offset="0.609375"/>
          <stop stopOpacity="1" stopColor="rgb(49.372864%, 37.310791%, 81.694031%)" offset="0.613281"/>
          <stop stopOpacity="1" stopColor="rgb(49.703979%, 37.367249%, 81.787109%)" offset="0.617188"/>
          <stop stopOpacity="1" stopColor="rgb(50.033569%, 37.42218%, 81.878662%)" offset="0.621094"/>
          <stop stopOpacity="1" stopColor="rgb(50.364685%, 37.477112%, 81.971741%)" offset="0.625"/>
          <stop stopOpacity="1" stopColor="rgb(50.694275%, 37.532043%, 82.064819%)" offset="0.628906"/>
          <stop stopOpacity="1" stopColor="rgb(51.023865%, 37.588501%, 82.157898%)" offset="0.632813"/>
          <stop stopOpacity="1" stopColor="rgb(51.353455%, 37.643433%, 82.250977%)" offset="0.636719"/>
          <stop stopOpacity="1" stopColor="rgb(51.68457%, 37.69989%, 82.344055%)" offset="0.640625"/>
          <stop stopOpacity="1" stopColor="rgb(52.01416%, 37.754822%, 82.437134%)" offset="0.644531"/>
          <stop stopOpacity="1" stopColor="rgb(52.345276%, 37.811279%, 82.530212%)" offset="0.648438"/>
          <stop stopOpacity="1" stopColor="rgb(52.674866%, 37.866211%, 82.621765%)" offset="0.652344"/>
          <stop stopOpacity="1" stopColor="rgb(53.004456%, 37.922668%, 82.714844%)" offset="0.65625"/>
          <stop stopOpacity="1" stopColor="rgb(53.334045%, 37.9776%, 82.807922%)" offset="0.660156"/>
          <stop stopOpacity="1" stopColor="rgb(53.665161%, 38.034058%, 82.901001%)" offset="0.664063"/>
          <stop stopOpacity="1" stopColor="rgb(53.994751%, 38.088989%, 82.99408%)" offset="0.667969"/>
          <stop stopOpacity="1" stopColor="rgb(54.325867%, 38.143921%, 83.087158%)" offset="0.671875"/>
          <stop stopOpacity="1" stopColor="rgb(54.655457%, 38.198853%, 83.180237%)" offset="0.675781"/>
          <stop stopOpacity="1" stopColor="rgb(54.986572%, 38.25531%, 83.273315%)" offset="0.679688"/>
          <stop stopOpacity="1" stopColor="rgb(55.316162%, 38.310242%, 83.364868%)" offset="0.683594"/>
          <stop stopOpacity="1" stopColor="rgb(55.645752%, 38.366699%, 83.457947%)" offset="0.6875"/>
          <stop stopOpacity="1" stopColor="rgb(55.975342%, 38.421631%, 83.551025%)" offset="0.691406"/>
          <stop stopOpacity="1" stopColor="rgb(56.306458%, 38.478088%, 83.644104%)" offset="0.695313"/>
          <stop stopOpacity="1" stopColor="rgb(56.636047%, 38.53302%, 83.737183%)" offset="0.699219"/>
          <stop stopOpacity="1" stopColor="rgb(56.967163%, 38.589478%, 83.830261%)" offset="0.703125"/>
          <stop stopOpacity="1" stopColor="rgb(57.296753%, 38.644409%, 83.92334%)" offset="0.707031"/>
          <stop stopOpacity="1" stopColor="rgb(57.627869%, 38.700867%, 84.016418%)" offset="0.710938"/>
          <stop stopOpacity="1" stopColor="rgb(57.957458%, 38.755798%, 84.109497%)" offset="0.714844"/>
          <stop stopOpacity="1" stopColor="rgb(58.287048%, 38.812256%, 84.202576%)" offset="0.71875"/>
          <stop stopOpacity="1" stopColor="rgb(58.616638%, 38.867188%, 84.294128%)" offset="0.722656"/>
          <stop stopOpacity="1" stopColor="rgb(58.947754%, 38.922119%, 84.387207%)" offset="0.726563"/>
          <stop stopOpacity="1" stopColor="rgb(59.277344%, 38.977051%, 84.480286%)" offset="0.730469"/>
          <stop stopOpacity="1" stopColor="rgb(59.608459%, 39.033508%, 84.573364%)" offset="0.734375"/>
          <stop stopOpacity="1" stopColor="rgb(59.938049%, 39.08844%, 84.666443%)" offset="0.738281"/>
          <stop stopOpacity="1" stopColor="rgb(60.267639%, 39.144897%, 84.759521%)" offset="0.742188"/>
          <stop stopOpacity="1" stopColor="rgb(60.597229%, 39.199829%, 84.8526%)" offset="0.746094"/>
          <stop stopOpacity="1" stopColor="rgb(60.928345%, 39.256287%, 84.945679%)" offset="0.75"/>
          <stop stopOpacity="1" stopColor="rgb(61.257935%, 39.311218%, 85.037231%)" offset="0.753906"/>
          <stop stopOpacity="1" stopColor="rgb(61.58905%, 39.367676%, 85.13031%)" offset="0.757813"/>
          <stop stopOpacity="1" stopColor="rgb(61.91864%, 39.422607%, 85.223389%)" offset="0.761719"/>
          <stop stopOpacity="1" stopColor="rgb(62.249756%, 39.479065%, 85.316467%)" offset="0.765625"/>
          <stop stopOpacity="1" stopColor="rgb(62.579346%, 39.533997%, 85.409546%)" offset="0.769531"/>
          <stop stopOpacity="1" stopColor="rgb(62.908936%, 39.588928%, 85.502625%)" offset="0.773438"/>
          <stop stopOpacity="1" stopColor="rgb(63.238525%, 39.64386%, 85.595703%)" offset="0.777344"/>
          <stop stopOpacity="1" stopColor="rgb(63.569641%, 39.700317%, 85.688782%)" offset="0.78125"/>
          <stop stopOpacity="1" stopColor="rgb(63.899231%, 39.755249%, 85.78186%)" offset="0.785156"/>
          <stop stopOpacity="1" stopColor="rgb(64.230347%, 39.811707%, 85.874939%)" offset="0.789063"/>
          <stop stopOpacity="1" stopColor="rgb(64.559937%, 39.866638%, 85.966492%)" offset="0.792969"/>
          <stop stopOpacity="1" stopColor="rgb(64.891052%, 39.923096%, 86.05957%)" offset="0.796875"/>
          <stop stopOpacity="1" stopColor="rgb(65.220642%, 39.978027%, 86.152649%)" offset="0.800781"/>
          <stop stopOpacity="1" stopColor="rgb(65.550232%, 40.034485%, 86.245728%)" offset="0.804688"/>
          <stop stopOpacity="1" stopColor="rgb(65.879822%, 40.089417%, 86.338806%)" offset="0.808594"/>
          <stop stopOpacity="1" stopColor="rgb(66.210938%, 40.145874%, 86.431885%)" offset="0.8125"/>
          <stop stopOpacity="1" stopColor="rgb(66.540527%, 40.200806%, 86.524963%)" offset="0.816406"/>
          <stop stopOpacity="1" stopColor="rgb(66.871643%, 40.257263%, 86.618042%)" offset="0.820313"/>
          <stop stopOpacity="1" stopColor="rgb(67.201233%, 40.312195%, 86.709595%)" offset="0.824219"/>
          <stop stopOpacity="1" stopColor="rgb(67.530823%, 40.367126%, 86.802673%)" offset="0.828125"/>
          <stop stopOpacity="1" stopColor="rgb(67.860413%, 40.422058%, 86.895752%)" offset="0.832031"/>
          <stop stopOpacity="1" stopColor="rgb(68.191528%, 40.478516%, 86.988831%)" offset="0.835938"/>
          <stop stopOpacity="1" stopColor="rgb(68.521118%, 40.533447%, 87.081909%)" offset="0.839844"/>
          <stop stopOpacity="1" stopColor="rgb(68.852234%, 40.589905%, 87.174988%)" offset="0.84375"/>
          <stop stopOpacity="1" stopColor="rgb(69.181824%, 40.644836%, 87.268066%)" offset="0.847656"/>
          <stop stopOpacity="1" stopColor="rgb(69.512939%, 40.701294%, 87.361145%)" offset="0.851563"/>
          <stop stopOpacity="1" stopColor="rgb(69.842529%, 40.756226%, 87.452698%)" offset="0.855469"/>
          <stop stopOpacity="1" stopColor="rgb(70.172119%, 40.812683%, 87.545776%)" offset="0.859375"/>
          <stop stopOpacity="1" stopColor="rgb(70.501709%, 40.867615%, 87.638855%)" offset="0.863281"/>
          <stop stopOpacity="1" stopColor="rgb(70.832825%, 40.924072%, 87.731934%)" offset="0.867188"/>
          <stop stopOpacity="1" stopColor="rgb(71.162415%, 40.979004%, 87.825012%)" offset="0.871094"/>
          <stop stopOpacity="1" stopColor="rgb(71.49353%, 41.033936%, 87.918091%)" offset="0.875"/>
          <stop stopOpacity="1" stopColor="rgb(71.82312%, 41.088867%, 88.011169%)" offset="0.878906"/>
          <stop stopOpacity="1" stopColor="rgb(72.154236%, 41.145325%, 88.104248%)" offset="0.882813"/>
          <stop stopOpacity="1" stopColor="rgb(72.483826%, 41.200256%, 88.197327%)" offset="0.886719"/>
          <stop stopOpacity="1" stopColor="rgb(72.813416%, 41.256714%, 88.290405%)" offset="0.890625"/>
          <stop stopOpacity="1" stopColor="rgb(73.143005%, 41.311646%, 88.381958%)" offset="0.894531"/>
          <stop stopOpacity="1" stopColor="rgb(73.474121%, 41.368103%, 88.475037%)" offset="0.898438"/>
          <stop stopOpacity="1" stopColor="rgb(73.803711%, 41.423035%, 88.568115%)" offset="0.902344"/>
          <stop stopOpacity="1" stopColor="rgb(74.134827%, 41.479492%, 88.661194%)" offset="0.90625"/>
          <stop stopOpacity="1" stopColor="rgb(74.464417%, 41.534424%, 88.754272%)" offset="0.910156"/>
          <stop stopOpacity="1" stopColor="rgb(74.794006%, 41.590881%, 88.847351%)" offset="0.914063"/>
          <stop stopOpacity="1" stopColor="rgb(75.123596%, 41.645813%, 88.94043%)" offset="0.917969"/>
          <stop stopOpacity="1" stopColor="rgb(75.454712%, 41.702271%, 89.033508%)" offset="0.921875"/>
          <stop stopOpacity="1" stopColor="rgb(75.784302%, 41.757202%, 89.125061%)" offset="0.925781"/>
          <stop stopOpacity="1" stopColor="rgb(76.115417%, 41.812134%, 89.21814%)" offset="0.929688"/>
          <stop stopOpacity="1" stopColor="rgb(76.445007%, 41.867065%, 89.311218%)" offset="0.933594"/>
          <stop stopOpacity="1" stopColor="rgb(76.776123%, 41.923523%, 89.404297%)" offset="0.9375"/>
          <stop stopOpacity="1" stopColor="rgb(77.105713%, 41.978455%, 89.497375%)" offset="0.941406"/>
          <stop stopOpacity="1" stopColor="rgb(77.435303%, 42.034912%, 89.590454%)" offset="0.945312"/>
          <stop stopOpacity="1" stopColor="rgb(77.764893%, 42.089844%, 89.683533%)" offset="0.949219"/>
          <stop stopOpacity="1" stopColor="rgb(78.096008%, 42.146301%, 89.776611%)" offset="0.953125"/>
          <stop stopOpacity="1" stopColor="rgb(78.425598%, 42.201233%, 89.86969%)" offset="0.957031"/>
          <stop stopOpacity="1" stopColor="rgb(78.756714%, 42.25769%, 89.962769%)" offset="0.960937"/>
          <stop stopOpacity="1" stopColor="rgb(79.086304%, 42.312622%, 90.054321%)" offset="0.964844"/>
          <stop stopOpacity="1" stopColor="rgb(79.417419%, 42.36908%, 90.1474%)" offset="0.96875"/>
          <stop stopOpacity="1" stopColor="rgb(79.507446%, 42.384338%, 90.17334%)" offset="1"/>
        </linearGradient>
        <linearGradient x1="-2.187906"
                        gradientTransform="matrix(1, -0.00000000135472, 0.00000000135472, 1, 0.000001517, -0.00000840113)"
                        y1="2.5" x2="73.025989" gradientUnits="userSpaceOnUse" y2="2.5" id="69cea76642">
          <stop stopOpacity="1" stopColor="rgb(0%, 28.999329%, 67.799377%)" offset="0"/>
          <stop stopOpacity="1" stopColor="rgb(0.0900269%, 29.014587%, 67.825317%)" offset="0.03125"/>
          <stop stopOpacity="1" stopColor="rgb(0.511169%, 29.084778%, 67.94281%)" offset="0.0351563"/>
          <stop stopOpacity="1" stopColor="rgb(0.842285%, 29.141235%, 68.035889%)" offset="0.0390625"/>
          <stop stopOpacity="1" stopColor="rgb(1.171875%, 29.196167%, 68.128967%)" offset="0.0429688"/>
          <stop stopOpacity="1" stopColor="rgb(1.502991%, 29.252625%, 68.222046%)" offset="0.046875"/>
          <stop stopOpacity="1" stopColor="rgb(1.832581%, 29.307556%, 68.315125%)" offset="0.0507812"/>
          <stop stopOpacity="1" stopColor="rgb(2.16217%, 29.364014%, 68.408203%)" offset="0.0546875"/>
          <stop stopOpacity="1" stopColor="rgb(2.49176%, 29.418945%, 68.501282%)" offset="0.0585938"/>
          <stop stopOpacity="1" stopColor="rgb(2.822876%, 29.473877%, 68.59436%)" offset="0.0625"/>
          <stop stopOpacity="1" stopColor="rgb(3.152466%, 29.528809%, 68.685913%)" offset="0.0664063"/>
          <stop stopOpacity="1" stopColor="rgb(3.483582%, 29.585266%, 68.778992%)" offset="0.0703125"/>
          <stop stopOpacity="1" stopColor="rgb(3.813171%, 29.640198%, 68.87207%)" offset="0.0742188"/>
          <stop stopOpacity="1" stopColor="rgb(4.144287%, 29.696655%, 68.965149%)" offset="0.078125"/>
          <stop stopOpacity="1" stopColor="rgb(4.473877%, 29.751587%, 69.058228%)" offset="0.0820313"/>
          <stop stopOpacity="1" stopColor="rgb(4.803467%, 29.808044%, 69.151306%)" offset="0.0859375"/>
          <stop stopOpacity="1" stopColor="rgb(5.133057%, 29.862976%, 69.244385%)" offset="0.0898438"/>
          <stop stopOpacity="1" stopColor="rgb(5.464172%, 29.919434%, 69.337463%)" offset="0.09375"/>
          <stop stopOpacity="1" stopColor="rgb(5.793762%, 29.974365%, 69.430542%)" offset="0.0976562"/>
          <stop stopOpacity="1" stopColor="rgb(6.124878%, 30.030823%, 69.523621%)" offset="0.101562"/>
          <stop stopOpacity="1" stopColor="rgb(6.454468%, 30.085754%, 69.615173%)" offset="0.105469"/>
          <stop stopOpacity="1" stopColor="rgb(6.784058%, 30.142212%, 69.708252%)" offset="0.109375"/>
          <stop stopOpacity="1" stopColor="rgb(7.113647%, 30.197144%, 69.801331%)" offset="0.113281"/>
          <stop stopOpacity="1" stopColor="rgb(7.444763%, 30.252075%, 69.894409%)" offset="0.117188"/>
          <stop stopOpacity="1" stopColor="rgb(7.774353%, 30.307007%, 69.987488%)" offset="0.121094"/>
          <stop stopOpacity="1" stopColor="rgb(8.105469%, 30.363464%, 70.080566%)" offset="0.125"/>
          <stop stopOpacity="1" stopColor="rgb(8.435059%, 30.418396%, 70.173645%)" offset="0.128906"/>
          <stop stopOpacity="1" stopColor="rgb(8.766174%, 30.474854%, 70.266724%)" offset="0.132812"/>
          <stop stopOpacity="1" stopColor="rgb(9.095764%, 30.529785%, 70.358276%)" offset="0.136719"/>
          <stop stopOpacity="1" stopColor="rgb(9.425354%, 30.586243%, 70.451355%)" offset="0.140625"/>
          <stop stopOpacity="1" stopColor="rgb(9.754944%, 30.641174%, 70.544434%)" offset="0.144531"/>
          <stop stopOpacity="1" stopColor="rgb(10.08606%, 30.697632%, 70.637512%)" offset="0.148438"/>
          <stop stopOpacity="1" stopColor="rgb(10.415649%, 30.752563%, 70.730591%)" offset="0.152344"/>
          <stop stopOpacity="1" stopColor="rgb(10.746765%, 30.809021%, 70.823669%)" offset="0.15625"/>
          <stop stopOpacity="1" stopColor="rgb(11.076355%, 30.863953%, 70.916748%)" offset="0.160156"/>
          <stop stopOpacity="1" stopColor="rgb(11.407471%, 30.918884%, 71.009827%)" offset="0.164062"/>
          <stop stopOpacity="1" stopColor="rgb(11.737061%, 30.973816%, 71.101379%)" offset="0.167969"/>
          <stop stopOpacity="1" stopColor="rgb(12.06665%, 31.030273%, 71.194458%)" offset="0.171875"/>
          <stop stopOpacity="1" stopColor="rgb(12.39624%, 31.085205%, 71.287537%)" offset="0.175781"/>
          <stop stopOpacity="1" stopColor="rgb(12.727356%, 31.141663%, 71.380615%)" offset="0.179688"/>
          <stop stopOpacity="1" stopColor="rgb(13.056946%, 31.196594%, 71.473694%)" offset="0.183594"/>
          <stop stopOpacity="1" stopColor="rgb(13.388062%, 31.253052%, 71.566772%)" offset="0.1875"/>
          <stop stopOpacity="1" stopColor="rgb(13.717651%, 31.307983%, 71.659851%)" offset="0.191406"/>
          <stop stopOpacity="1" stopColor="rgb(14.047241%, 31.364441%, 71.75293%)" offset="0.195313"/>
          <stop stopOpacity="1" stopColor="rgb(14.376831%, 31.419373%, 71.846008%)" offset="0.199219"/>
          <stop stopOpacity="1" stopColor="rgb(14.707947%, 31.47583%, 71.939087%)" offset="0.203125"/>
          <stop stopOpacity="1" stopColor="rgb(15.037537%, 31.530762%, 72.03064%)" offset="0.207031"/>
          <stop stopOpacity="1" stopColor="rgb(15.368652%, 31.587219%, 72.123718%)" offset="0.210938"/>
          <stop stopOpacity="1" stopColor="rgb(15.698242%, 31.642151%, 72.216797%)" offset="0.214844"/>
          <stop stopOpacity="1" stopColor="rgb(16.029358%, 31.697083%, 72.309875%)" offset="0.21875"/>
          <stop stopOpacity="1" stopColor="rgb(16.358948%, 31.752014%, 72.402954%)" offset="0.222656"/>
          <stop stopOpacity="1" stopColor="rgb(16.688538%, 31.808472%, 72.496033%)" offset="0.226562"/>
          <stop stopOpacity="1" stopColor="rgb(17.018127%, 31.863403%, 72.589111%)" offset="0.230469"/>
          <stop stopOpacity="1" stopColor="rgb(17.349243%, 31.919861%, 72.68219%)" offset="0.234375"/>
          <stop stopOpacity="1" stopColor="rgb(17.678833%, 31.974792%, 72.773743%)" offset="0.238281"/>
          <stop stopOpacity="1" stopColor="rgb(18.009949%, 32.03125%, 72.866821%)" offset="0.242188"/>
          <stop stopOpacity="1" stopColor="rgb(18.339539%, 32.086182%, 72.9599%)" offset="0.246094"/>
          <stop stopOpacity="1" stopColor="rgb(18.670654%, 32.142639%, 73.052979%)" offset="0.25"/>
          <stop stopOpacity="1" stopColor="rgb(19.000244%, 32.197571%, 73.146057%)" offset="0.253906"/>
          <stop stopOpacity="1" stopColor="rgb(19.329834%, 32.254028%, 73.239136%)" offset="0.257813"/>
          <stop stopOpacity="1" stopColor="rgb(19.659424%, 32.30896%, 73.332214%)" offset="0.261719"/>
          <stop stopOpacity="1" stopColor="rgb(19.99054%, 32.363892%, 73.425293%)" offset="0.265625"/>
          <stop stopOpacity="1" stopColor="rgb(20.320129%, 32.418823%, 73.518372%)" offset="0.269531"/>
          <stop stopOpacity="1" stopColor="rgb(20.651245%, 32.475281%, 73.61145%)" offset="0.273438"/>
          <stop stopOpacity="1" stopColor="rgb(20.980835%, 32.530212%, 73.703003%)" offset="0.277344"/>
          <stop stopOpacity="1" stopColor="rgb(21.310425%, 32.58667%, 73.796082%)" offset="0.28125"/>
          <stop stopOpacity="1" stopColor="rgb(21.640015%, 32.641602%, 73.88916%)" offset="0.285156"/>
          <stop stopOpacity="1" stopColor="rgb(21.97113%, 32.698059%, 73.982239%)" offset="0.289063"/>
          <stop stopOpacity="1" stopColor="rgb(22.30072%, 32.752991%, 74.075317%)" offset="0.292969"/>
          <stop stopOpacity="1" stopColor="rgb(22.631836%, 32.809448%, 74.168396%)" offset="0.296875"/>
          <stop stopOpacity="1" stopColor="rgb(22.961426%, 32.86438%, 74.261475%)" offset="0.300781"/>
          <stop stopOpacity="1" stopColor="rgb(23.292542%, 32.920837%, 74.354553%)" offset="0.304688"/>
          <stop stopOpacity="1" stopColor="rgb(23.622131%, 32.975769%, 74.446106%)" offset="0.308594"/>
          <stop stopOpacity="1" stopColor="rgb(23.951721%, 33.032227%, 74.539185%)" offset="0.3125"/>
          <stop stopOpacity="1" stopColor="rgb(24.281311%, 33.087158%, 74.632263%)" offset="0.316406"/>
          <stop stopOpacity="1" stopColor="rgb(24.612427%, 33.14209%, 74.725342%)" offset="0.320313"/>
          <stop stopOpacity="1" stopColor="rgb(24.942017%, 33.197021%, 74.81842%)" offset="0.324219"/>
          <stop stopOpacity="1" stopColor="rgb(25.273132%, 33.253479%, 74.911499%)" offset="0.328125"/>
          <stop stopOpacity="1" stopColor="rgb(25.602722%, 33.308411%, 75.004578%)" offset="0.332031"/>
          <stop stopOpacity="1" stopColor="rgb(25.933838%, 33.364868%, 75.097656%)" offset="0.335938"/>
          <stop stopOpacity="1" stopColor="rgb(26.263428%, 33.4198%, 75.189209%)" offset="0.339844"/>
          <stop stopOpacity="1" stopColor="rgb(26.593018%, 33.476257%, 75.282288%)" offset="0.34375"/>
          <stop stopOpacity="1" stopColor="rgb(26.922607%, 33.531189%, 75.375366%)" offset="0.347656"/>
          <stop stopOpacity="1" stopColor="rgb(27.253723%, 33.587646%, 75.468445%)" offset="0.351563"/>
          <stop stopOpacity="1" stopColor="rgb(27.583313%, 33.642578%, 75.561523%)" offset="0.355469"/>
          <stop stopOpacity="1" stopColor="rgb(27.914429%, 33.699036%, 75.654602%)" offset="0.359375"/>
          <stop stopOpacity="1" stopColor="rgb(28.244019%, 33.753967%, 75.747681%)" offset="0.363281"/>
          <stop stopOpacity="1" stopColor="rgb(28.573608%, 33.808899%, 75.840759%)" offset="0.367188"/>
          <stop stopOpacity="1" stopColor="rgb(28.903198%, 33.863831%, 75.933838%)" offset="0.371094"/>
          <stop stopOpacity="1" stopColor="rgb(29.234314%, 33.920288%, 76.026917%)" offset="0.375"/>
          <stop stopOpacity="1" stopColor="rgb(29.563904%, 33.97522%, 76.118469%)" offset="0.378906"/>
          <stop stopOpacity="1" stopColor="rgb(29.89502%, 34.031677%, 76.211548%)" offset="0.382813"/>
          <stop stopOpacity="1" stopColor="rgb(30.224609%, 34.086609%, 76.304626%)" offset="0.386719"/>
          <stop stopOpacity="1" stopColor="rgb(30.555725%, 34.143066%, 76.397705%)" offset="0.390625"/>
          <stop stopOpacity="1" stopColor="rgb(30.885315%, 34.197998%, 76.490784%)" offset="0.394531"/>
          <stop stopOpacity="1" stopColor="rgb(31.214905%, 34.254456%, 76.583862%)" offset="0.398438"/>
          <stop stopOpacity="1" stopColor="rgb(31.544495%, 34.309387%, 76.676941%)" offset="0.402344"/>
          <stop stopOpacity="1" stopColor="rgb(31.87561%, 34.365845%, 76.77002%)" offset="0.40625"/>
          <stop stopOpacity="1" stopColor="rgb(32.2052%, 34.420776%, 76.861572%)" offset="0.410156"/>
          <stop stopOpacity="1" stopColor="rgb(32.536316%, 34.477234%, 76.954651%)" offset="0.414063"/>
          <stop stopOpacity="1" stopColor="rgb(32.865906%, 34.532166%, 77.047729%)" offset="0.417969"/>
          <stop stopOpacity="1" stopColor="rgb(33.197021%, 34.587097%, 77.140808%)" offset="0.421875"/>
          <stop stopOpacity="1" stopColor="rgb(33.526611%, 34.642029%, 77.233887%)" offset="0.425781"/>
          <stop stopOpacity="1" stopColor="rgb(33.856201%, 34.698486%, 77.326965%)" offset="0.429688"/>
          <stop stopOpacity="1" stopColor="rgb(34.185791%, 34.753418%, 77.420044%)" offset="0.433594"/>
          <stop stopOpacity="1" stopColor="rgb(34.516907%, 34.809875%, 77.513123%)" offset="0.4375"/>
          <stop stopOpacity="1" stopColor="rgb(34.846497%, 34.864807%, 77.606201%)" offset="0.441406"/>
          <stop stopOpacity="1" stopColor="rgb(35.177612%, 34.921265%, 77.69928%)" offset="0.445313"/>
          <stop stopOpacity="1" stopColor="rgb(35.507202%, 34.976196%, 77.790833%)" offset="0.449219"/>
          <stop stopOpacity="1" stopColor="rgb(35.836792%, 35.032654%, 77.883911%)" offset="0.453125"/>
          <stop stopOpacity="1" stopColor="rgb(36.166382%, 35.087585%, 77.97699%)" offset="0.457031"/>
          <stop stopOpacity="1" stopColor="rgb(36.497498%, 35.144043%, 78.070068%)" offset="0.460938"/>
          <stop stopOpacity="1" stopColor="rgb(36.827087%, 35.198975%, 78.163147%)" offset="0.464844"/>
          <stop stopOpacity="1" stopColor="rgb(37.158203%, 35.253906%, 78.256226%)" offset="0.46875"/>
          <stop stopOpacity="1" stopColor="rgb(37.487793%, 35.308838%, 78.349304%)" offset="0.472656"/>
          <stop stopOpacity="1" stopColor="rgb(37.818909%, 35.365295%, 78.442383%)" offset="0.476563"/>
          <stop stopOpacity="1" stopColor="rgb(38.148499%, 35.420227%, 78.533936%)" offset="0.480469"/>
          <stop stopOpacity="1" stopColor="rgb(38.478088%, 35.476685%, 78.627014%)" offset="0.484375"/>
          <stop stopOpacity="1" stopColor="rgb(38.807678%, 35.531616%, 78.720093%)" offset="0.488281"/>
          <stop stopOpacity="1" stopColor="rgb(39.138794%, 35.588074%, 78.813171%)" offset="0.492188"/>
          <stop stopOpacity="1" stopColor="rgb(39.468384%, 35.643005%, 78.90625%)" offset="0.496094"/>
          <stop stopOpacity="1" stopColor="rgb(39.7995%, 35.699463%, 78.999329%)" offset="0.5"/>
          <stop stopOpacity="1" stopColor="rgb(40.129089%, 35.754395%, 79.092407%)" offset="0.503906"/>
          <stop stopOpacity="1" stopColor="rgb(40.460205%, 35.810852%, 79.185486%)" offset="0.507813"/>
          <stop stopOpacity="1" stopColor="rgb(40.789795%, 35.865784%, 79.277039%)" offset="0.511719"/>
          <stop stopOpacity="1" stopColor="rgb(41.119385%, 35.922241%, 79.370117%)" offset="0.515625"/>
          <stop stopOpacity="1" stopColor="rgb(41.448975%, 35.977173%, 79.463196%)" offset="0.519531"/>
          <stop stopOpacity="1" stopColor="rgb(41.78009%, 36.032104%, 79.556274%)" offset="0.523438"/>
          <stop stopOpacity="1" stopColor="rgb(42.10968%, 36.087036%, 79.649353%)" offset="0.527344"/>
          <stop stopOpacity="1" stopColor="rgb(42.440796%, 36.143494%, 79.742432%)" offset="0.53125"/>
          <stop stopOpacity="1" stopColor="rgb(42.770386%, 36.198425%, 79.83551%)" offset="0.535156"/>
          <stop stopOpacity="1" stopColor="rgb(43.099976%, 36.254883%, 79.928589%)" offset="0.539063"/>
          <stop stopOpacity="1" stopColor="rgb(43.429565%, 36.309814%, 80.021667%)" offset="0.542969"/>
          <stop stopOpacity="1" stopColor="rgb(43.760681%, 36.366272%, 80.114746%)" offset="0.546875"/>
          <stop stopOpacity="1" stopColor="rgb(44.090271%, 36.421204%, 80.206299%)" offset="0.550781"/>
          <stop stopOpacity="1" stopColor="rgb(44.421387%, 36.477661%, 80.299377%)" offset="0.554688"/>
          <stop stopOpacity="1" stopColor="rgb(44.750977%, 36.532593%, 80.392456%)" offset="0.558594"/>
          <stop stopOpacity="1" stopColor="rgb(45.082092%, 36.58905%, 80.485535%)" offset="0.5625"/>
          <stop stopOpacity="1" stopColor="rgb(45.411682%, 36.643982%, 80.578613%)" offset="0.566406"/>
          <stop stopOpacity="1" stopColor="rgb(45.741272%, 36.698914%, 80.671692%)" offset="0.570313"/>
          <stop stopOpacity="1" stopColor="rgb(46.070862%, 36.753845%, 80.764771%)" offset="0.574219"/>
          <stop stopOpacity="1" stopColor="rgb(46.401978%, 36.810303%, 80.857849%)" offset="0.578125"/>
          <stop stopOpacity="1" stopColor="rgb(46.731567%, 36.865234%, 80.949402%)" offset="0.582031"/>
          <stop stopOpacity="1" stopColor="rgb(47.062683%, 36.921692%, 81.04248%)" offset="0.585938"/>
          <stop stopOpacity="1" stopColor="rgb(47.392273%, 36.976624%, 81.135559%)" offset="0.589844"/>
          <stop stopOpacity="1" stopColor="rgb(47.723389%, 37.033081%, 81.228638%)" offset="0.59375"/>
          <stop stopOpacity="1" stopColor="rgb(48.052979%, 37.088013%, 81.321716%)" offset="0.597656"/>
          <stop stopOpacity="1" stopColor="rgb(48.382568%, 37.14447%, 81.414795%)" offset="0.601563"/>
          <stop stopOpacity="1" stopColor="rgb(48.712158%, 37.199402%, 81.507874%)" offset="0.605469"/>
          <stop stopOpacity="1" stopColor="rgb(49.043274%, 37.255859%, 81.600952%)" offset="0.609375"/>
          <stop stopOpacity="1" stopColor="rgb(49.372864%, 37.310791%, 81.694031%)" offset="0.613281"/>
          <stop stopOpacity="1" stopColor="rgb(49.703979%, 37.367249%, 81.787109%)" offset="0.617188"/>
          <stop stopOpacity="1" stopColor="rgb(50.033569%, 37.42218%, 81.878662%)" offset="0.621094"/>
          <stop stopOpacity="1" stopColor="rgb(50.364685%, 37.477112%, 81.971741%)" offset="0.625"/>
          <stop stopOpacity="1" stopColor="rgb(50.694275%, 37.532043%, 82.064819%)" offset="0.628906"/>
          <stop stopOpacity="1" stopColor="rgb(51.023865%, 37.588501%, 82.157898%)" offset="0.632813"/>
          <stop stopOpacity="1" stopColor="rgb(51.353455%, 37.643433%, 82.250977%)" offset="0.636719"/>
          <stop stopOpacity="1" stopColor="rgb(51.68457%, 37.69989%, 82.344055%)" offset="0.640625"/>
          <stop stopOpacity="1" stopColor="rgb(52.01416%, 37.754822%, 82.437134%)" offset="0.644531"/>
          <stop stopOpacity="1" stopColor="rgb(52.345276%, 37.811279%, 82.530212%)" offset="0.648438"/>
          <stop stopOpacity="1" stopColor="rgb(52.674866%, 37.866211%, 82.621765%)" offset="0.652344"/>
          <stop stopOpacity="1" stopColor="rgb(53.004456%, 37.922668%, 82.714844%)" offset="0.65625"/>
          <stop stopOpacity="1" stopColor="rgb(53.334045%, 37.9776%, 82.807922%)" offset="0.660156"/>
          <stop stopOpacity="1" stopColor="rgb(53.665161%, 38.034058%, 82.901001%)" offset="0.664063"/>
          <stop stopOpacity="1" stopColor="rgb(53.994751%, 38.088989%, 82.99408%)" offset="0.667969"/>
          <stop stopOpacity="1" stopColor="rgb(54.325867%, 38.143921%, 83.087158%)" offset="0.671875"/>
          <stop stopOpacity="1" stopColor="rgb(54.655457%, 38.198853%, 83.180237%)" offset="0.675781"/>
          <stop stopOpacity="1" stopColor="rgb(54.986572%, 38.25531%, 83.273315%)" offset="0.679688"/>
          <stop stopOpacity="1" stopColor="rgb(55.316162%, 38.310242%, 83.364868%)" offset="0.683594"/>
          <stop stopOpacity="1" stopColor="rgb(55.645752%, 38.366699%, 83.457947%)" offset="0.6875"/>
          <stop stopOpacity="1" stopColor="rgb(55.975342%, 38.421631%, 83.551025%)" offset="0.691406"/>
          <stop stopOpacity="1" stopColor="rgb(56.306458%, 38.478088%, 83.644104%)" offset="0.695312"/>
          <stop stopOpacity="1" stopColor="rgb(56.636047%, 38.53302%, 83.737183%)" offset="0.699219"/>
          <stop stopOpacity="1" stopColor="rgb(56.967163%, 38.589478%, 83.830261%)" offset="0.703125"/>
          <stop stopOpacity="1" stopColor="rgb(57.296753%, 38.644409%, 83.92334%)" offset="0.707031"/>
          <stop stopOpacity="1" stopColor="rgb(57.627869%, 38.700867%, 84.016418%)" offset="0.710938"/>
          <stop stopOpacity="1" stopColor="rgb(57.957458%, 38.755798%, 84.109497%)" offset="0.714844"/>
          <stop stopOpacity="1" stopColor="rgb(58.287048%, 38.812256%, 84.202576%)" offset="0.71875"/>
          <stop stopOpacity="1" stopColor="rgb(58.616638%, 38.867188%, 84.294128%)" offset="0.722656"/>
          <stop stopOpacity="1" stopColor="rgb(58.947754%, 38.922119%, 84.387207%)" offset="0.726563"/>
          <stop stopOpacity="1" stopColor="rgb(59.277344%, 38.977051%, 84.480286%)" offset="0.730469"/>
          <stop stopOpacity="1" stopColor="rgb(59.608459%, 39.033508%, 84.573364%)" offset="0.734375"/>
          <stop stopOpacity="1" stopColor="rgb(59.938049%, 39.08844%, 84.666443%)" offset="0.738281"/>
          <stop stopOpacity="1" stopColor="rgb(60.267639%, 39.144897%, 84.759521%)" offset="0.742188"/>
          <stop stopOpacity="1" stopColor="rgb(60.597229%, 39.199829%, 84.8526%)" offset="0.746094"/>
          <stop stopOpacity="1" stopColor="rgb(60.928345%, 39.256287%, 84.945679%)" offset="0.75"/>
          <stop stopOpacity="1" stopColor="rgb(61.257935%, 39.311218%, 85.037231%)" offset="0.753906"/>
          <stop stopOpacity="1" stopColor="rgb(61.58905%, 39.367676%, 85.13031%)" offset="0.757813"/>
          <stop stopOpacity="1" stopColor="rgb(61.91864%, 39.422607%, 85.223389%)" offset="0.761719"/>
          <stop stopOpacity="1" stopColor="rgb(62.249756%, 39.479065%, 85.316467%)" offset="0.765625"/>
          <stop stopOpacity="1" stopColor="rgb(62.579346%, 39.533997%, 85.409546%)" offset="0.769531"/>
          <stop stopOpacity="1" stopColor="rgb(62.908936%, 39.588928%, 85.502625%)" offset="0.773438"/>
          <stop stopOpacity="1" stopColor="rgb(63.238525%, 39.64386%, 85.595703%)" offset="0.777344"/>
          <stop stopOpacity="1" stopColor="rgb(63.569641%, 39.700317%, 85.688782%)" offset="0.78125"/>
          <stop stopOpacity="1" stopColor="rgb(63.899231%, 39.755249%, 85.78186%)" offset="0.785156"/>
          <stop stopOpacity="1" stopColor="rgb(64.230347%, 39.811707%, 85.874939%)" offset="0.789063"/>
          <stop stopOpacity="1" stopColor="rgb(64.559937%, 39.866638%, 85.966492%)" offset="0.792969"/>
          <stop stopOpacity="1" stopColor="rgb(64.891052%, 39.923096%, 86.05957%)" offset="0.796875"/>
          <stop stopOpacity="1" stopColor="rgb(65.220642%, 39.978027%, 86.152649%)" offset="0.800781"/>
          <stop stopOpacity="1" stopColor="rgb(65.550232%, 40.034485%, 86.245728%)" offset="0.804688"/>
          <stop stopOpacity="1" stopColor="rgb(65.879822%, 40.089417%, 86.338806%)" offset="0.808594"/>
          <stop stopOpacity="1" stopColor="rgb(66.210938%, 40.145874%, 86.431885%)" offset="0.8125"/>
          <stop stopOpacity="1" stopColor="rgb(66.540527%, 40.200806%, 86.524963%)" offset="0.816406"/>
          <stop stopOpacity="1" stopColor="rgb(66.871643%, 40.257263%, 86.618042%)" offset="0.820313"/>
          <stop stopOpacity="1" stopColor="rgb(67.201233%, 40.312195%, 86.709595%)" offset="0.824219"/>
          <stop stopOpacity="1" stopColor="rgb(67.530823%, 40.367126%, 86.802673%)" offset="0.828125"/>
          <stop stopOpacity="1" stopColor="rgb(67.860413%, 40.422058%, 86.895752%)" offset="0.832031"/>
          <stop stopOpacity="1" stopColor="rgb(68.191528%, 40.478516%, 86.988831%)" offset="0.835938"/>
          <stop stopOpacity="1" stopColor="rgb(68.521118%, 40.533447%, 87.081909%)" offset="0.839844"/>
          <stop stopOpacity="1" stopColor="rgb(68.852234%, 40.589905%, 87.174988%)" offset="0.84375"/>
          <stop stopOpacity="1" stopColor="rgb(69.181824%, 40.644836%, 87.268066%)" offset="0.847656"/>
          <stop stopOpacity="1" stopColor="rgb(69.512939%, 40.701294%, 87.361145%)" offset="0.851563"/>
          <stop stopOpacity="1" stopColor="rgb(69.842529%, 40.756226%, 87.452698%)" offset="0.855469"/>
          <stop stopOpacity="1" stopColor="rgb(70.172119%, 40.812683%, 87.545776%)" offset="0.859375"/>
          <stop stopOpacity="1" stopColor="rgb(70.501709%, 40.867615%, 87.638855%)" offset="0.863281"/>
          <stop stopOpacity="1" stopColor="rgb(70.832825%, 40.924072%, 87.731934%)" offset="0.867188"/>
          <stop stopOpacity="1" stopColor="rgb(71.162415%, 40.979004%, 87.825012%)" offset="0.871094"/>
          <stop stopOpacity="1" stopColor="rgb(71.49353%, 41.033936%, 87.918091%)" offset="0.875"/>
          <stop stopOpacity="1" stopColor="rgb(71.82312%, 41.088867%, 88.011169%)" offset="0.878906"/>
          <stop stopOpacity="1" stopColor="rgb(72.154236%, 41.145325%, 88.104248%)" offset="0.882812"/>
          <stop stopOpacity="1" stopColor="rgb(72.483826%, 41.200256%, 88.197327%)" offset="0.886719"/>
          <stop stopOpacity="1" stopColor="rgb(72.813416%, 41.256714%, 88.290405%)" offset="0.890625"/>
          <stop stopOpacity="1" stopColor="rgb(73.143005%, 41.311646%, 88.381958%)" offset="0.894531"/>
          <stop stopOpacity="1" stopColor="rgb(73.474121%, 41.368103%, 88.475037%)" offset="0.898438"/>
          <stop stopOpacity="1" stopColor="rgb(73.803711%, 41.423035%, 88.568115%)" offset="0.902344"/>
          <stop stopOpacity="1" stopColor="rgb(74.134827%, 41.479492%, 88.661194%)" offset="0.90625"/>
          <stop stopOpacity="1" stopColor="rgb(74.464417%, 41.534424%, 88.754272%)" offset="0.910156"/>
          <stop stopOpacity="1" stopColor="rgb(74.794006%, 41.590881%, 88.847351%)" offset="0.914063"/>
          <stop stopOpacity="1" stopColor="rgb(75.123596%, 41.645813%, 88.94043%)" offset="0.917969"/>
          <stop stopOpacity="1" stopColor="rgb(75.454712%, 41.702271%, 89.033508%)" offset="0.921875"/>
          <stop stopOpacity="1" stopColor="rgb(75.784302%, 41.757202%, 89.125061%)" offset="0.925781"/>
          <stop stopOpacity="1" stopColor="rgb(76.115417%, 41.812134%, 89.21814%)" offset="0.929688"/>
          <stop stopOpacity="1" stopColor="rgb(76.445007%, 41.867065%, 89.311218%)" offset="0.933594"/>
          <stop stopOpacity="1" stopColor="rgb(76.776123%, 41.923523%, 89.404297%)" offset="0.9375"/>
          <stop stopOpacity="1" stopColor="rgb(77.105713%, 41.978455%, 89.497375%)" offset="0.941406"/>
          <stop stopOpacity="1" stopColor="rgb(77.435303%, 42.034912%, 89.590454%)" offset="0.945312"/>
          <stop stopOpacity="1" stopColor="rgb(77.764893%, 42.089844%, 89.683533%)" offset="0.949219"/>
          <stop stopOpacity="1" stopColor="rgb(78.096008%, 42.146301%, 89.776611%)" offset="0.953125"/>
          <stop stopOpacity="1" stopColor="rgb(78.425598%, 42.201233%, 89.86969%)" offset="0.957031"/>
          <stop stopOpacity="1" stopColor="rgb(78.756714%, 42.25769%, 89.962769%)" offset="0.960937"/>
          <stop stopOpacity="1" stopColor="rgb(79.086304%, 42.312622%, 90.054321%)" offset="0.964844"/>
          <stop stopOpacity="1" stopColor="rgb(79.417419%, 42.36908%, 90.1474%)" offset="0.96875"/>
          <stop stopOpacity="1" stopColor="rgb(79.507446%, 42.384338%, 90.17334%)" offset="1"/>
        </linearGradient>
      </defs>
      <g clipPath="url(#492733c605)">
        <g clipPath="url(#b77456e5c6)">
          <path fill="url(#c99b5c78f6)"
                d="M 108.144531 19.199219 L 108.144531 112.507812 L 201.453125 112.507812 L 201.453125 19.199219 Z M 108.144531 19.199219 "
                fillRule="nonzero"/>
        </g>
      </g>
      <g clipPath="url(#7570c1c446)">
        <g clipPath="url(#4a928b27db)">
          <path fill="url(#bbc8435cdd)"
                d="M 33.746094 145.34375 L 33.746094 238.65625 L 127.054688 238.65625 L 127.054688 145.34375 Z M 33.746094 145.34375 "
                fillRule="nonzero"/>
        </g>
      </g>
      <g clipPath="url(#55836b9904)">
        <g clipPath="url(#ba509a4e8c)">
          <path fill="url(#a7df0cc7ef)"
                d="M 182.546875 145.34375 L 182.546875 238.65625 L 275.855469 238.65625 L 275.855469 145.34375 Z M 182.546875 145.34375 "
                fillRule="nonzero"/>
        </g>
      </g>
      <path strokeLinecap="butt" transform="matrix(0.381015, -0.646009, 0.646009, 0.381015, 102.48736, 150.85241)"
            fill="none" strokeLinejoin="miter" d="M -0.000254939 2.498882 L 70.838702 2.501148 "
            stroke="url(#494c874b06)" strokeWidth="5" strokeMiterlimit="4"/>
      <path strokeLinecap="butt" transform="matrix(-0.381015, -0.646009, 0.646009, -0.381015, 203.874512, 152.757482)"
            fill="none" strokeLinejoin="miter" d="M 0.0011431 2.498735 L 70.83745 2.500954 " stroke="url(#2f78460c2c)"
            strokeWidth="5" strokeMiterlimit="4"/>
      <g clipPath="url(#7e3aa69e81)">
        <g clipPath="url(#b307d783b7)">
          <path fill="url(#c1563d9744)"
                d="M 108.144531 271.492188 L 108.144531 364.800781 L 201.453125 364.800781 L 201.453125 271.492188 Z M 108.144531 271.492188 "
                fillRule="nonzero"/>
        </g>
      </g>
      <g clipPath="url(#afb1bc4b1f)">
        <g clipPath="url(#849913e20b)">
          <path fill="url(#6d9a6436d6)"
                d="M 256.945312 271.492188 L 256.945312 364.800781 L 350.253906 364.800781 L 350.253906 271.492188 Z M 256.945312 271.492188 "
                fillRule="nonzero"/>
        </g>
      </g>
      <path strokeLinecap="butt" transform="matrix(0.381015, -0.646009, 0.646009, 0.381015, 176.887932, 276.998064)"
            fill="none" strokeLinejoin="miter" d="M 0.00223336 2.500157 L 70.838544 2.497936 "
            stroke="url(#ec8a3210b4)" strokeWidth="5" strokeMiterlimit="4"/>
      <path strokeLinecap="butt" transform="matrix(-0.381015, -0.646009, 0.646009, -0.381015, 278.275123, 278.903152)"
            fill="none" strokeLinejoin="miter" d="M 0.00127717 2.501497 L 70.84023 2.499229 " stroke="url(#69cea76642)"
            strokeWidth="5" strokeMiterlimit="4"/>
    </svg>
  )
}