
export const apiGet = async (endpoint) => {
  const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
    credentials: "include"
  })
  return await response.json()
}

export const apiDelete = async (endpoint) => {
  const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
    method: "DELETE",
    credentials: "include"
  })
  return await response.json()
}

export const apiPost = async (endpoint, body) => {
  return await fetch(process.env.REACT_APP_API_URL + endpoint, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  })
}