import './App.css';
import {Routes} from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Chat from "./components/Chat";
import {ChatProvider} from "./contexts/ChatContext";

function App() {
  return (
    <div className="App">
      <ChatProvider>
        <Sidebar/>
        <Chat/>
      </ChatProvider>
    </div>
  );
}

export default App;
