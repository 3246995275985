import {createContext, useEffect, useState, useContext} from "react";
import {apiDelete, apiGet, apiPost} from "../utility/api";
import { message } from "antd";

export const ChatContext = createContext();

export const ChatProvider = ({children}) => {

  const [chats, setChats] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [models, setModels] = useState(["llama3"]);
  const [error, setError] = useState(null);
  const [loadingMessages, setLoadingMessages] = useState({});

  useEffect(() => {
    apiGet("/chats").then(data => setChats(data))
    apiGet("/available_models").then(data => setModels(data))
  }, [])

  const addChat = (chat) => {
    setChats([chat, ...chats])
  }

  const updateChat = (chatId, updates) => {
    setChats(prevChats => prevChats.map(chat => {
      // Not the one to update
      console.log(chat)
      if (chat.id !== chatId)
        return chat
      return {...chat, ...updates}
    }))
  }

  const deleteChat = (chatId) => {
    apiDelete("/chat/" + chatId)
    setChats(chats.filter(chat => chat.id !== chatId))
  }

  const getChatMessages = (chatId) => {
    apiGet("/chat/" + chatId)
      .then(data => {
        if (data.status === "failure")
          return setError("No chat messages found")
        // console.log(data.chat_messages)
        setChatMessages(data.chat_messages || [])
      })
  }

  const clearChatMessages = () => {
    setChatMessages([]);
  }

  const addChatMessage = (chatMessage) => {
    setChatMessages(prevChatMessages => [...prevChatMessages, chatMessage])
  }

  const updateChatMessage = (chatMessageId, updates) => {
    setChatMessages(prevChatMessages => prevChatMessages.map(message => {
      // Not the one to update
      if (message.id !== chatMessageId)
        return message
      return {...message, ...updates}
    }))
  }

  const deleteChatMessage = (chatMessageId) => {
    apiDelete("/chat_message/" + chatMessageId).then(() => {
      setChatMessages(chatMessages.filter(message => message.id !== chatMessageId))
    })
  }

  const regenerateChatMessage = async (chatMessageId, chatId) => {
    // setLoadingMessages(prev => ({ ...prev, [chatMessageId]: true }));
    // try {
    //   const aiMessage = chatMessages.find(msg => msg.id === chatMessageId && msg.type === "ai");
    //
    //   if (!aiMessage) {
    //     //console.error("AI message not found.");
    //     return setError("Failed to regenerate message. AI message not found.");
    //   }
    //
    //   //console.log(aiMessage)
    //
    //   const originalUserMessage = chatMessages.find(msg => msg.id === aiMessage.response_to && msg.type === "user");
    //   //console.log("orginal user message: " + originalUserMessage.content);
    //   if (!originalUserMessage) {
    //     //console.error("Original user message not found.");
    //     return setError("Failed to regenerate message. User message not found.");
    //   }
    //
    //   //console.log(originalUserMessage)
    //
    //   const response = await apiPost(`/chat_message/${originalUserMessage.id}/regenerate`, {
    //     chatId,
    //     userMessage: originalUserMessage.content // Use original user input as the prompt
    //   });
    //
    //   if (response.ok) {
    //     const data = await response.json(); // Parse the response to JSON
    //     //console.log("Full Response Data:", data); // Log the parsed JSON data
    //
    //     const regeneratedMessage = data.regenerated_message.content;
    //     //console.log("Regenerated Message:", regeneratedMessage);
    //
    //     //replace with the regenerated message
    //     setChatMessages(prevMessages => {
    //       const updatedMessages = [...prevMessages]; //copy of chatMessages
    //       const aiMessageIndex = updatedMessages.findIndex(msg => msg.id === chatMessageId);
    //
    //       if (aiMessageIndex !== -1) {
    //         // Update the content of the AI message with the regenerated content
    //         updatedMessages[aiMessageIndex] = {
    //           ...updatedMessages[aiMessageIndex],
    //           content: regeneratedMessage
    //         };
    //       }
    //
    //       return updatedMessages; // Return the updated message list to trigger UI re-render
    //     });
    //
    //   } else {
    //     setError("Failed to regenerate message: " + response.message);
    //   }
    // } catch (err) {
    //   setError("Failed to regenerate message");
    // } finally {
    //   setLoadingMessages(prev => ({ ...prev, [chatMessageId]: false }));
    // }
  };
  
  

  return <ChatContext.Provider value={{
    chats,
    chatMessages,
    addChat,
    updateChat,
    deleteChat,
    getChatMessages,
    clearChatMessages,
    addChatMessage,
    updateChatMessage,
    deleteChatMessage,
    regenerateChatMessage,
    models,
    error
  }}>
    {children}
  </ChatContext.Provider>;
}